import { atom } from 'recoil';

const latestIdxState = atom({
  key: 'latestIdxState', // unique ID (with respect to other atoms/selectors)
  default: {
    notice: 0,
    ask: 0,
    faq: 0,
  }, // default value (initial state for each category)
});

const userState = atom({
  key: 'userState',
  default: { name: '', owner_Name: null },
});

const adminUserState = atom({
  key: 'adminUserState',
  default: { name: '' },
});

const tokenState = atom({
  key: 'tokenState',
  default: {
    accessToken: 'emptyToken',
    refreshToken: null,
  },
});

export { userState, adminUserState, tokenState, latestIdxState };
