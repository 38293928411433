const adminPostAPI = (API) => ({
  getDonation: () =>
    API.get('admin/donation', {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),

  postDonation: ({ data }) =>
    API.post('admin/donation', data, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),

  getPopupNotices: () =>
    API.get('admin/notice/popup', {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    })
      .then(function (response) {
        return response; // 응답 데이터만 반환
      })
      .catch(function (error) {
        return error.response;
      }),

  postPopupNotice: ({ data }) =>
    API.post('admin/notice/popup', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Access_Token: '1111111',
        Web: 'Y',
      },
    })
      .then(function (response) {
        return response; // 응답 데이터만 반환
      })
      .catch(function (error) {
        console.log('error', error);
        return error.response;
      }),
});

export default adminPostAPI;
