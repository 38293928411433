const storeAPI = (API) => ({
  getStores: ({ params }) =>
    API.get('admin/store/list', {
      params: { page: params.curPages, size: params.pageSize },
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then(function (response) {
      const result = response.data;
      const data = result['_embedded']['adminStoreListResourceList'];
      const page = result['page'];
      const links = result['_links'];
      return { data, page, links };
    }),

  getStore: ({ id }) =>
    API.get('admin/store/' + id, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then(function (response) {
      return response.data;
    }),

  updateStore: ({ id, data }) =>
    API.patch('admin/store/update/' + id, data, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then(function (response) {
      return response.data;
    }),

  searchStore: ({ data, params }) =>
    API.post('admin/store/search', data, {
      params: { page: params.curPages, size: params.pageSize },
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then(function (response) {
      const result = response.data;
      const data = result['_embedded']['adminStoreListResourceList'];
      const page = result['page'];
      const links = result['_links'];
      return { data, page, links };
    }),

  searchResultStore: ({ data }) =>
    API.post('admin/store/search/excel', data, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then(function (response) {
      return response.data;
    }),
});

export default storeAPI;
