import React, { useEffect, useRef, useState } from 'react';
import NoticeForm from './NoticeForm';

import { NoticeAPI } from '../../../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeClearValue } from '../../../../util/safe';
import Spinner from '../../../atoms/Spinner';

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [notice, setNotice] = useState({
    title: '',
    type: '',
    viewYn: 'Y',
  });
  const [files, setFiles] = useState([]); // local에 선택된 파일들 정보저장
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isContentUpdated, setIsContentUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  const quillElement = useRef(null);
  const quillInstance = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const idx = +queryParams.get('idx');

  const mimeToExtension = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'application/pdf': '.pdf',
    // 필요한 경우 다른 MIME 타입도 추가
  };

  const getExtensionFromMime = (mimeType) => {
    return mimeToExtension[mimeType] || '';
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true); // 데이터 로드 시작 시 로딩 표시
      if (idx) {
        try {
          const result = await NoticeAPI.getNotice({ id: idx });
          const serverFiles = result.noticeFile.map((file) => ({
            ...file,
            state: 'stable',
            type: 'server',
          }));

          setNotice({
            idx: result.idx,
            contents: result.contents,
            title: result.title,
            type: result.type,
            viewYn: result.viewYn ? result.viewYn : 'Y',
          });
          setFiles(serverFiles);
        } catch (error) {
          console.error('Failed to fetch notice:', error);
        }
      }
      setIsLoading(false); // 데이터 로드 완료 시 로딩 종료
    }
    fetchData();
  }, [location.search]);

  const handleFileChange = async (event) => {
    const MAX_FILE_SIZE = 30 * 1024 * 1024; // 10MB in bytes

    const newFiles = Array.from(event.target.files).map((file) => {
      // 파일 크기 체크
      if (file.size > MAX_FILE_SIZE) {
        alert(`${file.name} 파일 크기는 10MB를 초과할 수 없습니다.`);
        return null; // 파일 크기가 10MB를 초과하면 null 반환
      }

      return {
        id: null,
        oriName: file.name,
        realName: file.name,
        state: 'new',
        type: 'local',
        file, // Original file object
      };
    });

    // 유효한 파일만 추가
    const validFiles = newFiles.filter((file) => file !== null);
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDeleteFileChange = (index) => {
    const fileToDelete = files[index];

    if (fileToDelete.type === 'server') {
      setDeletedFiles((prev) => [...prev, fileToDelete.idx]);
    }
    // Remove file from local state
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const clearValue = makeClearValue(value);
    setNotice({ ...notice, [name]: clearValue });
  };

  const handleCreate = async () => {
    const userConfirmed = window.confirm('공지사항을 등록하시겠나요?');

    if (userConfirmed) {
      try {
        const { noticeIdx } = await NoticeAPI.createNotice({ data: notice });
        return noticeIdx;
      } catch (error) {
        console.log('등록에러', error);
        return 'error';
      }
    }
  };

  const handleUpdate = async () => {
    const userConfirmed = window.confirm('공지사항을 수정하시겠나요?');

    if (userConfirmed) {
      try {
        if (!idx) return;
        const result = await NoticeAPI.updateNotice({ id: idx, data: notice });
        console.log('수정완료', result);
        return 'success';
      } catch (error) {
        return 'error';
      }
    }
  };

  const handleDelete = async () => {
    const userConfirmed = window.confirm(
      '공지사항을 삭제하시겠나요? 삭제한 글은 복구되지 않습니다'
    );

    if (userConfirmed) {
      try {
        const result = await NoticeAPI.deleteNotice({ id: idx });
        navigate('/admin/noticeList');
        navigate(0);
      } catch (error) {
        alert('오류가 발생했습니다.');
      }
    }
  };

  const handleCancle = () => {
    const userConfirmed = window.confirm(
      '작성을 취소하시겠나요? 취소하시면 작성중인 글은 저장되지 않습니다'
    );

    if (userConfirmed) {
      try {
        navigate('/admin/noticeList');
      } catch (error) {
        alert('오류가 발생했습니다.');
      }
    }
  };

  const handleDeleteFile = async () => {
    try {
      const data = { idxs: [...deletedFiles] };
      const deleteResult = await NoticeAPI.deleteFileNotice({ data });
    } catch (error) {
      console.log('에러', error);
      alert('오류가 발생했습니다.');
    }
  };

  const handleAddFile = async (result) => {
    const formData = new FormData();
    let hasAddedFile = false;
    let latestIdx = result;

    files.forEach((file) => {
      if (file.state === 'new' && file.type === 'local') {
        formData.append('files', file.file);
        hasAddedFile = true;
      }
    });

    if (!hasAddedFile) {
      console.log('요청보낼 파일이 존재하지 않습니다.');
      return 'success'; // 정상 처리로 간주
    }

    try {
      const addResult = await NoticeAPI.addFileNotice({
        id: idx ? idx : latestIdx,
        data: formData,
      });
      return 'success';
    } catch (error) {
      console.log('에러', error);
      return 'error'; // 오류 발생
    }
  };

  const base64ToBlob = (base64) => {
    const byteString = atob(base64.split(',')[1]);
    const mimeType = base64.match(/data:(.*?);base64/)[1];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  };

  const uploadImageToServer = async (blobUrl) => {
    const formData = new FormData();
    const file = blobUrl;
    const extension = getExtensionFromMime(file.type);
    const fileName = `editor_embeded${extension}`;

    formData.append('file', file, fileName);

    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const result = await NoticeAPI.uploadNoticeImage({ data: formData });
      return result.message;
    } catch (error) {
      console.log('에러', error);
      alert('오류가 발생했습니다.');
    }
  };

  const changeContents = async () => {
    let contents = quillInstance.current.root.innerHTML;

    const parser = new DOMParser();
    const doc = parser.parseFromString(contents, 'text/html');
    const images = doc.querySelectorAll('img');

    const uploadImageAndChangeURL = Array.from(images).map(async (image) => {
      try {
        const base64url = image.src;

        // Check if the image source is a base64 URL
        if (base64url.startsWith('data:image')) {
          const blobUrl = base64ToBlob(base64url);
          const downloadUrl = await uploadImageToServer(blobUrl);

          image.src = downloadUrl;
        } else {
          console.warn('Image source is not a base64 URL:', base64url);
        }
      } catch (error) {
        console.error('Error processing image:', error);
      }
    });

    await Promise.all(uploadImageAndChangeURL);

    const updatedContents = doc.body.innerHTML;

    setNotice((prevNotice) => ({ ...prevNotice, contents: updatedContents }));
    setIsContentUpdated(true); // State to indicate the content update is complete
  };

  useEffect(() => {
    if (isContentUpdated) {
      const submitData = async () => {
        setIsLoading(true); // API 요청 시작 시 로딩 표시
        try {
          let result;

          if (!idx) {
            result = await handleCreate();
          } else {
            result = await handleUpdate();
          }

          if (result === 'error') {
            setIsLoading(false); // 에러 발생 시 로딩 종료
            alert('오류가 발생했습니다.');
            return; // 중단
          }

          await handleAddFile(result);

          if (deletedFiles.length > 0) {
            await handleDeleteFile();
          }

          setIsLoading(false); // 에러 발생 시 로딩 종료
          navigate(0);

          if (!idx) {
            navigate('/admin/noticeList');
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false); // 에러 발생 시 로딩 종료
        }
      };
      submitData();
    }
  }, [isContentUpdated]);

  const handleSubmit = async () => {
    if (
      !notice.title ||
      !notice.type ||
      !notice.viewYn ||
      !quillInstance.current.root.innerHTML
    ) {
      alert('모든 필수 항목을 입력해주세요.');
      return;
    }

    await changeContents();
  };

  return (
    <>
      {isLoading && <Spinner />}
      {notice.idx && (
        <NoticeForm
          idx={idx}
          data={notice}
          files={files}
          quillElement={quillElement}
          quillInstance={quillInstance}
          onChange={handleChange}
          onFileChange={handleFileChange}
          onDeleteFileChange={handleDeleteFileChange}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
          onCancle={handleCancle}
        />
      )}
      {!idx && (
        <NoticeForm
          data={notice}
          files={files}
          quillElement={quillElement}
          quillInstance={quillInstance}
          onChange={handleChange}
          onFileChange={handleFileChange}
          onDeleteFileChange={handleDeleteFileChange}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
          onCancle={handleCancle}
        />
      )}
    </>
  );
};

export default Index;
