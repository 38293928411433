import ArrowRight from "../../assets/svg/Arrow-right";

const CardNews = ({ content, onModal }) => {
  return (
    <div className="flex flex-col w-full md:w-5/12 rounded-2xl shadow-xl p-4 mb-4 md:mb-0">
      <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-8">
        {content.title}
      </h3>
      <p className="text-base md:text-lg font-medium mb-4 md:mb-8">
        {content.describe.map((item, index) => (
          <span key={index}>
            {item}
            <br />
          </span>
        ))}
      </p>
      <div className="flex justify-start mb-4">
        <a
          className="flex items-center bg-yellow-300 text-black rounded-full px-4 py-2 md:px-6 md:py-3 transition duration-300 hover:bg-yellow-400"
          href={content.href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onModal}
        >
          <p className="text-sm md:text-base font-medium">{content.to}</p>
          <ArrowRight size={8} strokeWidth={1.8} />
        </a>
      </div>
      <div className="flex justify-end mt-4 md:mt-8 h-full">
        <img
          src={content.img}
          alt={content.title}
          className="object-cover w-fit h-full md:w-fit md:h-full self-end"
        />
      </div>
    </div>
  );
};

export default CardNews;
