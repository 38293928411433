export const SNS = [
  { id: 1, code: "SNS", value: "SNS" },
  { id: 2, code: "insta", value: "insta" },
  { id: 3, code: "kakao", value: "kakao" },
  { id: 4, code: "YouTube", value: "YouTube" },
  { id: 5, code: "twitter", value: "twitter" },
  { id: 6, code: "band", value: "band" },
  { id: 7, code: "nblog", value: "nblog" },
  { id: 8, code: "etc", value: "etc" },
];
