const ArrowRight = (props) => {
  /** hover가 nul일 경우를 대비 */
  const { hover, size, strokeWidth } = props;
  const cn = "md:w-" + size + " w-" + size + " md:h-8" + " h-4 mr-4 " + hover;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke="currentColor"
      className={cn}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
      />
    </svg>
  );
};

export default ArrowRight;
