import React, { useEffect, useRef, useState } from 'react';
import BizInfo from './BizInfo';
import { useNavigate } from 'react-router-dom';
const Index = () => {
  const [showModal, setShowModal] = useState([false, false, false]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(showModal);
  }, [showModal]);

  const handleModal = (e) => {
    const target = e.target.closest('[data-id]');
    const index = target.dataset.id;
    setShowModal((prev) =>
      prev.map((modal, idx) => (idx == index ? !modal : modal))
    );
  };

  const handleLink = (e) => {
    const { id } = e.target;
    if (id == 0) {
      window.open('https://www.instagram.com/goodimpact012/', '_blank');
    } else {
      window.scrollTo(0, 0);
      navigate('/community/notice');
    }
  };

  return (
    <BizInfo {...{ showModal }} onModal={handleModal} onLink={handleLink} />
  );
};

export default Index;
