import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerList = [
    { url: "/policy/terms", content: "이용약관" },
    { url: "/policy/privacy", content: "개인정보처리방침" },
    { url: "/policy/email", content: "이메일무단수집거부" },
    { url: "/community/ask", content: "1:1문의" },
    {
      url: "https://www.acrc.go.kr/",
      content: "국민권익위원회",
      external: true,
    },
    { url: "https://www.nts.go.kr/", content: "국세청", external: true },
    { url: "https://www.mohw.go.kr/", content: "주무관청", external: true },
  ];

  const handleClick = () => {
    window.scroll({
      top: 0,
    });
  };

  return (
    <div className="flex flex-col w-full bg-gray-100 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col sm:flex-row justify-between text-xs pt-5 sm:pt-9 sm:text-sm sm:mb-8">
        <ul className="flex flex-wrap sm:justify-start items-center font-semibold sm:mb-0">
          {footerList.map(({ url, content, external }) => (
            <li className="mr-4 sm:mr-8 mb-1" key={content}>
              {external ? (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-2xs md:text-base text-black hover:underline"
                  onClick={handleClick}
                >
                  {content}
                </a>
              ) : (
                <Link
                  to={url}
                  className="text-2xs md:text-base text-black hover:underline"
                  onClick={handleClick}
                >
                  {content}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <Link
          to="/home"
          onClick={handleClick}
          className="flex justify-end sm:block"
        >
          <div className="w-24 h-12 bg-[url('./assets/images/header_logo.png')] bg-cover cursor-pointer mb-4 md:w-32 md:h-16" />
        </Link>
      </div>
      <div className="pt-4 sm:pt-0" />
      <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:space-x-16 text-xs sm:text-sm">
        <div className="flex flex-col mb-4 sm:mb-0">
          <p className="font-semibold mb-2">후원계좌</p>
          <p className="text-2xs text-gray-500 md:text-sm">
            • 정회원회비 입금: 우리은행 1005 604 451456 (사단법인
            선한영향력가게)
          </p>
          <p className="text-2xs text-gray-500 md:text-sm">
            • 후원금 : 우리은행 1005 404 677800 (사단법인 선한영향력가게)
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-2xs text-gray-500 md:text-sm">
            사단법인 선한영향력가게 | 사업자등록번호 104-82-71462
          </p>
          <p className="text-2xs text-gray-500 md:text-sm">
            서울특별시 강남구 강남대로118길 43 3층 | 전화 010-2375-0133 | 이메일
            goodimpact012@daum.net
          </p>
          <p className="text-2xs text-gray-500 md:text-sm">
            &copy; Goodshare All Rights Reserved
          </p>
        </div>
      </div>
      <div className="pb-8" />
    </div>
  );
};

export default Footer;
