import React, { useState } from "react";

const TwInput = ({ placeholder, menu, name, onNotice, isString }) => {
  const { idx } = menu;
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;

    if (isString) {
      setValue(inputValue);
      onNotice(e);
    } else {
      if (regex.test(inputValue)) {
        setValue(inputValue);
        onNotice(e);
      }
    }
  };

  return (
    <div className="w-full">
      <input
        id={idx}
        name={"menu_" + name}
        type="text"
        value={value}
        onChange={handleChange}
        className="w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm appearance-none"
        placeholder={placeholder}
      />
    </div>
  );
};

export default TwInput;
