import ApplyTitle from "../../../atoms/ApplyTitle";
import Dividers from "../../../atoms/Dividers";
import PolicyContent from "../../../atoms/PolicyContent";
import TermsTitle from "../../../atoms/TermsTitle";

const Terms = ({ items }) => {
  return (
    <>
      <ApplyTitle title={"이용약관"} />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        {items.map((item) => {
          return (
            <>
              {item.bt != null ? <TermsTitle text={item.bt}></TermsTitle> : ""}
              <PolicyContent item={item} />
            </>
          );
        })}
      </div>
    </>
  );
};

export default Terms;
