const adminAuthAPI = (API) => ({
  login: ({ data }) =>
    API.post('admin/login', data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),
  logout: () =>
    API.get('admin/logout', {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),
});

export default adminAuthAPI;
