const ApplyInputTitle = ({ title, redStar }) => {
  let redDot = "";

  if (redStar) {
    redDot = "after:content-['*'] after:ml-0.5 after:text-red-500";
  }

  return (
    <>
      <div className="flex flex-row md:w-2/12 w-1/4 items-center">
        <h2 className={`flex md:text-xl text-sm text-gray-600 ${redDot}`}>
          {title}
        </h2>
      </div>
    </>
  );
};

export default ApplyInputTitle;
