import { useNavigate } from 'react-router-dom';
import ApplyTitle from '../../../atoms/ApplyTitle';
import ApplyProgress from '../../../atoms/ApplyProgress';
import Terms02 from '../../../atoms/Terms02';
import Terms01 from '../../../atoms/Terms01';

const Step1 = ({
  nextTo,
  handleCheckBox,
  isAllChecked,
  checkBox1,
  checkBox2,
  checkboxesRef,
}) => {
  return (
    <>
      {/* section1 */}
      <section id="section1" className="md:mb-0 mb-4">
        <ApplyTitle title={'선한영향력가게 회원 신청'} />
      </section>

      {/* section2 */}
      <section
        id="section2"
        className="flex flex-col items-center md:mx-0 mx-4 overflow-y-auto"
      >
        <ApplyProgress title={'약관 동의'} step={1} />

        <form id="약관동의" className="flex flex-col w-5/6 md:mt-0 mt-8">
          <ul className="flex md:mb-10 mb-8">
            <li className="flex flex-col w-full">
              <div className="flex flex-row mb-8 justify-start ">
                <label className="flex">
                  <div className="flex items-center gap-2 ">
                    <input
                      type="checkbox"
                      className="w-5 h-5 border-solid border-gray-500 text-sky-950 "
                      checked={checkBox1}
                      onChange={(e) => handleCheckBox(e, 1)}
                      ref={(el) => (checkboxesRef.current[1] = el)}
                    />
                    <span className="md:text-lg text-sm">(필수)이용약관</span>
                  </div>
                </label>
              </div>
              <div className="flex border-solid border-2 border-gray-300 justify-center">
                <div className="flex h-80 w-full mb-4 overflow-scroll">
                  <Terms01 />
                </div>
              </div>
            </li>
          </ul>

          <ul className="flex  mb-10">
            <li className="flex flex-col w-full">
              <div className="flex flex-row mb-8 justify-start bg">
                <label className="flex">
                  <div className="flex items-center gap-2 ">
                    <input
                      type="checkbox"
                      className="w-5 h-5 border-solid border-gray-500 text-sky-950 "
                      checked={checkBox2}
                      onChange={(e) => handleCheckBox(e, 2)}
                      ref={(el) => (checkboxesRef.current[2] = el)}
                    />
                    <span className="md:text-lg text-sm">
                      (필수)개인정보 수집 이용 동의서
                    </span>
                  </div>
                </label>
              </div>
              <div className="flex border-solid border-2 border-gray-300 justify-cente">
                <div className="flex h-80 w-full mb-4 p-4 overflow-scroll">
                  <Terms02 />
                </div>
              </div>
            </li>
          </ul>
          <label>
            <div className="flex h-auto w-auto mt-4 mb-16 md:text-lg text-sm text-gray-500 justify-center items-center gap-3">
              <input
                type="checkbox"
                className="w-5 h-5 border-solid border-gray-500 text-sky-950"
                checked={isAllChecked}
                onClick={(e) => handleCheckBox(e, 0)}
                ref={(el) => (checkboxesRef.current[0] = el)}
              />
              <span>위의 내용을 모두 읽었으며 이에 동의합니다.</span>
            </div>
          </label>
          <a
            className="flex border-solid border-2 border-sky-950 text-sky-950 md:w-80 w-48 h-16 gap-3 mb-24 bg-white justify-center items-center self-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer"
            onClick={nextTo}
          >
            <p className="md:text-xl text-base font-bold">다음 단계</p>
          </a>
        </form>
      </section>
    </>
  );
};

export default Step1;
