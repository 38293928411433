import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/svg/Arrow-right';
import { scrollTop } from '../../util/scroll';

const Post = ({ idx, type, title }) => {
  const data = { '01': 'notice', '02': 'notice', '03': 'ask' };

  return (
    <Link to={`/community/${data[type]}/view/${idx}`} onClick={scrollTop}>
      <li className='group flex flex-row border-solid border-b border-gray-200 items-center justify-between'>
        <div className='flex p-3 md:p-6 gap-4 items-center md:w-full w-5/6 '>
          <div className='flex rounded-md bg-blue-50 md:w-16 w-12 h-6 text-xs md:h-8 md:text-sm font-semibold text-blue-900 text-center items-center justify-center'>
            {type === '01' ? '공지사항' : '뉴스'}
          </div>
          <p className='text-xs md:text-lg font-medium truncate flex-1'>
            {title}
          </p>
        </div>
        <div className='flex-shrink-0 md:w-1/12 w-1/6 text-right group-hover:animate-blink p-0 md:p-6'>
          <ArrowRight size={10} strokeWidth={1.5} />
        </div>
      </li>
    </Link>
  );
};

export default Post;
