import React from 'react';
import SectorDropDown from '../../atoms/SectorDropDown';
import ApplyInput from '../ApplyInput/ApplyInput';
import ApplyInputTitle from '../../atoms/ApplyInputTitle';
import ApplyInputContent from '../../atoms/ApplyInputContent';
import SearchPostcode from '../../atoms/SearchPostcode';
import { SECTORS, SUB_SECTORS } from '../../../constants/sectors';
import SelectSimpleCustom from '../../atoms/SelectSimpleCustom';
import SelectSector from '../../atoms/SelectSector';

const StoreInfo = ({
  handleFocus,
  handleApplyForm,
  subSector,
  onRadio,
  radio,
  applyForm,
  onChangeSector,
  onPass,
  refs,
}) => {
  return (
    <>
      <div className="flex flex-col md:w-5/6 w-full mt-10">
        <div className="flex flex-row w-full mb-8 justify-between border-solid border-b-2 border-gray-500">
          <h3 className="flex md:text-2xl text-sm font-bold mb-4 ">가게정보</h3>
        </div>
      </div>

      <dl className="flex flex-col md:w-5/6 w-full md:mt-10 mt-0 items-center ">
        <div className="flex flex-col md:w-2/3 w-full">
          <ApplyInput id={'상호명'}>
            <ApplyInputTitle title={`상호명`} redStar={true} />
            <ApplyInputContent>
              <label className="flex flex-col md:w-2/3 w-5/6 md:mt-7 mt-0">
                <input
                  name="name"
                  type="text"
                  className="peer h-full items-center md:text-base text-xs"
                  placeholder="상호명을 입력하세요."
                  onFocus={handleFocus}
                  onChange={handleApplyForm}
                  value={applyForm.name}
                  ref={refs.name}
                />
                <a className="flex border-bottom-gray"></a>
                <p className="mt-2 invisible peer-invalid:visible text-pink-600 md:text-sm text-2xs">
                  상호명을 입력해주세요.
                </p>
              </label>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'업종'}>
            <ApplyInputTitle title={`업종`} redStar={true} />
            <ApplyInputContent>
              <div
                className="flex flex-row items-center  md:w-2/3 w-5/6 gap-1 justify-evenly"
                ref={refs.sector}
              >
                <SelectSector
                  options={SECTORS}
                  onChangeSector={onChangeSector}
                  value={applyForm.sector}
                />
                <SelectSector
                  options={subSector}
                  onChangeSector={onChangeSector}
                  onApplyForm={handleApplyForm}
                  isSub={true}
                  value={applyForm.subSector}
                  onPass={onPass}
                />
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'매장번호'}>
            <ApplyInputTitle title={`매장번호`} redStar={true} />
            <ApplyInputContent>
              <div className="flex flex-col h-full md:w-10/12 w-5/6">
                <div
                  name="tel_Yn"
                  className="flex flex-row gap-4 mt-3 ml-3 mb-2"
                  onChange={onRadio}
                >
                  <div class="flex items-center ">
                    <input
                      checked={applyForm.tel_Yn === 'Y'}
                      id="tel-radio-3"
                      type="radio"
                      value="Y"
                      name="tel_Yn"
                      class="w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-800"
                    />
                    <label
                      for="tel-radio-3"
                      className="ms-2 md:text-base text-xs font-medium "
                    >
                      공개
                    </label>
                  </div>
                  <div class="flex items-center">
                    <input
                      checked={applyForm.tel_Yn === 'N'}
                      id="tel-radio-4"
                      type="radio"
                      value="N"
                      name="tel_Yn"
                      class="w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="tel-radio-4"
                      class="ms-2 md:text-base text-xs font-medium "
                    >
                      비공개
                    </label>
                  </div>
                </div>
                <label className="flex flex-col md:w-4/5 w-full">
                  <input
                    name="tel"
                    type="tel"
                    className="peer md:text-base text-xs"
                    // pattern="(\d{1,4}-){2}\d{4}"
                    placeholder="하이픈을 포함해 번호를 입력해주세요."
                    onFocus={handleFocus}
                    onChange={handleApplyForm}
                    value={applyForm.tel}
                    ref={refs.tel}
                  />
                  <a className="flex w-full border-bottom-gray"></a>
                  <p className="mt-2 invisible peer-invalid:visible text-pink-600 md:text-sm text-2xs">
                    유효한 전화 번호를 입력해주세요.
                  </p>
                </label>
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'주소'}>
            <ApplyInputTitle title={`주소`} redStar={true} />
            <ApplyInputContent>
              <div className="flex flex-col w-full" ref={refs.address}>
                <SearchPostcode
                  onApplyForm={handleApplyForm}
                  {...{ applyForm }}
                  onPass={onPass}
                />
              </div>
            </ApplyInputContent>
          </ApplyInput>
        </div>
      </dl>
    </>
  );
};

export default StoreInfo;
