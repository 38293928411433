import React from "react";
import ArrowRight from "../../../assets/svg/Arrow-right";
import banner from "../../../assets/images/apply_banner.png";
import card1 from "../../../assets/images/apply_img_1.png";
import card2 from "../../../assets/images/apply_img_2.png";
import DownLoadIcon from "../../atoms/DownLoadIcon";
import ApplyPageHeader from "../../atoms/ApplyPageHeader";

const Apply = ({ onDownload }) => {
  return (
    <>
      <div className="flex flex-col mb-64 p-8 ">
        <ApplyPageHeader IMG={banner} />
        <div className="flex-col w-full md:h-full h-full md:pt-6 pt-16 md:mb-16 mb-16">
          <div className="flex flex-col w-auto h-auto md:mx-10 mx-4 md:my-6 md:mb-16">
            <h2 className="md:flex md:flex-col flex-1 md:text-5xl text-2xl font-semibold md:leading-tight leading-tight">
              세상을 위한 좋은 변화를 위해 <br className="hidden md:block" />{" "}
              함께하실 분들은 신청해주세요!
            </h2>

            <div id="info" className="flex flex-col md:my-8 my-4">
              <p className="md:text-lg text-sm font-normal md:leading-7 leading-5">
                사단법인 선한영향력가게는 결식우려아동급식카드 소지 아동대상과
                단체들에 <br className="hidden md:block" /> 무료 또는
                할인가격으로 급식 또는 매장 업종 서비스로 후원함을 목적으로
                운영됩니다.
                <br />
              </p>
            </div>
            <div className="flex justify-start">
              <a
                className="flex md:m-0 md:p-0 p-3 text-black md:w-72 w-fit md:h-14 h-fit md:gap-3 gap-0 bg-yellow-300 justify-center items-center transition duration-300 hover:cursor-pointer rounded-full"
                href="/apply/step/1"
              >
                <p className="md:text-lg text-xs md:leading-7 leading-5 md:font-medium">
                  선한영향력가게 동행 신청
                </p>
                <ArrowRight size={10} strokeWidth={1.8} />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-around gap-8">
          <div className="relative flex flex-col w-full md:w-1/2 rounded-2xl shadow-xl p-4 md:p-8">
            <h3 className="font-semibold md:text-3xl text-xl mb-4 md:mb-8">
              업종
            </h3>
            <p className="font-medium md:text-lg text-base text-gray-400">
              가능 업종
            </p>
            <p className="font-medium md:text-lg text-base mb-4 md:mb-8">
              음식, 카페, 안경원, 헤어샵, 스터디카페, 꽃집, 인테리어,{" "}
              <br className="hidden md:block" />
              편의점, 병원, 약국 등 기타 아이들에게 도움되는 서비스 업종
            </p>
            <p className="font-medium md:text-lg text-base text-gray-400">
              불가능 업종
            </p>
            <p className="font-medium md:text-lg text-base mb-16 md:mb-60">
              국가에서 지정하는 아동대상 불가 업종
            </p>
            <div className="flex justify-center mt-10 md:absolute md:right-0 md:bottom-0 md:mr-8 md:mb-8">
              <img
                src={card1}
                className="object-cover w-36 h-28 md:w-72 md:h-56"
              />
            </div>
          </div>
          <div className="relative flex flex-col w-full md:w-1/2 rounded-2xl shadow-xl p-4 md:p-8">
            <h3 className="font-semibold md:text-3xl text-xl mb-4 md:mb-8">
              '결식우려아동급식카드'란?
            </h3>
            <p className="font-medium md:text-lg text-base mb-4 md:mb-8">
              지자체에서 차상위계층 및 결식이 우려되어 지정한 18세 미만
              <br className="hidden md:block" />
              미취학, 취학 아동에게 지급하는 충전식 선불카드입니다. (일부 지자체
              제외)
            </p>
            <div className="flex justify-start">
              <a
                className="flex md:m-0 md:p-0 text-black md:w-64 w-52 md:h-14 h-12 md:gap-3 gap-1 bg-white justify-center items-center transition duration-300 hover:cursor-pointer rounded-full border-solid border-2 border-black"
                onClick={onDownload}
              >
                <p className="md:text-lg text-sm md:leading-7 leading-5 md:font-medium">
                  표준메뉴얼 다운로드
                </p>
                <DownLoadIcon />
              </a>
            </div>
            <div className="flex justify-center mt-10 md:absolute md:right-0 md:bottom-0 md:mr-8 md:mb-8">
              <img
                src={card2}
                className="object-cover w-36 h-28 md:w-72 md:h-56"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apply;
