import React from 'react';
import HomeContent from './HomeContent';

const Modal = ({ show, onClose, children, content, onLink, id, onModal }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-4 py-6 md:px-0"
      data-id={id}
    >
      <div className="flex flex-col items-center bg-white rounded-md p-4 max-w-3xl h-fit overflow-y-auto mx-auto z-50 w-full">
        <HomeContent
          content={content}
          onLink={onLink}
          id={id}
          onModal={onModal}
        />
      </div>
    </div>
  );
};

export default Modal;
