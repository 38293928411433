const askAPI = (API) => ({
  getAsks: ({ params }) =>
    API.get('admin/ask/list', {
      params: { page: params.curPages, size: params.pageSize },
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => {
      const result = response.data;
      const data = result['_embedded']['adminAllAskResourceList'];
      const page = result['page'];
      const links = result['_links'];
      return { data, page, links };
    }),

  getAsk: ({ id }) =>
    API.get(`admin/ask/${id}`, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  updateAsk: ({ id, data }) =>
    API.patch(`admin/ask/update/${id}`, data, {
      headers: {
        Access_Token: "1111111",
        Web: "Y",
      },
    }).then((response) => response.data),

  createAsk: ({ data }) =>
    API.post('admin/ask/', data, {
      headers: {
        Access_Token: "1111111",
        Web: "Y",
      },
    }).then((response) => response.data),

  deleteAsk: ({ id }) =>
    API.delete(`admin/ask/delete/${id}`, {
      headers: {
        Access_Token: "1111111",
        Web: "Y",
      },
    }).then((response) => response.data),

  uploadAskImage: ({ data }) =>
    API.post(`admin/ask/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  addFileAsk: ({ id, data }) =>
    API.post(`admin/ask/update/addfile/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  deleteFileAsk: ({ data }) =>
    API.delete(`admin/ask/update/delfile`, {
      data: data,
      headers: {
        Access_Token: "1111111",
        Web: "Y",
      },
    }).then((response) => response.data),
});

export default askAPI;
