import AskView from './AskView';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CommunityAPI } from '../../../api';

const Index = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [askContents, setAskContents] = useState({});
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      const result = await CommunityAPI.getAsk(id);
      const domain = 'https://www.선한영향력가게.com';
      try {
        const serverFiles = result.askFile.map((file) => ({
          ...file,
          link: '/file/ask/' + file.realName,
          state: 'stable',
          type: 'server',
        }));
        setFiles(serverFiles);
      } catch (error) {
        console.error('Failed to fetch notice:', error);
      }
      const updatedContents = result.contents.replace(
        /<img src="\/file\/ask\//g,
        `<img src="${domain}/file/ask/`
      );
      result.contents = updatedContents;
      setAskContents(result);
    }
    fetchData();
  }, []);
  return (
    <AskView
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      askContents={askContents}
      navigate={navigate}
      files={files}
    />
  );
};

export default Index;
