import React from 'react';
import Post from './Post';
import { useLocation } from 'react-router-dom';

const Index = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  return (
    <div className='post-page'>
      <Post type={type} />
    </div>
  );
};

export default Index;
