import { useEffect, useState } from 'react';
import { PostAPI, RequestAPI } from '../../../api';
import Home from './Home';
import NoticePopup from '../../atoms/NoticePopup';

const Index = () => {
  const [posts, setPosts] = useState([]);
  const [showNotice, setShowNotice] = useState(false);
  const [popupData, setPopupData] = useState(null);

  useEffect(() => {
    handlePost();
    fetchPopupData();
    const dontShowToday = localStorage.getItem('dontShowNoticeToday');
    const now = new Date().getTime();
    if (!dontShowToday || now > dontShowToday) {
      setShowNotice(true);
    }
  }, []);

  // viewYn 처리필요
  const handlePost = async () => {
    try {
      const response = await PostAPI.getHomePosts();
      // await CommunityAPI.getNotices({ curPages, pageSize });
      const posts = response.data._embedded.allNoticeResourceList.slice(0, 10);
      const viewPosts = posts.filter((post) => post.viewYn === 'Y').slice(0, 4);
      setPosts(viewPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const fetchPopupData = async () => {
    try {
      const { data } = await RequestAPI.getPopupNotices();
      console.log('팝업 데이터 가져오기', data);

      setPopupData({
        idx: data.idx,
        noticeIdx: data.noticeIdx,
        title: data.title,
        contents: data.contents,
        realName: data.realName,
        viewYn: data.viewYn,
      });
    } catch (error) {
      console.error('Error fetching popup data:', error);
    }
  };

  const handleCloseNotice = () => {
    setShowNotice(false);
  };

  return (
    <>
      {showNotice && popupData && popupData.viewYn !== 'N' && (
        <NoticePopup notice={popupData} onClose={handleCloseNotice} />
      )}
      <Home {...{ posts }} />
    </>
  );
};

export default Index;
