import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import AddressButton from './AddressButton';

const SearchPostcode = ({ onApplyForm, applyForm, onPass }) => {
  const [postcode, setPostcode] = useState(applyForm.zipcode);
  const [roadAddress, setRoadAddress] = useState(applyForm.addr1);
  const [detailAddress, setDetailAddress] = useState(applyForm.addr2);
  const [guide, setGuide] = useState('');
  const scriptLoadedRef = useRef(false);

  useEffect(() => {}, [postcode, roadAddress, detailAddress]);

  useEffect(() => {
    setPostcode(applyForm.zipcode || '');
    setRoadAddress(applyForm.addr1 || '');
    setDetailAddress(applyForm.addr2 || '');
    if (applyForm.zipcode && applyForm.addr1) {
    }
  }, [applyForm.zipcode]);

  const handleDetailAddr = (e) => {
    onApplyForm(e);
    setDetailAddress(e.target.value);
  };
  const handlePostcode = () => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        let roadAddr = data.roadAddress; // 도로명 주소 변수
        let extraRoadAddr = ''; // 참고 항목 변수

        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraRoadAddr += data.bname;
        }
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraRoadAddr +=
            extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
        }
        if (extraRoadAddr !== '') {
          extraRoadAddr = ' (' + extraRoadAddr + ')';
        }

        setPostcode(data.zonecode);
        setRoadAddress(roadAddr);
        setDetailAddress('');
        onPass('address', true);

        onApplyForm({
          target: { name: 'zipcode', value: data.zonecode, dataset: '' },
        });
        onApplyForm({
          target: { name: 'addr1', value: roadAddr, dataset: '' },
        });
        onApplyForm({
          target: { name: 'addr2', value: '', dataset: '' },
        });

        if (data.autoRoadAddress) {
          const expRoadAddr = data.autoRoadAddress + extraRoadAddr;
          setGuide('(예상 도로명 주소 : ' + expRoadAddr + ')');
        } else if (data.autoJibunAddress) {
          const expJibunAddr = data.autoJibunAddress;
          setGuide('(예상 지번 주소 : ' + expJibunAddr + ')');
        } else {
          setGuide('');
        }
      },
    }).open();
  };

  return (
    <>
      <Helmet>
        <script
          src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
          async
          onLoad={() => {
            scriptLoadedRef.current = true;
          }}
        />
      </Helmet>
      <div className="flex flex-row items-center">
        <label className="flex flex-col md:w-1/2 w-7/12 mt-7">
          <input
            name="zipcode"
            type="text"
            className="peer h-full items-center md:text-base text-xs"
            placeholder="우편번호"
            disabled
            value={postcode}
            onChange={onApplyForm}
          />
          <a className="flex w-full border-bottom-gray"></a>
        </label>
        <AddressButton
          title={'검색'}
          w={'1/4 md:w-32'}
          h={'10 mt-5'}
          textSize={'xs md:text-base'}
          onPostcode={handlePostcode}
        ></AddressButton>
        <br />
      </div>

      <label className="flex flex-col md:w-2/3 w-4/5 md:mt-7 mt-2">
        <input
          name="addr1"
          type="text"
          className="peer h-full items-center  md:text-base text-xs"
          placeholder="기본주소"
          value={roadAddress}
          disabled
          onChange={onApplyForm}
        />
        <a className="flex w-full border-bottom-gray"></a>
      </label>
      <label className="flex flex-col md:w-2/3 w-4/5 md:mt-7 mt-2">
        <input
          name="addr2"
          type="text"
          className="peer h-full w-full items-center md:text-base text-xs"
          placeholder="상세 주소 (선택)"
          onChange={handleDetailAddr}
          value={detailAddress}
        />
        <a className="flex w-full border-bottom-gray"></a>
      </label>

      <span
        id="guide"
        style={{ color: '#999', display: guide ? 'block' : 'none' }}
      >
        {guide}
      </span>
    </>
  );
};

export default SearchPostcode;
