import Ask from './Ask';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommunityAPI } from '../../../api';

const Index = () => {
  const [asks, setAsks] = useState([]);
  const [curPages, setCurPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [secretModal, setSecretModal] = useState(false);
  const [clickAskIdx, setClickAskIdx] = useState('0');
  const navigate = useNavigate();
  const askType = ['회원정보', '선한가게신청', '후원', '기타', '학생'];

  const handlePaginationNumber = (e) => {
    setCurPages(e.target.innerText - 1);
    e.target.className =
      'bg-sky-800 text-white inline-flex justify-center items-center w-12 h-12 hover:cursor-pointer';
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePaginationPrev = (e) => {
    if (curPages > 0) {
      setCurPages(curPages - 1);
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePaginationNext = () => {
    if (curPages < totalPages - 1) {
      setCurPages(curPages + 1);
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      alert('마지막 페이지 입니다.');
    }
  };

  const handleSecretClick = (e, idx) => {
    e.preventDefault();
    setClickAskIdx(idx);
    setSecretModal(!secretModal);
  };

  const handleSecretCheckClick = (e) => {
    e.preventDefault();
    const secretPwd = new FormData(e.target).get('secretPwd');

    async function checkPasword(idx, secretPwd) {
      try {
        const result = await CommunityAPI.checkSecretAskPwd({ idx, secretPwd });
        if (result.message === 'Fail') {
          alert('비밀번호를 확인해주세요');
        } else {
          navigate('/community/ask/view/' + idx);
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error('Error checking password:', error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
    checkPasword(clickAskIdx.toString(), secretPwd);
  };

  useEffect(() => {
    async function fetchData(curPages, pageSize) {
      const result = await CommunityAPI.getAsks({ curPages, pageSize });
      setAsks(result['data']);
      setTotalPages(result['page']['totalPages']);
    }
    fetchData(curPages, pageSize);
  }, [curPages]);

  return (
    <Ask
      asks={asks}
      askType={askType}
      curPages={curPages}
      totalPages={totalPages}
      secretModal={secretModal}
      setSecretModal={setSecretModal}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      onPaginationNumber={handlePaginationNumber}
      onPaginationPrev={handlePaginationPrev}
      onPaginationNext={handlePaginationNext}
      onSecretClick={handleSecretClick}
      onSecretCheckClick={handleSecretCheckClick}
    />
  );
};

export default Index;
