import React from 'react';
import Input from '../../atoms/Input';
import { Link } from 'react-router-dom';

const FindEmail = ({
  onFindEmailSubmit,
  ownerEmail,
  secretModal,
  setSecretModal,
}) => {
  return (
    <main className='block w-full overflow-hidden box-border leading-none'>
      <section className='block box-border mx-auto my-0 text-center p-0 align-baseline'>
        <div className='block box-border w-full p-0 my-0 mx-auto overflow-hidden align-baseline'>
          <div className='flex flex-wrap -mx-[10px] m-0 p-0 align-baseline'>
            <div className='box-border block px-2.5 py-0 w-full m-0'>
              <div className='box-border block py-28 px-0 font-sans m-0 align-baseline'>
                <h2 className='box-border block text-4xl tracking-tighter font-black mb-6 ms-0 me-0 m-0 p-0'>
                  이메일 주소 찾기
                </h2>
                <p className='box-border block text-lg tracking-tighter font-medium mb-2 ms-0 me-0 m-0 p-0'>
                  아래의 정보를 입력하시면 가입시 등록한 이메일 주소를 확인하실
                  수 있습니다.
                </p>
              </div>
              <form
                className='box-border block w-1/4 md:min-w-[494px] min-w-[325px] my-0 mx-auto p-0 text-left font-sans text-[#666] align-baseline'
                name='authForm'
                onSubmit={onFindEmailSubmit}
              >
                <Input type='text' name='name' placeholder='이름' />
                <Input
                  type='text'
                  name='phone'
                  placeholder='가입 휴대폰 번호'
                />
                <button
                  className='box-border block h-20 w-full bg-[#2c578a] text-white font-sans cursor-pointer
                                border-none p-0 m-0 text-2xl tracking-tight mt-14'
                  type='submit'
                >
                  확인
                </button>
                {secretModal && (
                  <div className='fixed flex top-0 bottom-0 left-0 right-0 justify-center content-center text-center items-center'>
                    <div
                      className='fixed bg-gray-800 opacity-80 w-full h-full'
                      onClick={() => setSecretModal(false)}
                    ></div>
                    <div className='bg-white flex flex-col justify-center content-center items-center w-1/3 h-80 border border-solid border-sky-800 z-40 text-xl'>
                      <p>해당 정보로 가입된 이메일은 </p>

                      <h2>
                        <b>{ownerEmail}</b> 입니다.
                      </h2>

                      <p className='mt-5'>로그인 페이지로 이동하시려면</p>
                      <p className='mb-5'> 아래 버튼을 클릭해주세요.</p>
                      <Link
                        className='text-center content-center bg-sky-800 text-white w-24 h-12'
                        to='/store/auth'
                      >
                        확인
                      </Link>
                    </div>
                  </div>
                )}
              </form>
              <div className='mb-60'></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FindEmail;
