export default function ApplyPageHeader({ IMG }) {
  return (
    <div className="flex justify-center">
      <div className="relative isolate overflow-hidden w-full h-[100px] md:h-[400px] rounded-3xl">
        <img
          alt=""
          src={IMG}
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
      </div>
    </div>
  );
}
