import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyInfo from './MyInfo';
import { MENU } from '../../../constants/menu';
import { MyPageAPI, SignupAPI, UpdateAPI } from '../../../api';
import { D_APPLY_FORM } from '../../../constants/dummyApply';
import { D_DATA } from '../../../constants/dummyBusinesses';
import { D_STORE_FILE } from '../../../constants/dummyStoreFile';
import { APPLY_FORM } from '../../../constants/apply';
import { SECTORS, SUB_SECTORS } from '../../../constants/sectors';
import {
  PASS_CONDITION_MYINFO,
  PASS_REF,
} from '../../../constants/passCondition';
import Spinner from '../../atoms/Spinner';

const Index = () => {
  const navigate = useNavigate();
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  const d_menu = D_APPLY_FORM.menu;
  const d_store_file = D_APPLY_FORM.storeFile;

  const [menuID, setMenuID] = useState(1);
  const [applyForm, setApplyForm] = useState(APPLY_FORM);
  const [data, setData] = useState(D_DATA);
  const [menuList, setMenuList] = useState([...d_menu]);
  const [storeFileList, setStoreFileList] = useState([...D_STORE_FILE]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passCondition, setPassCondition] = useState(PASS_CONDITION_MYINFO);
  const [ownerPwd, setOwnerPwd] = useState('');
  const [regNo, setRegNo] = useState('');

  // 0: add, 1: update, 2: delete
  const [updatedMenu, setUpdatedMenu] = useState([[], [], []]);
  const [updatedStoreFile, setUpdatedStoreFile] = useState([[], [], []]);
  const refs = PASS_REF();
  const observer = useRef(null);

  const scrollToRef = (ref) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ref.current.focus();
          observer.current.disconnect();
        }
      },
      { threshold: 1.0 }
    );

    observer.current.observe(ref.current);
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const findSectorIdx = (key, value, idx) => {
    if (key == 'type') {
      return SECTORS.findIndex((sector) => sector.code === value);
    } else {
      return SUB_SECTORS[idx].findIndex((sector) => sector.value === value);
    }
  };

  const handlePass = (key, valid) => {
    if (['reg_No', 'reg_Name', 'reg_date'].includes(key)) {
      return;
    }
    setPassCondition((prev) => ({ ...prev, [key]: valid }));
  };

  const isAllTrue = (obj) => {
    return Object.values(obj).every((value) => value === true);
  };

  const findFalseKey = (obj) => {
    return Object.keys(obj).find((key) => obj[key] === false);
  };

  const setStoreFile = (storeFile, idx) => {
    const type = {
      ciImg: ['상호명(CI)', 0],
      outImg: ['가게전면', 1],
      inImg: ['가게내부', 2],
      menuImg: ['메뉴판', 3],
      repImg: ['대표메뉴', 4],
    };
    storeFile.map((item, id) => {
      item.title = type[item.type][0];
      const index = type[item.type][1];
      const orderNo = parseInt(item.order_no);
      item.order_no = orderNo;
      item.img_url = `/file/store/${idx}/` + item.real_name;
      item.store_Img = true;
      setStoreFileList((prev) => {
        const newPrev = [...prev];
        if (newPrev[index][2]) {
          newPrev[index][1] = item;
        } else {
          newPrev[index][0] = item;
        }
        if (newPrev[index][0]) {
          newPrev[index][2] = true;
        }

        return newPrev;
      });
    });
  };

  const setMenu = (menu, idx) => {
    setMenuList(
      menu.map((item, id) => {
        return {
          ...item,
          id: id,
          img_url: `/file/store/menu/${idx}/` + item.real_Name,
        };
      })
    );
  };

  useEffect(() => {
    const getInfo = async () => {
      try {
        const info1 = await MyPageAPI.get(accessToken);
        const info2 = await MyPageAPI.getInfo(accessToken);
        const info = info2.data;
        const filteredInfo = {
          // Filter out "menu" and "storeFile" keys
          ...Object.keys(info)
            .filter(
              (key) =>
                !['menu', 'storeFile', 'idx', 'item_Code', 'item_Etc'].includes(
                  key
                )
            )
            .reduce((obj, key) => {
              if (key === 'close_Info' || key === 'target2_Code') {
                obj[key] = info[key].split(',').map((item) => item.trim());
              } else {
                obj[key] = info[key];
              }
              return obj;
            }, {}),
        };
        filteredInfo.owner_Email = info1.data.owner_Email;
        filteredInfo.reg_No = '';
        setRegNo(info.reg_No);
        filteredInfo.reg_date = '';
        filteredInfo.reg_Name = '';
        filteredInfo.isUpdate = true;

        filteredInfo.owner_Hp = info.owner_Hp;
        filteredInfo.owner_Name = info.owner_Name;
        filteredInfo.owner_Pwd = '';
        filteredInfo.owner_Pwd_ = '';
        setOwnerPwd(info.owner_Pwd);
        filteredInfo.tel_Yn = info.tel_Yn;
        filteredInfo.regularYn = info.regularYn;

        //임시 - 변경해야함
        filteredInfo.agreeYn = 'N';

        filteredInfo.sector = findSectorIdx('type', filteredInfo.type);
        filteredInfo.subSector = findSectorIdx(
          'type2',
          filteredInfo.type2,
          filteredInfo.sector
        );

        setApplyForm(filteredInfo);

        setMenuID(info.menu.length);
        setStoreFile(info.storeFile, info.idx);
        setMenu(info.menu, info.idx);
      } catch (e) {
        console.error(e);
      }
    };
    getInfo();
  }, []);

  useEffect(() => {
    handleData();
  }, [applyForm, menuList, storeFileList]);

  const handleFocus = (e) => {
    e.target.setAttribute('required', '');
  };

  // 사업자정보 데이터
  const handleData = () => {
    setData((prevData) => {
      return {
        ...prevData,
        businesses: [
          {
            b_no: applyForm.reg_No,
            start_dt: applyForm.reg_date,
            p_nm: applyForm.reg_Name,
          },
        ],
      };
    });
  };

  // 전체 폼
  const handleApplyForm = (e) => {
    const { name, value, checked, type } = e.target;
    if (
      name != 'type' &&
      name != 'zipcode' &&
      name != 'addr1' &&
      name != 'addr2'
    ) {
      handlePass(name, e.target.validity.valid);
    }
    if (name == 'reg_No' || name == 'reg_Name' || name == 'reg_date') {
      handlePass('businesses', false);
    }

    // 핸드폰 번호가 변경되었는지 확인하고 `applyForm`에 업데이트합니다.
    if (name === 'owner_Hp') {
      handlePass('owner_Hp', e.target.validity.valid);
    }

    setApplyForm((prevForm) => {
      // prevForm에서 현재 name 값을 가져옴
      const currentValues = prevForm[name] || [];

      // name이 리스트 값을 나타내는 경우
      if (type === 'checkbox') {
        if (checked) {
          // 체크된 경우, value를 리스트에 추가
          return {
            ...prevForm,
            [name]: [...currentValues, value],
          };
        } else {
          // 체크 해제된 경우, value를 리스트에서 제거
          return {
            ...prevForm,
            [name]: currentValues.filter((item) => item !== value),
          };
        }
      }

      if (name === 'type') {
        const sector = findSectorIdx('type', value[0]);
        const subSector = findSectorIdx('type', value[1], sector);
        return {
          ...prevForm,
          type: value[0],
          type2: value[1],
          sector: sector,
          subSector: subSector,
        };
      }
      // 리스트 값이 아닌 경우 기본 처리
      return {
        ...prevForm,
        [name]: value,
      };
    });
  };

  // 메뉴
  const handleMenuChange = (e) => {
    const { name, value, id, files, checked } = e.target;
    setMenuList((prevList) =>
      prevList.map((menu) => {
        if (menu.id == id) {
          if (menu.idx != null) {
            addValueToList(2, parseInt(id));
          }
          if (name === 'menu_Img' && files) {
            return {
              ...menu,
              [name]: files,
              img_url: URL.createObjectURL(files[0]),
            };
          } else if (name == 'free_Yn') {
            return { ...menu, [name]: checked ? 'Y' : 'N' };
          }
          return { ...menu, [name]: value };
        } else {
          return menu;
        }
      })
    );
  };
  const addValueToList = (idx, value, isStore) => {
    if (isStore == null) {
      setUpdatedMenu((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 값이 이미 리스트에 있는지 확인
        if (list.includes(value)) {
          return prev; // 값이 이미 있으면 아무 것도 하지 않고 이전 상태 반환
        }
        // 찾은 리스트에 새로운 요소 추가
        const newList = [...prev];
        newList[idx] = [...list, value];
        return newList;
      });
    } else {
      setUpdatedStoreFile((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 값이 이미 리스트에 있는지 확인
        if (list.includes(value)) {
          return prev; // 값이 이미 있으면 아무 것도 하지 않고 이전 상태 반환
        }
        // 찾은 리스트에 새로운 요소 추가
        const newList = [...prev];
        newList[idx] = [...list, value];
        return newList;
      });
    }
  };

  const removeValueToList = (idx, value, isStore) => {
    if (isStore == null) {
      setUpdatedMenu((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 찾은 리스트에서 value 제거
        const newList = [...prev];
        newList[idx] = newList[idx].filter((item) => item != value);
        return newList;
      });
    } else {
      setUpdatedStoreFile((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 찾은 리스트에서 value 제거
        const newList = [...prev];
        newList[idx] = newList[idx].filter((item) => item != value);
        return newList;
      });
    }
  };

  const handleAddMenu = () => {
    addValueToList(0, menuID);
    setMenuID(menuID + 1);
    setMenuList([...menuList, { ...MENU, id: menuID }]);
  };

  const handleDeleteMenu = (e) => {
    const { id, idx } = e.target.dataset;
    const menuID = parseInt(id);
    removeValueToList(0, menuID);
    if (idx != null) {
      addValueToList(1, idx);
      removeValueToList(2, menuID);
    }
    setMenuList((prevMenus) => prevMenus.filter((menu) => menu.id != menuID));
  };

  // 스토어
  const handleStoreChange = (e) => {
    const { name, files, id, dataset } = e.target;
    if (dataset.originId) {
      addValueToList(1, dataset.originId, true);
    }

    setStoreFileList((prevList) =>
      prevList.map((storeFile, index) => {
        if (index == id) {
          const updatedStoreFile = storeFile.map((file, idx) => {
            if (idx == dataset.id) {
              if (files == null) {
                return {
                  id: null,
                  type: file.type,
                  title: file.title,
                  order_no: file.order_no,
                  store_Img: null,
                };
              }
              return { ...file, [name]: files };
            }
            return file;
          });

          let hasFileAtZeroIndex = false;
          const tmp = updatedStoreFile.map((file, idx) => {
            if (idx == 0 && file.store_Img) {
              hasFileAtZeroIndex = true;
            }
            if (idx == 2) {
              return hasFileAtZeroIndex;
            }
            return file;
          });

          if (!tmp[0]?.store_Img && tmp[1]?.store_Img) {
            addValueToList(2, tmp[1].idx, true);
            return [
              { ...tmp[1], order_no: 0 },
              { ...tmp[0], order_no: 1, store_Img: null },
              !tmp[2],
            ];
          }
          return tmp;
        }
        return storeFile;
      })
    );
  };

  // 시간
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // 옵션을 설정하여 날짜 형식을 지정합니다.
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    // 날짜 형식을 로컬 시간대에 맞게 변환합니다.
    const formattedDate = date
      .toLocaleString('en-GB', options)
      .replace(',', '');

    // '/'로 구분자를 변경합니다.
    const [day, month, yearAndTime] = formattedDate.split('/');
    const [year, time] = yearAndTime.split(' ');
    return `${year}/${month}/${day} ${time}`;
  };

  // blob 변환
  const fileToBlob = (file) => {
    return new Blob([file], { type: file.type });
  };

  const createFormData = (store_idx) => {
    const formData = new FormData();

    // menuList 추가, index로 구분 맞음
    menuList.forEach((menu, index) => {
      const {
        default_Price,
        free_Yn,
        img_url,
        menu_Img,
        menu_Name,
        offer_Price,
        reg_dt,
      } = menu;
      if (
        default_Price &&
        free_Yn &&
        img_url &&
        menu_Img &&
        menu_Name &&
        offer_Price &&
        reg_dt
      ) {
        formData.append(`menuList[${index}].default_Price`, default_Price);
        formData.append(`menuList[${index}].free_Yn`, free_Yn);
        formData.append(`menuList[${index}].img_url`, img_url);
        formData.append(
          `menuList[${index}].menu_Img`,
          fileToBlob(menu_Img[0]),
          menu_Img[0].name
        );
        formData.append(`menuList[${index}].menu_Name`, menu_Name);
        formData.append(`menuList[${index}].offer_Price`, offer_Price);
        formData.append(`menuList[${index}].reg_dt`, formatDate(reg_dt));
      }
    });

    // storeFileList 추가
    storeFileList.forEach((files, idx) => {
      const pIdx = 2 * idx;
      files.map((file, cIdx) => {
        const idx = pIdx + cIdx;
        const { order_no, store_Img, type } = file;
        if (typeof order_no === 'number' && store_Img && type) {
          formData.append(`storeFileList[${idx}].order_no`, String(order_no));
          formData.append(
            `storeFileList[${idx}].store_Img`,
            fileToBlob(store_Img[0]),
            store_Img[0].name
          );
          formData.append(`storeFileList[${idx}].type`, type);
        }
      });
    });

    // id 연결
    formData.append('store_idx', store_idx);

    return formData;
  };

  const createMenu = (menu, isupdate) => {
    const formData = new FormData();
    const {
      default_Price,
      free_Yn,
      img_url,
      menu_Img,
      menu_Name,
      offer_Price,
      reg_dt,
      idx,
    } = menu;
    if (
      default_Price &&
      free_Yn &&
      img_url &&
      menu_Img &&
      menu_Name &&
      offer_Price &&
      reg_dt
    ) {
      formData.append(`default_Price`, default_Price);
      formData.append(`free_Yn`, free_Yn);
      formData.append(`img_url`, img_url);
      formData.append(`menu_Img`, fileToBlob(menu_Img[0]), menu_Img[0].name);
      formData.append(`menu_Name`, menu_Name);
      formData.append(`offer_Price`, offer_Price);
      formData.append(`reg_dt`, formatDate(reg_dt));
      if (isupdate) {
        formData.append(`idx`, idx);
      }
      return formData;
    } else {
      return null;
    }
  };

  const createStoreImg = (file) => {
    const formData = new FormData();

    const { order_no, store_Img, type } = file;
    if (typeof order_no === 'number' && store_Img && type) {
      formData.append(`order_no`, String(order_no));
      formData.append(`store_Img`, fileToBlob(store_Img[0]), store_Img[0].name);
      formData.append(`type`, type);
      return formData;
    }
    return null;
  };

  // 페이지 이동
  const nextTo = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const completeDelete = () => {
    navigate('/');
    window.location.reload();
  };

  // 제출
  const handleSummit = async () => {
    if (!applyForm.reg_No && !applyForm.reg_Name && !applyForm.reg_date) {
      passCondition.businesses = true;
    }
    if (!applyForm.owner_Pwd_ && !applyForm.owner_Pwd) {
      passCondition.owner_Pwd = true;
      passCondition.owner_Pwd_ = true;
    }
    if (!isAllTrue(passCondition)) {
      const condition = findFalseKey(passCondition);
      scrollToRef(refs[condition]);

      if (condition == 'businesses') {
        alert('사업자 등록 번호를 인증해주세요.');
      } else if (condition == 'owner_Name') {
        alert(`이름을 확인해주세요`);
      } else if (condition == 'owner_Email') {
        alert('이메일을 확인해주세요.');
      } else if (condition == 'owner_Hp') {
        alert('휴대폰 번호를 확인해주세요.');
      } else if (condition == 'owner_Pwd_' || condition == 'owner_Pwd') {
        alert('비밀번호를 확인해주세요.');
      } else if (condition == 'name') {
        alert('상호명을 확인해주세요.');
      } else if (condition == 'sector') {
        alert('업종을 선택해주세요.');
      } else if (condition == 'tel') {
        alert('매장 번호를 확인해주세요.');
      } else if (condition == 'address') {
        alert('주소를 입력해주세요.');
      }
      return;
    }
    if (!applyForm.reg_No && !applyForm.reg_Name && !applyForm.reg_date) {
      applyForm.reg_No = regNo;
    }
    if (!applyForm.owner_Pwd_ && !applyForm.owner_Pwd) {
      applyForm.owner_Pwd = ownerPwd;
      applyForm.owner_Pwd_ = ownerPwd;
    }
    if (applyForm.owner_Pwd_ != applyForm.owner_Pwd) {
      scrollToRef(refs['owner_Pwd_']);
      alert('비밀번호 일치 여부를 확인해주세요.');
      return;
    }

    // 메뉴 항목에 대한 밸리데이션
    for (const menu of menuList) {
      if (
        menu.menu_Img &&
        (!menu.menu_Name || !menu.default_Price || !menu.offer_Price)
      ) {
        alert(
          '제공품목의 경우 "품목 이름", "기존 가격", "제공 가격"을 모두 입력해야 합니다.'
        );
        return; // 유효성 검사 실패 시 제출 중단
      }
    }

    setIsSubmitting(true);
    try {
      // 1. store update
      const temp1 = applyForm.close_Info.join(', ').trim();
      const temp2 = applyForm.target2_Code.join(', ').trim();

      const {
        reg_Name,
        reg_date,
        _links,
        isUpdate,
        sector,
        subSector,
        owner_Hp,
        owner_Name,
        owner_Pwd_,
        ...summitData
      } = applyForm;

      const data = {
        ...summitData,
        owner_Hp: applyForm.owner_Hp,
        close_Info: temp1,
        target2_Code: temp2,
      };

      const updateRes = await UpdateAPI.update(data, accessToken);
      const result = updateRes.data;

      // 2. 메뉴
      // add, update
      for (const menu of menuList) {
        if (updatedMenu[0].includes(menu.id)) {
          const data = createMenu(menu);
          if (data) {
            const res = await UpdateAPI.addMenu(data, accessToken);
          }
        } else if (updatedMenu[2].includes(menu.id)) {
          const data = createMenu(menu, true);
          var res;
          if (data) {
            res = await UpdateAPI.updateMenu(data, menu.idx, accessToken);
          }
        }
      }
      // delete
      for (const idx of updatedMenu[1]) {
        const res = await UpdateAPI.deleteMenu(idx, accessToken);
      }

      // 3. 스토어 이미지 update 및 delete
      for (const files of storeFileList) {
        for (const file of files) {
          if (file.store_Img === true) {
            continue;
          }
          if (file.store_Img) {
            const data = createStoreImg(file);
            if (data) {
              const res = await UpdateAPI.addStore(data, accessToken);
            }
          }
        }
      }

      for (const idx of updatedStoreFile[1]) {
        const res = await UpdateAPI.deleteStore(idx, accessToken);
      }
      alert('정보가 변경 되었습니다.');
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false); // 제출 완료 후 상태 초기화
      nextTo();
    }
  };

  // 회원 탈퇴
  const handleStoreDelete = async (password, reason) => {
    const confirmed = window.confirm('정말 탈퇴하시겠습니까?');
    if (confirmed) {
      try {
        const data = { owner_Pwd: password, reason: reason }; // reason이 필요하다면 value를 추가
        const res = await UpdateAPI.withdrawalStore(data);
        if (res.status == 200) {
          alert('회원 탈퇴가 완료되었습니다.');
          completeDelete();
        } else {
          alert('회원 탈퇴에 실패했습니다.');
        }
      } catch (error) {
        console.error(error);
        alert('비밀번호를 확인해주세요.');
      }
    }
  };

  return (
    <>
      {isSubmitting ? (
        <Spinner /> //
      ) : (
        <MyInfo
          {...{
            handleFocus,
            handleApplyForm,
            data,
            menuList,
            storeFileList,
            applyForm,
            refs,
          }}
          onMenuChange={handleMenuChange}
          onAddMenu={handleAddMenu}
          onDeleteMenu={handleDeleteMenu}
          onSummit={handleSummit}
          onStoreChange={handleStoreChange}
          setUpdatedStoreFile={setUpdatedStoreFile}
          onPass={handlePass}
          onStoreDelete={handleStoreDelete}
        />
      )}
    </>
  );
};

export default Index;
