import { useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import { useEffect, useRef, useState } from 'react';

const Index = () => {
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState([false, false, false]);
  const checkboxesRef = useRef([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    // 모든 체크박스가 체크되었는지 확인하여 isAllChecked 상태 업데이트
    const allChecked = checkedItems.slice(1).every((item) => item === true);
    setIsAllChecked(allChecked);
  }, [checkedItems]);

  const handleCheckBox = (e, box) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[box] = e.target.checked;
    setCheckedItems(newCheckedItems);
    if (box == 0 && e.target.checked) {
      setCheckedItems((prev) => prev.map(() => true));
    } else if (box == 0) {
      setCheckedItems((prev) => prev.map(() => false));
    }
  };

  const nextTo = () => {
    if (isAllChecked) {
      navigate('/apply/step/2');
      window.scrollTo(0, 0);
    } else {
      alert('필수 항목에 동의해주세요.');
      const firstUncheckedIndex =
        checkedItems.slice(1).findIndex((item) => !item) + 1;
      if (firstUncheckedIndex !== -1) {
        checkboxesRef.current[firstUncheckedIndex].scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <Step1
      nextTo={nextTo}
      handleCheckBox={handleCheckBox}
      isAllChecked={isAllChecked}
      checkBox1={checkedItems[1]}
      checkBox2={checkedItems[2]}
      checkboxesRef={checkboxesRef}
    />
  );
};

export default Index;
