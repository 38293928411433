import React from 'react';

const Button = ({ title, className, onClick, children }) => {
  return (
    <button className={`bg-[#365ca5] text-white inline-block mb-0 font-normal text-center whitespace-nowrap align-middle cursor-pointer bg-none border border-transparent text-sm leading-normal rounded ${className}`} onClick={onClick}>
      {children ? children : title}
    </button>
  );
};

export default Button;
