import React, { useEffect, useRef, useState } from 'react';
import Devote from './Devote';
import icon1 from '../../../assets/images/devote_card_img_1.png';
import icon2 from '../../../assets/images/devote_card_img_2.png';
import icon3 from '../../../assets/images/devote_card_img_3.png';
const Index = () => {
  const content = [
    {
      title: '단기 • 정기후원',
      describe: [
        '한번이든, 정기적이든 모이면 큰 힘을 발휘합니다.',
        '후원한 금액은 동무이 필요한 아동, 청소년들에게',
        '의미있게 사용됩니다.',
      ],
      img: icon1,
      to: '정기후원하기',
      // href: "https://basket.fund/goodimpact/7",
    },
    {
      title: '기업사회공헌',
      describe: [
        '기업이나 단체와 협업을 통해',
        '소상공인과 아이들에게 선한 영향력이 펼쳐지는',
        '세상을 만들어갑니다.',
      ],
      img: icon2,
      to: '기업 / 단체 후원 문의하기',
      href: 'https://pf.kakao.com/_vSXxob',
    },
    {
      title: '기타공헌',
      describe: [
        '금전이 아닌 직업, 재능, 현물 등으로',
        '단체와 아이들을 위한 사업에 함께 할 수 있습니다.',
        '',
      ],
      img: icon3,
      to: '기타공헌 문의하기',
      href: 'https://pf.kakao.com/_vSXxob',
    },
  ];
  return <Devote {...{ content }} />;
};

export default Index;
