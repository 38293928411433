import Password from "../../../components/atoms/Password";
import HoverButton from "../../atoms/HoverButton";
import ApplyInput from "../ApplyInput/ApplyInput";
import ApplyInputTitle from "../../atoms/ApplyInputTitle";
import ApplyInputContent from "../../atoms/ApplyInputContent";

const BasicInfo = ({
  handleFocus,
  handleApplyForm,
  data,
  isRegulr,
  handleIsRegulr,
  applyForm,
  onPass,
  refs,
}) => {
  return (
    <dl className="flex flex-col md:w-5/6 w-full md:mt-10 items-center ">
      <div className="flex flex-col md:w-2/3 w-full">
        <ApplyInput id={"회원구분"}>
          <ApplyInputTitle title={"회원구분"} redStar={true} />
          <ApplyInputContent>
            <div className="flex flex-row w-full gap-4 md:mb-4 mb-2">
              <div class="flex items-center">
                <input
                  checked={applyForm.regularYn == "Y" ? true : false}
                  id="regular_yn-1"
                  type="radio"
                  value="Y"
                  name="regularYn"
                  class="w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-800"
                  onChange={handleIsRegulr}
                />
                <label
                  for="regular_yn-1"
                  className="ms-2 md:text-base text-xs font-medium "
                >
                  정회원
                </label>
              </div>
              <div class="flex items-center">
                <input
                  checked={applyForm.regularYn == "Y" ? false : true}
                  id="regular_yn-2"
                  type="radio"
                  value="N"
                  name="regularYn"
                  class="w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={handleIsRegulr}
                />
                <label
                  for="regular_yn-2"
                  class="ms-2 md:text-base text-xs font-medium "
                >
                  준회원
                </label>
              </div>
            </div>
            <div className="flex flex-col md:text-base text-2xs">
              <p>
                ※ 정회원은 협회 활동에 대한 참여 및 의결권을 가지고 협회사 대상
                지원 사업 우선권이 있으며,
              </p>
              <p>매년 연회비(120,000원/가입즉시 이체) 납입의무가 있습니다.</p>
              <p>※ 준회원은 협회 활동에 대한 의결권이 없으며,</p>
              <p>매년 연회비(60,000/가입즉시 이체) 납입의무가 있습니다.</p>
            </div>
          </ApplyInputContent>
        </ApplyInput>
        <ApplyInput id={"사업자 등록번호"}>
          <ApplyInputTitle title={`사업자 등록번호`} redStar={true} />
          <ApplyInputContent>
            <div className="flex md:flex-row flex-col md:w-10/12 w-full gap-2 md:p-1 p-0 md:mt-10">
              <label className="flex flex-col md:w-1/4 w-5/6">
                <input
                  name="reg_No"
                  type="text"
                  className="peer h-fit items-center md:text-base text-xs"
                  pattern="^\d{10}$"
                  placeholder="사업자번호"
                  onChange={handleApplyForm}
                  onFocus={handleFocus}
                  value={applyForm.reg_No}
                  ref={refs.businesses}
                />
                <a className="flex border-bottom-gray"></a>
                <p className="mt-2 invisible peer-invalid:visible text-pink-600 md:text-sm text-xs">
                  '-' 제외 : 1010212345
                </p>
              </label>
              <label className="flex flex-col md:w-1/4 w-5/6">
                <input
                  name="reg_date"
                  type="text"
                  className="peer h-fit items-center md:text-base text-xs"
                  pattern="^\d{8}$"
                  placeholder="가입연월일"
                  onChange={handleApplyForm}
                  onFocus={handleFocus}
                  value={applyForm.reg_date}
                />
                <a className="flex border-bottom-gray"></a>
                <p className="mt-2 invisible peer-invalid:visible text-pink-600  md:text-sm text-2xs">
                  예시 : 20000101
                </p>
              </label>
              <label className="flex flex-col md:w-1/4 w-5/6">
                <input
                  name="reg_Name"
                  type="text"
                  className="peer h-fit items-center md:text-base text-xs"
                  pattern="^[\uAC00-\uD7A3]{2,13}$"
                  placeholder="대표자 성명"
                  onChange={handleApplyForm}
                  onFocus={handleFocus}
                  value={applyForm.reg_Name}
                />
                <a className="flex border-bottom-gray"></a>
                <p className="mt-2 invisible peer-invalid:visible text-pink-600  md:text-sm text-2xs">
                  이름은 2~15자 이내로 입력해주세요.
                </p>
              </label>
              <div className="flex w-1/4">
                <HoverButton
                  title={"인증하기"}
                  w={"full"}
                  h={"8 md:h-10"}
                  textSize={"xs md:text-base"}
                  {...{ data }}
                  onPass={onPass}
                ></HoverButton>
              </div>
            </div>
          </ApplyInputContent>
        </ApplyInput>

        <ApplyInput id={"이름"}>
          <ApplyInputTitle title={`이름`} redStar={true} />
          <ApplyInputContent>
            <label className="flex flex-col md:w-2/3 w-5/6 h-fit md:mt-7 mt-0">
              <input
                name="owner_Name"
                type="text"
                className="peer h-full items-center md:text-base text-xs  disabled:bg-gray-50"
                pattern="^[\uAC00-\uD7A3]{2,13}$"
                placeholder="이름을 입력하세요."
                onChange={handleApplyForm}
                onFocus={handleFocus}
                value={applyForm.owner_Name}
                disabled={applyForm.isUpdate === true}
                required
                ref={refs.owner_Name}
              />
              <a className="flex border-bottom-gray"></a>
              <p className="mt-2 invisible peer-invalid:visible text-pink-600  md:text-sm text-2xs">
                이름은 2~15자 이내로 입력해주세요.
              </p>
            </label>
          </ApplyInputContent>
        </ApplyInput>

        <ApplyInput id={"이메일"}>
          <ApplyInputTitle title={`이메일`} redStar={true} />
          <ApplyInputContent>
            <label class="flex flex-col md:w-2/3 w-5/6 md:mt-7 mt-0">
              <input
                name="owner_Email"
                type="email"
                className="peer h-full w-full md:text-base text-xs disabled:bg-gray-50"
                placeholder="이메일을 입력해주세요."
                onChange={handleApplyForm}
                onFocus={handleFocus}
                value={applyForm.owner_Email}
                disabled={applyForm.isUpdate === true}
                ref={refs.owner_Email}
              />
              <a className="flex border-bottom-gray"></a>
              <p class="mt-2 invisible peer-invalid:visible text-pink-600  md:text-sm text-2xs">
                올바른 이메일 형식으로 입력해주세요.
              </p>
            </label>
          </ApplyInputContent>
        </ApplyInput>

        <ApplyInput id={"휴대폰"}>
          <ApplyInputTitle title={`휴대폰`} redStar={true} />
          <ApplyInputContent>
            <label className="flex flex-col md:w-2/3 w-5/6 md:mt-7 mt-0">
              <input
                name="owner_Hp"
                type="tel"
                className="peer md:text-base text-xs"
                pattern="(\d{1,4}-){2}\d{4}"
                placeholder="하이픈을 포함해 번호를 입력해주세요."
                onChange={handleApplyForm}
                onFocus={handleFocus}
                value={applyForm.owner_Hp}
                ref={refs.owner_Hp}
              />
              <a className="flex border-bottom-gray"></a>
              <p className="mt-2 invisible peer-invalid:visible text-pink-600  md:text-sm text-2xs">
                유효한 휴대폰 번호를 입력해주세요.
              </p>
            </label>
          </ApplyInputContent>
        </ApplyInput>

        <Password
          {...{ handleApplyForm, handleFocus, applyForm, refs }}
        ></Password>
      </div>
    </dl>
  );
};

export default BasicInfo;
