import React, { useState } from "react";

const PasswordChangeModal = ({ isOpen, onClose, onSubmit }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage("비밀번호가 일치하지 않습니다. 다시 입력해 주세요.");
      return;
    }
    setErrorMessage("");
    onSubmit(newPassword, confirmPassword);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl font-semibold mb-4">비밀번호 변경</h2>
        <form onSubmit={handleSubmit}>
          <label className="block mb-2">
            새 비밀번호:
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{10,16}$"
              title="비밀번호는 영문자, 숫자, 특수문자를 포함하여 10~16자로 입력해 주세요."
              className="w-full mt-1 p-2 border border-gray-400 rounded border-solid"
            />
          </label>
          <label className="block mb-2">
            새 비밀번호 확인:
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{10,16}$"
              title="비밀번호는 영문자, 숫자, 특수문자를 포함하여 10~16자로 입력해 주세요."
              className="w-full mt-1 p-2 border border-gray-400 rounded border-solid"
            />
          </label>
          {errorMessage && (
            <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          )}
          <button
            type="submit"
            className="mt-4 w-full p-2 bg-sky-800 text-white rounded hover:bg-sky-900"
          >
            변경
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
