import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NoticePopup = ({ notice, onClose }) => {
  const [dontShowToday, setDontShowToday] = useState(false);

  const handleClose = () => {
    if (dontShowToday) {
      const today = new Date();
      today.setHours(23, 59, 59, 999); // set to end of day
      localStorage.setItem('dontShowNoticeToday', today.getTime());
    }
    onClose();
  };

  const handleCheckboxChange = () => {
    setDontShowToday(!dontShowToday);
  };

  return (
    <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50 p-4'>
      <div
        className={`bg-white p-6 rounded-lg relative mx-auto shadow-lg ${
          notice.realName ? 'max-w-screen-md' : 'max-w-md'
        } w-full`}
      >
        <button
          className='absolute top-2 right-2 text-gray-500 hover:text-black'
          onClick={handleClose}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='size-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18 18 6M6 6l12 12'
            />
          </svg>
        </button>
        <div className='text-lg font-semibold mb-4'>{notice.title}</div>
        <div className='text-sm mb-4'>{notice.contents}</div>
        {notice.realName && (
          <Link
            to={
              notice.noticeIdx
                ? `/community/notice/view/${notice.noticeIdx}`
                : '/'
            }
            className='flex justify-center'
          >
            <img
              src={'file/notice/popup/' + notice.realName}
              alt='팝업이미지'
              className='mb-4 w-full h-auto object-contain rounded max-h-60 md:max-h-80 lg:max-h-96'
            />
          </Link>
        )}
        <div className='flex items-center'>
          <input
            type='checkbox'
            id='dontShowToday'
            checked={dontShowToday}
            onChange={handleCheckboxChange}
            className='mr-2 text-sm border border-solid border-gray-400 text-main'
          />
          <label htmlFor='dontShowToday' className=''>
            오늘 하루 안 보기
          </label>
        </div>
      </div>
    </div>
  );
};

export default NoticePopup;
