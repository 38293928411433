import { useRef } from 'react';

const PASS_CONDITION_APPLY = {
  businesses: false,
  owner_Name: false,
  owner_Email: false,
  owner_Hp: false,
  owner_Pwd: false,
  name: false,
  sector: false,
  tel: false,
  address: false,
};

const PASS_CONDITION_MYINFO = {
  businesses: true,
  owner_Name: true,
  owner_Email: true,
  owner_Hp: true,
  owner_Pwd_: true,
  owner_Pwd: true,
  name: true,
  sector: true,
  tel: true,
  address: true,
};

const PASS_REF = () => ({
  businesses: useRef(null),
  owner_Name: useRef(null),
  owner_Email: useRef(null),
  owner_Hp: useRef(null),
  owner_Pwd: useRef(null),
  owner_Pwd_: useRef(null),
  name: useRef(null),
  sector: useRef(null),
  tel: useRef(null),
  address: useRef(null),
});

export { PASS_CONDITION_APPLY, PASS_CONDITION_MYINFO, PASS_REF };
