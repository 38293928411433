import React, { useEffect, useRef, useState } from 'react';
import AskForm from './AskForm';

import { AskAPI } from '../../../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeClearValue } from '../../../../util/safe';
import Spinner from '../../../atoms/Spinner';

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [ask, setAsk] = useState({});
  const [files, setFiles] = useState([]); // local에 선택된 파일들 정보저장
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isContentUpdated, setIsContentUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  const quillElement = useRef(null);
  const quillInstance = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const idx = +queryParams.get('idx');

  const mimeToExtension = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'application/pdf': '.pdf',
    // 필요한 경우 다른 MIME 타입도 추가
  };

  const getExtensionFromMime = (mimeType) => {
    return mimeToExtension[mimeType] || '';
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true); // 데이터 로드 시작 시 로딩 표시
      if (idx) {
        try {
          const result = await AskAPI.getAsk({ id: idx });
          const serverFiles = await result.askFile.map((file) => ({
            ...file,
            state: 'stable',
            type: 'server',
          }));
          setAsk(result);
          setFiles(serverFiles);
        } catch (error) {
          console.error('Failed to fetch ASK:', error);
        }
      }
      setIsLoading(false); // 데이터 로드 완료 시 로딩 종료
    }
    fetchData();
  }, [location.search]);

  const handleFileChange = async (event) => {
    const MAX_FILE_SIZE = 30 * 1024 * 1024; // 10MB in bytes

    const newFiles = Array.from(event.target.files).map((file) => {
      // 파일 크기 체크
      if (file.size > MAX_FILE_SIZE) {
        alert(`${file.name} 파일 크기는 10MB를 초과할 수 없습니다.`);
        return null; // 파일 크기가 10MB를 초과하면 null 반환
      }

      return {
        id: null,
        oriName: file.name,
        realName: file.name,
        state: 'new',
        type: 'local',
        file, // Original file object
      };
    });

    // 유효한 파일만 추가
    const validFiles = newFiles.filter((file) => file !== null);
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDeleteFileChange = (index) => {
    const fileToDelete = files[index];

    // Track files to be deleted from the server
    if (fileToDelete.type === 'server') {
      setDeletedFiles((prev) => [...prev, fileToDelete.idx]);
    }
    // Remove file from local state
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const clearValue = makeClearValue(value);
    setAsk({ ...ask, [name]: clearValue });
  };

  const handleCreate = async () => {
    const userConfirmed = window.confirm('1:1문의를 등록하시겠나요?');

    if (userConfirmed) {
      try {
        const result = await AskAPI.createAsk({ data: ask });
        return 'success';
      } catch (error) {
        alert('오류가 발생했습니다.');
      }
    }
  };

  const handleUpdate = async () => {
    const userConfirmed = window.confirm('1:1문의를 수정하시겠나요?');

    if (userConfirmed) {
      try {
        if (!idx) return;
        const result = await AskAPI.updateAsk({ id: idx, data: ask });
        return 'success';
      } catch (error) {
        alert('오류가 발생했습니다.');
        return 'error';
      }
    }
  };

  const handleDelete = async () => {
    const userConfirmed = window.confirm(
      '1:1문의를 삭제하시겠나요? 삭제한 글은 복구되지 않습니다'
    );

    if (userConfirmed) {
      try {
        const result = await AskAPI.deleteAsk({ id: idx });
        navigate('/admin/ASKList');
        navigate(0);
      } catch (error) {
        alert('오류가 발생했습니다.');
      }
    }
  };

  const handleCancle = () => {
    const userConfirmed = window.confirm(
      '작성을 취소하시겠나요? 취소하시면 작성중인 글은 저장되지 않습니다'
    );

    if (userConfirmed) {
      try {
        navigate('/admin/ASKList');
      } catch (error) {
        alert('오류가 발생했습니다.');
      }
    }
  };

  const handleDeleteFile = async () => {
    try {
      const data = { idxs: [...deletedFiles] };
      const deleteResult = await AskAPI.deleteFileAsk({ data });
    } catch (error) {
      console.log('에러', error);
      alert('오류가 발생했습니다.');
    }
  };

  const handleAddFile = async () => {
    const formData = new FormData();
    let hasAddedFile = false;

    // Append new files to formData

    files.forEach((file) => {
      if (file.state === 'new' && file.type === 'local') {
        formData.append('files', file.file);
        hasAddedFile = true;
      }
    });

    for (let [key, value] of formData.entries()) {
    }

    if (!hasAddedFile) {
      return;
    }

    try {
      const addResult = await AskAPI.addFileAsk({
        id: idx,
        data: formData,
      });
      return 'success';
    } catch (error) {
      console.log('에러', error);
      return 'error'; // 오류 발생
    }
  };

  const base64ToBlob = (base64) => {
    const byteString = atob(base64.split(',')[1]);
    const mimeType = base64.match(/data:(.*?);base64/)[1];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  };

  const uploadImageToServer = async (blobUrl) => {
    const formData = new FormData();
    const file = blobUrl;
    const extension = getExtensionFromMime(file.type);
    const fileName = `editor_embeded${extension}`;
    formData.append('file', file, fileName);

    try {
      const result = await AskAPI.uploadAskImage({ data: formData });
      return result.message;
    } catch (error) {
      console.log('에러', error);
      alert('오류가 발생했습니다.');
    }
  };

  const changeContents = async () => {
    let answer = quillInstance.current.root.innerHTML;

    const parser = new DOMParser();
    const doc = parser.parseFromString(answer, 'text/html');
    const images = doc.querySelectorAll('img');

    const uploadImageAndChangeURL = Array.from(images).map(async (image) => {
      try {
        const base64url = image.src;

        // Check if the image source is a base64 URL
        if (base64url.startsWith('data:image')) {
          const blobUrl = base64ToBlob(base64url);
          const downloadUrl = await uploadImageToServer(blobUrl);

          image.src = downloadUrl;
        } else {
          console.warn('Image source is not a base64 URL:', base64url);
        }
      } catch (error) {
        console.error('Error processing image:', error);
      }
    });

    await Promise.all(uploadImageAndChangeURL);

    const updatedContents = doc.body.innerHTML;

    setAsk((prev) => ({ ...prev, answer: updatedContents, answerYn: 'Y' }));
    setIsContentUpdated(true); // State to indicate the content update is complete
  };

  useEffect(() => {
    if (isContentUpdated) {
      const submitData = async () => {
        setIsLoading(true); // API 요청 시작 시 로딩 표시
        try {
          let result;

          if (!idx) {
            result = await handleCreate();
          } else {
            result = await handleUpdate();
          }

          if (result === 'error') {
            setIsLoading(false); // 에러 발생 시 로딩 종료
            alert('오류가 발생했습니다.');
            return; // 중단
          }

          await handleAddFile(result);

          if (deletedFiles.length > 0) {
            await handleDeleteFile();
          }

          setIsLoading(false); // 에러 발생 시 로딩 종료
          navigate(0);

          if (!idx) {
            navigate('/admin/askList');
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false); // 에러 발생 시 로딩 종료
        }
      };
      submitData();
    }
  }, [isContentUpdated]);

  const handleSubmit = async () => {
    if (!quillInstance.current.root.innerHTML) {
      alert('모든 필수 항목을 입력해주세요.');
      return;
    }

    await changeContents();
  };

  return (
    <>
      {isLoading && <Spinner />}
      {ask.idx && (
        <AskForm
          idx={idx}
          data={ask}
          files={files}
          quillElement={quillElement}
          quillInstance={quillInstance}
          onChange={handleChange}
          onFileChange={handleFileChange}
          onDeleteFileChange={handleDeleteFileChange}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
          onCancle={handleCancle}
        />
      )}
      {!idx && (
        <AskForm
          data={ask}
          files={files}
          quillElement={quillElement}
          quillInstance={quillInstance}
          onChange={handleChange}
          onFileChange={handleFileChange}
          onDeleteFileChange={handleDeleteFileChange}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
          onCancle={handleCancle}
        />
      )}
    </>
  );
};

export default Index;
