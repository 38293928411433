import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AdminAuthAPI } from '../../../../api';

const AdminHeader = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 관리자 데이터를 불러오는 함수 (예시로 사용)
    const fetchAdminData = async () => {
      try {
      } catch (error) {
        console.error('Error fetching admin data:', error);
      }
    };

    fetchAdminData();
  }, []);

  const handleLogout = async () => {
    try {
      const result = await AdminAuthAPI.logout();
      navigate('/admin');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div
      id='admin-header'
      className='flex items-center justify-between bg-main text-white h-16 px-8 py-4'
    >
      <a href='/' target='_blank' rel='noopener noreferrer'>
        <div className="w-24 h-12 bg-[url('./assets/images/footer_logo.png')] bg-cover cursor-pointer" />
      </a>
      <div className='flex items-center'>
        <button onClick={handleLogout} className='ml-4 text-white'>
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default AdminHeader;
