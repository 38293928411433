import { setAuthHeaders } from '.';
import TokenService from './tokenService';

const authAPI = (API) => ({
  login: (params) =>
    API.post('account/login', params)
      .then(function (response) {
        if (response.status === 200) {
          const accessToken = response.headers['access_token'];
        }
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),
  findEmail: (params) =>
    API.post('account/findemail', params)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),
  verifyAccount: (params) =>
    API.post('account/verify', params)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),
  resetPassword: (params) =>
    API.post('account/resetpwd', params)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),

  logout: (params) =>
    API.get('account/weblogout', setAuthHeaders())
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      }),
});
export default authAPI;
