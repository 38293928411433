import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

export default function PageButtonList({ onPaginationPrev, onPaginationNext, curPages, setCurPages, totalPages, onPaginationNumber }) {
  return (
    <nav className="pb-4 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <ArrowLongLeftIcon
        className="mt-3 h-5 w-5 text-gray-400 cursor-pointer"
        aria-hidden="true"
        onClick={() => {
          setCurPages(0);
        }}
      />
      <div className="flex items-center">
        <a href="#" className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" onClick={(e) => onPaginationPrev(e)}>
          이전
        </a>
        <div>
          {[...Array(totalPages).keys()]
            .map((i) => i + 1)
            .slice(parseInt(curPages / 10) * 10, parseInt(curPages / 10) * 10 + 10)
            ?.map((idx) => (
              <div onClick={(e) => onPaginationNumber(e)} className={idx === curPages + 1 ? 'inline-flex items-center border-t-2 border-sky-950 px-4 pt-4 text-sm font-medium text-[#365ca5] cursor-pointer' : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 cursor-pointer hover:border-gray-300 hover:text-gray-700'}>
                {idx}
              </div>
            ))}
        </div>
        <a href="#" className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" onClick={(e) => onPaginationNext(e)}>
          다음
        </a>
      </div>
      <ArrowLongRightIcon
        className="mt-3 h-5 w-5 text-gray-400 cursor-pointer"
        aria-hidden="true"
        onClick={() => {
          setCurPages(totalPages - 1);
        }}
      />
    </nav>
  );
}
