export const D_APPLY_FORM = {
  owner_Email: "dummy@naver.com",
  owner_Name: "더미",
  owner_Pwd: "ejalepdlxj97@",
  owner_Hp: "010-5555-5555",

  name: "더미네 맛집",
  type: "01",
  type2: "카페",
  tel: "010-4141-2424",
  tel_Yn: "y",
  reg_No: "1078614075",
  zipcode: "21016",
  addr1: "인천 계양구 굴재길 2",
  addr2: "101-101",
  sns_Type1: "insta",
  sns_Url1: "dummy@insta",
  sns_Type2: "kakao",
  sns_Url2: "dummy@kakao",
  open_Info: "오픈정보",
  close_Info: ["월", "휴무일 상시변경", "수", "금"],
  target1_Code: "99",
  target1_Etc: "더미더미",
  target2_Code: ["02", "01", "99"],
  target2_Etc: "누구나 ",
  agreeYn: "N",
  regularYn: "n",
  break_End_Hour: "15:00",
  break_Start_Hour: "13:00",
  close_Hour: "20:00",
  open_Hour: "07:00",
  reg_Name: "조주완",
  reg_date: "20020401",
  isdummy: true,
  storeFile: [
    {
      idx: 12934,
      type: "ciImg",
      order_no: "0",
      ori_name: "팀사진_가로.jpeg",
      real_name: "8aa8700ddd8842ad84bdd8c33b7fb3bd.jpeg",
      size: 435492,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
    {
      idx: 12935,
      type: "outImg",
      order_no: "0",
      ori_name: "팀사진_세로.jpeg",
      real_name: "00635d01c0c2447888bfd85dac614023.jpeg",
      size: 307181,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
    {
      idx: 12936,
      type: "inImg",
      order_no: "0",
      ori_name: "팀사진_세로.jpeg",
      real_name: "b67a9c19dad8498a9eafe5b7ab2e990a.jpeg",
      size: 307181,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
    {
      idx: 12937,
      type: "menuImg",
      order_no: "0",
      ori_name: "씬4_1.jpeg",
      real_name: "02583ef3dfcb4fcab0224543c2a8363c.jpeg",
      size: 135584,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
    {
      idx: 12938,
      type: "repImg",
      order_no: "0",
      ori_name: "씬4_1.jpeg",
      real_name: "0a78f4fb50c146639dce132926c5e0da.jpeg",
      size: 135584,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
  ],
  menu: [
    {
      idx: 877,
      menu_Name: "양념치킨",
      free_Yn: "N",
      default_Price: 20000,
      offer_Price: 1000,
      ori_Name: "씬4_1.jpeg",
      real_Name: "63117f35dff14e6f871c8f3d2529756d.jpeg",
      size: 135584,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
    {
      idx: 878,
      menu_Name: "후라이드",
      free_Yn: "Y",
      default_Price: 15000,
      offer_Price: 0,
      ori_Name: "씬4_2.jpeg",
      real_Name: "4f9390776e7a476d8e92f55a501b8b8d.jpeg",
      size: 841179,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
  ],
};

// tel_Yn;
// reg_No;
// agreeYn;
// regularYn;
// reg_Name;
// reg_date;

const value = {
  name: "오에성 닭갈비",
  type: "01",
  type2: "주류",
  tel: "010-1234-0231",
  zipcode: "13480",
  addr1: "경기 성남시 분당구 대왕판교로 477",
  addr2: "101동",
  sns_Type1: "01",
  sns_Url1: "올로롤",
  sns_Type2: "02",
  sns_Url2: "페이스북",
  open_Info: "안열어",
  open_Hour: "07:47",
  close_Hour: "20:51",
  break_Start_Hour: "07:50",
  break_End_Hour: "18:51",
  close_Info: ", 월, 일, 휴무일 상시변경",
  item_Code: "",
  item_Etc: "",
  target1_Code: "02",
  target1_Etc: "",
  target2_Code: ", 99, 02, 01",
  target2_Etc: "경찰도 됨",
  storeFile: [
    {
      idx: 12938,
      type: "changeImg",
      order_no: "0",
      ori_name: "팀사진_가로.jpeg",
      real_name: "08b9e077ef1d4971ab3ed61c9400a33d.jpeg",
      size: 135584,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
  ],
  menu: [
    {
      idx: 877,
      menu_Name: "지옥의 양념치킨",
      free_Yn: "Y",
      default_Price: 1000,
      offer_Price: 0,
      ori_Name: "씬4_1.jpeg",
      real_Name: "206a63b3a0154ad687d42025d02e1206.jpeg",
      size: 135584,
      reg_dt: "2024-06-25T05:54:39.000+00:00",
      img_url: null,
    },
  ],
  _links: {
    self: {
      href: "http://61.75.91.34:8081/api/store/4373",
    },
  },
};
