import { StarIcon } from '@heroicons/react/16/solid';
import React from 'react';
import { Link } from 'react-router-dom';

const AskForm = ({
  dropdownOpen,
  setDropdownOpen,
  isSecret,
  secretRef,
  contentsLength,
  emailNotify,
  emailRef,
  isEmailValid,
  fileName,
  onAskSubmit,
  onSecretChange,
  onFileChange,
  onEmailChange,
  onEmailNotifyChange,
  onContentsChange,
}) => {
  return (
    <section className='block mx-auto my-0 text-center p-0 align-baseline'>
      <div className='flex flex-col items-center justify-center p-0 align-baseline'>
        <div className='box-border w-full block py-20 px-0 font-sans align-baseline'>
          <h2 className='box-border block text-4xl tracking-tighter font-black mb-2 ms-0 me-0 m-0 p-0 underline-yellow'>
            커뮤니티
          </h2>
        </div>
        <div className='box-border block pb-10 font-sans w-3/4'>
          <ul className='xl:flex xl:list-none hidden'>
            <Link
              to='/community/notice'
              className='font-medium text-xl flex-grow w-1/2 border border-solid bg-gray-100 h-20 flex justify-center items-center hover:underline hover:decoration-solid hover:text-sky-800'
            >
              <li className=''>공지사항</li>
            </Link>
            <Link
              to='/community/ask'
              className='text-white font-medium text-xl flex-grow w-1/2 bg-sky-800 h-20 flex justify-center items-center hover:underline hover:decoration-solid '
            >
              <li className=''>1:1문의</li>
            </Link>
            <Link
              to='/community/faq'
              className='font-medium text-xl flex-grow w-1/2 border border-solid bg-gray-100 h-20 flex justify-center items-center hover:underline hover:decoration-solid hover:text-sky-800'
            >
              <li className=''>FAQ</li>
            </Link>
          </ul>
          <div className='xl:hidden flex cursor-pointer relative'>
            <div
              className='flex align-center items-center justify-between font-medium text-xl w-full border text-sky-800 border-solid border-sky-800 p-4 '
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              1:1 문의
              <svg
                class='-mr-1 h-5 w-5 text-gray-400'
                viewBox='0 0 20 20'
                fill='currentColor'
                aria-hidden='true'
              >
                <path
                  fill-rule='evenodd'
                  d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                  clip-rule='evenodd'
                />
              </svg>
            </div>
            {dropdownOpen && (
              <div>
                <Link
                  to='/community/notice'
                  className='absolute left-0 align-center items-center p-4 font-medium text-xl text-sky-800 flex w-full translate-y-14 bg-white border border-solid border-sky-800 mt-1'
                >
                  공지
                </Link>
                <Link
                  to='/community/faq'
                  className='absolute left-0 align-center items-center p-4 font-medium text-xl text-sky-800 flex w-full translate-y-28 bg-white border border-solid border-sky-800 mt-1'
                >
                  FAQ
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className='box-border block font-sans w-3/4 mb-40'>
          <form name='insForm' id='insForm' onSubmit={(e) => onAskSubmit(e)}>
            <div className='xl:leading-10 xl:text-2xl xl:pl-2 text-xl py-9 border-t-2 border-solid border-t-gray-500 bg-gray-100 border-b border-b-gray-200 mb-5'>
              <h3 className='text-start text-gray-600 tracking-tighter pl-4'>
                상세항목 입력
              </h3>
            </div>

            <dl>
              <div className='xl:flex-row xl:pl-6 flex my-5 px-4 flex-col'>
                <dt className='xl:basis-44 leading-10 text-xl flex items-center tracking-tight'>
                  문의구분
                  <StarIcon className='w-2 ml-2' />
                </dt>
                <dd className='xl:flex-row flex flex-grow flex-col'>
                  <div className='xl:mt-0 flex flex-grow float-left mt-4'>
                    <label className='xl:w-60 w-full'>
                      <select
                        className='xl:w-60 leading-10 text-xl w-full border border-solid border-gray-300 inline-block text-gray-500'
                        name='type'
                        required
                      >
                        <option value=''>== 선택 ==</option>
                        <option value='01'>회원정보</option>
                        <option value='02'>선한가게신청</option>
                        <option value='03'>후원</option>
                        <option value='04'>기타</option>
                        <option value='05'>학생</option>
                      </select>
                    </label>
                  </div>

                  <div className='xl:mt-0 flex flex-col-reverse items-end float-right mt-4 w-full'>
                    <label className='inline-flex items-center mt-2'>
                      <input
                        className='border border-solid border-gray-300 h-5 w-5 mr-2 focus:ring-0'
                        type='checkbox'
                        name='secretYn'
                        value={isSecret}
                        onChange={(e) => onSecretChange(e)}
                      />
                      <span className='text-lg tracking-tighter'>비밀글</span>
                    </label>
                    <input
                      className='xl:w-auto xl:mt-0 read-only:bg-gray-100 read-only:placeholder-gray-500 leading-10 text-xl w-full border border-solid border-gray-300 placeholder-transparent mt-2'
                      type='password'
                      name='secretPwd'
                      maxLength='15'
                      placeholder='비밀번호 입력'
                      readOnly={!isSecret}
                      autoComplete='off'
                      ref={secretRef}
                    />
                  </div>
                </dd>
              </div>

              <div className='xl:flex-row xl:my-10 xl:pl-6 flex px-4 flex-col'>
                <dt className='xl:basis-44 leading-10 text-xl flex items-center tracking-tight'>
                  제목
                  <StarIcon className='w-2 ml-2' />
                </dt>
                <dd className='xl:mt-0 flex flex-grow mt-4'>
                  <div className='flex flex-grow'>
                    <input
                      className='leading-10 text-xl  border border-solid border-gray-300 w-full'
                      type='text'
                      name='title'
                      maxLength='20'
                      placeholder='제목을 입력해 주세요'
                      required
                    />
                  </div>
                </dd>
              </div>

              <div className='xl:flex-row xl:my-10 xl:pl-6 xl:mt-0 flex flex-col px-4 mt-4'>
                <dt className='xl:basis-44 leading-10 text-xl flex items-center tracking-tight'>
                  내용
                  <StarIcon className='w-2 ml-2' />
                </dt>
                <dd className='flex flex-grow'>
                  <div className='xl:mt-0 flex flex-grow relative mt-4'>
                    <textarea
                      className='text-xl border border-solid border-gray-300 w-full resize-none'
                      name='contents'
                      cols='30'
                      rows='10'
                      maxLength='1000'
                      onChange={(e) => onContentsChange(e)}
                      required
                    ></textarea>
                    <span className='absolute text-xl tracking-tighter text-gray-500 right-5 bottom-2'>
                      <em>{contentsLength}</em> / 1000
                    </span>
                  </div>
                </dd>
              </div>

              <div className='xl:flex-row xl:my-10 xl:pl-6 xl:mt-0 flex px-4 mt-4 flex-col'>
                <dt className='leading-10 text-xl flex xl:basis-44 items-center tracking-tight'>
                  사진첨부
                </dt>
                <dd className='flex flex-col'>
                  <div className='xl:flex-row xl:items-center xl:mt-0 flex flex-grow flex-col items-start mt-4'>
                    <label className='xl:w-auto mr-5 w-full'>
                      <span className='xl:w-40 inline-flex justify-center items-center tracking-tighter w-full h-14 text-lg bg-gray-200 text-gray-500'>
                        찾아보기
                      </span>
                      <input
                        className='hidden'
                        type='file'
                        name='file'
                        id='imgFile'
                        accept='.gif, .jpg, .png'
                        onChange={(e) => onFileChange(e)}
                      />
                    </label>
                    <span className='tracking-tighter text-sky-900'>
                      *이미지파일 JPG, PNG, GIF 500k 이하로 등록 가능합니다.
                    </span>
                  </div>
                  <span className='flex flex-col flex-grow items-start tracking-tighter text-gray-500 text-lg mt-2'>
                    {fileName}
                  </span>
                </dd>
              </div>

              <div className='xl:flex-row xl:my-10 xl:pl-6 xl:mt-0 flex px-4 mt-4 flex-col'>
                <dt className='xl:basis-44 leading-10 text-xl flex items-center tracking-tight text-center'>
                  답변이메일 알림
                </dt>
                <dd className='flex flex-grow flex-col items-start'>
                  <div className='xl:flex-row xl:mt-0 flex flex-col flex-grow mb-4 w-full mt-4'>
                    <input
                      className='read-only:bg-gray-100 read-only:placeholder-gray-500 inline-flex flex-grow leading-10 text-xl w-full border border-solid placeholder-transparent border-gray-300'
                      type='email'
                      name='notiEmail'
                      placeholder='example@example.com'
                      readOnly={!emailNotify}
                      ref={emailRef}
                      onChange={(e) => onEmailChange(e)}
                    />
                    <label className='xl:ml-4 xl:mt-0 inline-flex w-full items-center mt-2'>
                      <input
                        className='w-5 h-5 mr-2 border border-solid border-gray-300 focus:ring-0'
                        type='checkbox'
                        name='notiYn'
                        value={emailNotify}
                        onChange={(e) => onEmailNotifyChange(e)}
                      />
                      <span className='text-lg'>이메일 수신</span>
                    </label>
                  </div>
                  <p className='xl:text-base text-sm tracking-tighter'>
                    문의 내용에 대한 답변 알림 수신을 원치 않으시면 체크를
                    해제해주세요.
                  </p>
                  <p
                    className='xl:items-start xl:w-auto xl:text-lg w-full tracking-tighter text-red-500 text-sm mt-2'
                    hidden={isEmailValid}
                  >
                    올바른 메일 형식이 아닙니다.
                  </p>
                </dd>
              </div>
            </dl>

            <div className='xl:mt-0 flex justify-center mt-6'>
              <button
                type='button'
                className='inline-flex justify-center items-center bg-white border-2 border-solid border-sky-800 text-sky-800 text-xl mr-3 w-52 h-16'
                id='cancelBtn'
                onClick={() => {
                  const userConfirmed =
                    window.confirm('작성중인 글을 취소하시겠습니까?');
                  if (userConfirmed) {
                    window.history.back(); // 페이지 뒤로가기
                  }
                }}
              >
                취소
              </button>
              <button
                type='submit'
                className='inline-flex justify-center items-center bg-sky-800  text-white text-xl w-52 h-16'
                id='saveBtn'
              >
                접수하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AskForm;
