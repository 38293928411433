import { useEffect, useState } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';

const initialTabs = [
  { name: '의장인사말', href: 'sectoon1', current: true },
  { name: 'CI', href: 'CI', current: false },
  { name: '연혁', href: '연혁', current: false },
  { name: '찾아오시는 길', href: '찾아오시는 길', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function IntroTab() {
  const [tabs, setTabs] = useState(initialTabs);

  useEffect(() => {
    console.log(tabs);
  }, [tabs]);

  const handleSelectTab = (e) => {
    const { value } = e.target;
    const selectedTab = tabs.find((tab) => tab.name === value);

    setTabs((prev) =>
      prev.map((item) =>
        item.name === value
          ? { ...item, current: true }
          : { ...item, current: false }
      )
    );

    if (selectedTab) {
      scroller.scrollTo(selectedTab.href, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  const handleTabClick = (name) => {
    setTabs((prev) =>
      prev.map((item) =>
        item.name === name
          ? { ...item, current: true }
          : { ...item, current: false }
      )
    );
  };

  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        <select
          id='tabs'
          name='tabs'
          className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-black focus:outline-none focus:ring-black sm:text-sm'
          onChange={handleSelectTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav aria-label='Tabs' className='-mb-px flex'>
            {tabs.map((tab) => (
              <div
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-b-2 border-solid'
                    : 'border-b-2 border-gray-400 border-solid',
                  'flex text-center px-6'
                )}
              >
                <ScrollLink
                  to={tab.href}
                  spy={true}
                  smooth={true}
                  duration={500}
                  name={tab.name}
                  aria-current={tab.current ? 'page' : undefined}
                  className={classNames(
                    tab.current
                      ? 'border-black text-black'
                      : 'border-transparent text-gray-400 hover:border-gray-200 hover:text-gray-500',
                    'whitespace-nowrap border-b-2 px-1 py-4 text-2xl font-bold cursor-pointer'
                  )}
                  onClick={() => handleTabClick(tab.name)}
                >
                  {tab.name}
                </ScrollLink>
              </div>
            ))}
            <div className='flex flex-grow w-full border-b-2 border-solid border-gray-400'></div>
          </nav>
        </div>
      </div>
    </div>
  );
}
