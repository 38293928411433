const STORE_FILE_TITLES = [
  { title: "상호명(CI)", type: "ciImg" },
  { title: "가게전면", type: "outImg" },
  { title: "가게내부", type: "inImg" },
  { title: "메뉴판", type: "menuImg" },
  { title: "대표메뉴", type: "repImg" },
];

const STORE_FILE_INFO = {
  idx: null,
  type: "",
  title: "",
  order_no: "0",
  store_Img: null,
};

const STORE_FILE = [];

STORE_FILE_TITLES.forEach((item) => {
  let innerArray = [];
  for (let i = 0; i < 2; i++) {
    innerArray.push({
      ...STORE_FILE_INFO,
      title: item.title,
      type: item.type,
      order_no: i,
    });
  }
  innerArray.push(false);
  STORE_FILE.push(innerArray);
});

export { STORE_FILE, STORE_FILE_INFO };
