import { Fragment } from "react";
import nextIcon from "../../assets/images/detail.png";
import nextIcon2 from "../../assets/images/go_basket_button.png";
import xButton from "../../assets/images/X_button.png";

export default function HomeContent({
  onlyContent,
  content,
  onLink,
  id,
  onModal,
}) {
  return (
    <>
      <div className="flex bg-white lg:px-8 mt-6 w-full px-4 justify-center">
        <div className="flex flex-row mx-auto text-base leading-7 text-gray-700 w-full justify-center items-center">
          <h1 className="flex-grow mt-2 ml-2 lg:text-4xl text-xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
            {content.title}
          </h1>
          <div className="flex items-end">
            <img
              src={xButton}
              className="object-cover h-fit hover:cursor-pointer"
              alt="Close Button"
              onClick={onModal}
            />
          </div>
        </div>
      </div>

      <div className="bg-white px-6 lg:px-8 mb-12 flex flex-col items-center">
        <div className="mx-auto max-w-full md:text-base text-sm leading-7 text-gray-700">
          <div className="mt-8 max-w-2xl mx-auto">
            <h3 className="font-bold">
              {content.describe.map((title, index) => (
                <Fragment key={index}>
                  {title}
                  <br />
                </Fragment>
              ))}
            </h3>
            <ul
              role="list"
              className="max-w-xl space-y-3 text-gray-600 md:text-base text-sm"
            >
              {content.items.map((content, index) => (
                <li key={index} className="flex gap-x-3 items-center">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {content.subTitle}
                    </strong>
                    <br />
                    {content.contents.map((item, itemIndex) => (
                      <Fragment key={itemIndex}>
                        {item}
                        <br />
                      </Fragment>
                    ))}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-center mt-10 hover:cursor-pointer">
            <img
              src={id != null ? nextIcon : nextIcon2}
              target="_blank"
              onClick={onLink}
              id={id}
              className={id != null ? `flex md:w-36 w-24` : `flex md:w-56 w-40`}
              style={{ display: id != null ? "block" : "none" }}
            />
          </div>
          <div className="flex justify-center mt-10">
            <img
              src={content.img}
              className="object-cover w-full h-auto max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl"
            />
          </div>
        </div>
      </div>
    </>
  );
}
