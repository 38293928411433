import Modal from './Modal';
import IMG1 from '../../assets/images/modal_img_1.png';
import IMG2 from '../../assets/images/modal_img_2.png';
import IMG3 from '../../assets/images/modal_img_3.png';
import MAIN_IMG1 from '../../assets/images/bizinfo_img_1.png';
import MAIN_IMG2 from '../../assets/images/bizinfo_img_2.png';
import MAIN_IMG3 from '../../assets/images/bizinfo_img_3.png';

const posts = [
  {
    id: 1,
    title: '매장동행사업',
    href: '/test',
    description: '아동 지역생활권 매장이 직접 후원',
    imageUrl: MAIN_IMG1,
  },
  {
    id: 2,
    title: '같이동행사업',
    href: '#',
    description: '보육시설, 지역아동센터 등 지원',
    imageUrl: MAIN_IMG2,
  },
  {
    id: 3,
    title: '기업과 함께하는 사회공헌 프로그램',
    href: '#',
    description: '특별한 외식사업, 재료구입비 지원',
    imageUrl: MAIN_IMG3,
  },
];

const content1 = [
  {
    img: IMG1,
    title: '매장동행사업',
    describe: [
      '아동 지역에 있는 선한영향력가게 매장에서 판매하는 음식 및 서비스를 직접 제공하는 나눔사업입니다.',
      '아동 대상 불가업종을 제외하고 다양한 업종이 참여 가능합니다.',
    ],
    items: [
      {
        subTitle: '',
        contents: [
          '현재 선한영향력가게는 음식점, 커피전문점, 교육학원, 체력단련, 직업교육, 미용,  헬스, 꽃, 과일, 안경, 병원, 약국, 펜션, 판매, 도소매, 제조 등의 업종들이 참여하고 있습니다.',
        ],
      },
    ],
  },
  {
    img: IMG2,
    title: '같이동행사업',
    describe: [],
    items: [
      {
        subTitle: '푸드트럭',
        contents: [
          '스테이크 도시락, 타코, 에이드 등 아이들이 좋아하지만 시설에서 접하기 어려운 음식으로 구성',
          '푸드트럭이라는 새로운 문화 생활의 기회를 제공',
        ],
      },
      {
        subTitle: '지역나눔활동',
        contents: [
          '전국 지역아동센터와 그룹홈 등 찾아가는 지역 단위 나눔행사 후원 캠페인 후원금과 지역 회원사 동참으로 더 커지는 나눔을 실천합니다.',
        ],
      },
    ],
  },

  {
    img: IMG3,
    title: '기업동행사업',
    describe: [
      '소상공인을 위한 사업을 하니 아이들에게 후원 됩니다.',
      '아이들을 후원하니 소상공인 매출이 증가됩니다.',
    ],
    items: [],
  },
];

export default function NewCardNews2({ showModal, onModal, onLink }) {
  return (
    <div className="bg-white md:py-12 py-4">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post, idx) => (
            <>
              <Modal
                show={showModal[idx]}
                content={content1[idx]}
                onLink={onLink}
                id={idx}
                onModal={onModal}
              />
              <article
                key={post.id}
                className="flex flex-col items-start"
                data-id={idx}
              >
                <div
                  className="relative w-full"
                  data-id={idx}
                  onClick={onModal}
                >
                  <img
                    src={post.imageUrl}
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  <div className="absolute right-0 bottom-0 m-4">
                    <div className="bg-white rounded-full p-2 cursor-pointer hover:bg-gray-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="max-w-xl">
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a onClick={onModal}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-2 line-clamp-3 text-sm leading-6 text-gray-600">
                      {post.description}
                    </p>
                  </div>
                </div>
              </article>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
