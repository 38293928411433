const Terms02 = () => {
  return (
    <div className="max-w-4xl mx-auto p-5  text-gray-700">
      <div class="textbox check2-area document" style={{ display: "block" }}>
        <div class="document-body">
          <div class="mb-150">
            <p class="mb-30">
              선한영향력가게(이하 ‘협회')는 개인정보보호법 제30조에 따라
              정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
              원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
              처리지침을 수립·공개합니다.
            </p>
            <p>
              협회에서 운영하는 모든 사이트에도 동일하게 적용됩니다. 단,
              사이트의 서비스 특수성 및 전문성을 고려하여 상세한 개인정보 관리가
              요구되는 경우에는 별도의 개인정보 처리방침을 둘수 있습니다.
            </p>
          </div>
          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              01.개인정보의 처리목적
            </h3>
            <p className="mb-4">
              협회는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
              개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
              목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
              동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </p>
            <dl className="list-disc pl-4 mb-4">
              <div className="mb-4">
                <dt className="font-bold">온라인 회원 가입 및 관리</dt>
                <dd>
                  회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
                  회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인,
                  서비스 부정이용 방지 각종 고지·통지, 고충처리 등을 목적으로
                  개인정보를 처리합니다.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-bold">민원사무처리</dt>
                <dd>
                  민원사항 확인, 사실 확인을 위한 연락·통지, 처리결과 통보 등의
                  목적으로 개인정보를 처리합니다.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-bold">
                  협회 사업 안내 및 홍보, 유관기관 사업 홍보를 위한 처리
                </dt>
                <dd>협회 내에서 수행하는 모든 사업과 유관기관 사업.</dd>
              </div>
              <div className="mb-4">
                <dt className="font-bold">
                  세미나·포럼, 교육·연수, 교류회, 조사, 기타 사업 등 안내 및
                  후속 사업 안내
                </dt>
              </div>
              <div>
                <dt className="font-bold">
                  협회는 사업안내 및 홍보를 위한 정보 이외의 민감정보는 수집하지
                  않습니다.
                </dt>
              </div>
            </dl>
          </li>
          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              02. 개인정보의 처리 및 보유기간
            </h3>
            <ul>
              <li>
                <p className="mb-2">
                  협회는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
                  개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
                  개인정보를 처리·보유합니다.
                </p>
              </li>
              <li>
                <p>
                  각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. 협회는
                  법령의 규정과 정보주체의동의에 의해서만 개인정보를
                  수집·보유하며 그 보유기간 및 이용기간은 이용자가 회원가입을
                  신청한 시점부터 해지하겠다는 의사 표시를 하여 해지가 처리된
                  시점까지로 합니다. 사업참가자가 사업중지 및 정보 수신 거부 시
                  개인정보를 파기합니다. 홈페이지 회원가입으로 수집된 개인정보의
                  경우 회원탈퇴 시를 개인정보 파기 사유가 발생한 경우로 판단하여
                  파기합니다.
                </p>
              </li>
            </ul>
          </li>

          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              03. 개인정보의 제3자 제공
            </h3>
            <p>협회는 개인정보를 제3자에게 제공하지 않습니다.</p>
          </li>

          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              04. 정보주체의 권리·의무 및 행사방법
            </h3>
            <ol className="list-decimal pl-4">
              <li className="mb-2">
                <h4 className="font-bold mt-3">
                  정보주체는 협회에 대해 언제든지 다음 각 호의 개인정보 보호
                  관련 권리를 행사할 수 있습니다.
                </h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">개인정보 열람요구</li>
                  <li className="mb-2">오류 등이 있을 경우 정정 요구</li>
                  <li className="mb-2">삭제 요구</li>
                  <li>처리정지 요구</li>
                </ul>
              </li>
              <li className="mb-2">
                <h4 className="font-bold mt-3">
                  제1항에 따른 권리 행사는 협회에 대해 서면, 전화, 전자우편 등을
                  통하여 하실 수 있으며 협회는 이에 대해 지체 없이
                  조치하겠습니다.
                </h4>
              </li>
              <li className="mb-2">
                <h4 className="font-bold mt-3">
                  정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                  경우에는 협회는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
                  이용하거나 제공하지 않습니다.
                </h4>
              </li>
              <li className="mb-2">
                <h4 className="font-bold mt-3">
                  제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
                  자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
                  보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
                  합니다.
                </h4>
              </li>
              <li>
                <h4 className="font-bold mt-3">
                  정보주체는 개인정보 보호법 등 관계법령을 위반하여 협회가
                  처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
                  침해하여서는 아니됩니다.
                </h4>
              </li>
            </ol>
          </li>
          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              05. 처리하는 개인정보 항목
            </h3>
            <p className="mb-2">
              협회는 다음의 개인정보 항목을 처리하고 있습니다.
            </p>
            <h5 className="font-bold mt-3">[온라인 회원사 가입 및 관리]</h5>
            <p className="mb-2">
              필수항목 : 이메일(ID), 이름, 비밀번호, 휴대폰 번호, 전화번호,
              상호명, 업종, 지역, 사업장주소, 사업자등록번호
            </p>
            <ol className="list-decimal pl-4">
              <li className="mb-2">
                인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로
                생성되어 수집될 수 있습니다.
                <ul>
                  <li className="mb-2">
                    IP주소, 쿠키, 서비스 이용기록, 방문기록 등
                  </li>
                  <li className="mb-2">
                    협회는 귀하의 정보를 수시로 저장하고 찾아내는
                    ‘쿠키(cookie)’를 운용합니다. 쿠키란 협회의 웹사이트를
                    운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주
                    작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
                    협회는 다음과 같은 목적을 위해 쿠키를 사용합니다. 회원과
                    비회원의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과
                    관심분야를 파악하여 서비스 개편 등의 척도로 활용합니다.
                  </li>
                  <li className="mb-2">
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서
                    이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
                    허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면
                    모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를
                    거부하셨을 경우 일부 서비스제공에 어려움이 있을 수 있습니다.
                    <p className="mb-2">
                      1. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.
                      <br />
                      2. [개인정보 탭]을 클릭합니다.
                      <br />
                      3. [개인정보취급 수준]을 설정하시면 됩니다.
                    </p>
                  </li>
                  <li>
                    협회는 사업안내 및 홍보를 위한 정보 이외의 민감정보는
                    수집하지 않습니다.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              06. 개인정보의 파기
            </h3>
            <ol className="list-decimal pl-4">
              <li className="mb-2">
                협회는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
              </li>
              <li className="mb-2">
                정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나
                처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
                계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
              </li>
              <li>
                개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <ul className="list-decimal pl-4 mt-2">
                  <li className="mb-2">
                    <strong>[파기절차]</strong>
                    <ul className="list-disc pl-4">
                      <li className="mb-2">
                        개인정보: 이용자가 입력한 정보는 목적 달성 후 지체 없이
                        파기합니다.
                      </li>
                      <li>
                        개인정보파일: 처리 목적 달성, 해당 서비스의 폐지, 사업의
                        종료 등 그 개인정보 파일이 불필요하게 되었을 때에는
                        개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체
                        없이 파기합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>[파기방법]</strong>
                    <ul className="list-disc pl-4">
                      <li className="mb-2">
                        전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
                        사용합니다.
                      </li>
                      <li>
                        종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              07. 개인정보 보호책임자
            </h3>
            <ul>
              <li>
                <ol className="list-decimal pl-4">
                  <li className="mb-2">
                    협회는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                    개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
                    위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                    <ul className="list-disc pl-4 mt-2">
                      <li>개인정보 관리 책임자 : 오인태 대표</li>
                      <li>
                        개인정보 관리 담당자 : 김은아 사무국장{" "}
                        <a href="mailto:keahkey@gmail.com">keahkey@gmail.com</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    이용자는 협회의 서비스(또는 사업)을 이용하면서 발생한 모든
                    개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                    개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
                    협회는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴
                    것입니다.
                  </li>
                </ol>
              </li>
            </ul>
          </li>

          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              08. 개인정보 처리방침의 변경에 관한 사항
            </h3>
            <ul>
              <li>
                <p>이 개인정보 처리방침은 2021. 7. 15부터 적용됩니다.</p>
              </li>
            </ul>
          </li>

          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              09. 동의를 거부할 권리가 있다는 사실과 동의 거부에 따른 불이익
            </h3>
            <ul>
              <li>
                <p>
                  이용자는 협회에서 수집하는 개인정보에 대해 동의를 거부할
                  권리가 있으며 동의 거부 시에는 협회 정보제공 서비스가 일부
                  제한됩니다.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h3 className="text-xl font-semibold mt-5 mb-2">
              10. 권익침해 구제방법
            </h3>
            <p className="mb-2">
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
              등을 문의하실 수 있습니다.
            </p>
            <ul>
              <li className="mb-2">
                <h4 className="font-bold mt-3">개인정보분쟁 조정위원회</h4>
                <ul className="list-decimal pl-4">
                  <li className="mb-2">
                    홈페이지 :{" "}
                    <a target="_blank" href="https://www.kopico.go.kr">
                      https://www.kopico.go.kr
                    </a>
                  </li>
                  <li>전화번호 : (국번없이) 118 (내선2번)</li>
                </ul>
              </li>
              <li className="mb-2">
                <h4 className="font-bold mt-3">대검찰청 사이버범죄 수사단</h4>
                <ul className="list-decimal pl-4">
                  <li className="mb-2">
                    홈페이지 :{" "}
                    <a target="_blank" href="http://www.spo.go.kr">
                      http://www.spo.go.kr
                    </a>
                  </li>
                  <li>전화번호 : 02-3480-3582</li>
                </ul>
              </li>
            </ul>
          </li>

          <ol className="space-y-4">
            {/* <li>
              <h3>01.개인정보의 처리목적</h3>
              <p class="mb-50">
                협회는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
                개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
                목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
                동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </p>

              <dl class="list-disc">
                <div class="mb-50">
                  <dt>온라인 회원 가입 및 관리</dt>
                  <dd>
                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
                    식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른
                    본인확인, 서비스 부정이용 방지 각종 고지·통지, 고충처리 등을
                    목적으로 개인정보를 처리합니다.
                  </dd>
                </div>
                <div class="mb-50">
                  <dt>민원사무처리</dt>
                  <dd>
                    민원사항 확인, 사실 확인을 위한 연락·통지, 처리결과 통보
                    등의 목적으로 개인정보를 처리합니다.
                  </dd>
                </div>
                <div class="mb-50">
                  <dt>
                    협회 사업 안내 및 홍보, 유관기관 사업 홍보를 위한 처리
                  </dt>
                  <dd>협회 내에서 수행하는 모든 사업과 유관기관 사업.</dd>
                </div>
                <div class="mb-50">
                  <dt>
                    세미나·포럼, 교육·연수, 교류회, 조사, 기타 사업 등 안내 및
                    후속 사업 안내
                  </dt>
                </div>
                <div>
                  <dt>
                    협회는 사업안내 및 홍보를 위한 정보 이외의 민감정보는
                    수집하지 않습니다.
                  </dt>
                </div>
              </dl>
            </li> */}

            {/* <li>
              <h3>02. 개인정보의 처리 및 보유기간</h3>
              <ul class="list-dash">
                <li class="mb-15">
                  협회는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
                  개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
                  개인정보를 처리·보유합니다.
                </li>
                <li>
                  각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. 협회는
                  법령의 규정과 정보주체의동의에 의해서만 개인정보를
                  수집·보유하며 그 보유기간 및 이용기간은 이용자가 회원가입을
                  신청한 시점부터 해지하겠다는 의사 표시를 하여 해지가 처리된
                  시점까지로 합니다. 사업참가자가 사업중지 및 정보 수신 거부 시
                  개인정보를 파기합니다. 홈페이지 회원가입으로 수집된 개인정보의
                  경우 회원탈퇴 시를 개인정보 파기 사유가 발생한 경우로 판단하여
                  파기합니다.
                </li>
              </ul>
            </li>

            <li>
              <h3>03. 개인정보의 제3자 제공</h3>
              <p>협회는 개인정보를 제3자에게 제공하지 않습니다.</p>
            </li>

            <li>
              <h3>04. 정보주체의 권리·의무 및 행사방법</h3>
              <ol class="list-order">
                <li class="mb-40">
                  <h4 class="mb-50">
                    정보주체는 협회에 대해 언제든지 다음 각 호의 개인정보 보호
                    관련 권리를 행사할 수 있습니다.
                  </h4>
                  <ul class="list-disc fz-18">
                    <li>개인정보 열람요구</li>
                    <li>오류 등이 있을 경우 정정 요구</li>
                    <li>삭제 요구</li>
                    <li>처리정지 요구</li>
                  </ul>
                </li>
                <li class="mb-40">
                  <h4>
                    제1항에 따른 권리 행사는 협회에 대해 서면, 전화, 전자우편
                    등을 통하여 하실 수 있으며 협회는 이에 대해 지체 없이
                    조치하겠습니다.
                  </h4>
                </li>
                <li class="mb-40">
                  <h4>
                    정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                    경우에는 협회는 정정 또는 삭제를 완료할 때까지 당해
                    개인정보를 이용하거나 제공하지 않습니다.
                  </h4>
                </li>
                <li class="mb-40">
                  <h4>
                    제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
                    받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
                    개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
                    제출하셔야 합니다.
                  </h4>
                </li>
                <li>
                  <h4>
                    정보주체는 개인정보 보호법 등 관계법령을 위반하여 협회가
                    처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
                    침해하여서는 아니됩니다.
                  </h4>
                </li>
              </ol>
            </li> */}

            {/* <li>
              <h3>05. 처리하는 개인정보 항목</h3>
              <p class="mb-50">
                협회는 다음의 개인정보 항목을 처리하고 있습니다.
              </p>
              <dl class="fz-18">
                <h5>[온라인 회원사 가입 및 관리]</h5>
                <p class="mb-15">
                  필수항목 : 이메일(ID), 이름, 비밀번호, 휴대폰 번호, 전화번호,
                  상호명, 업종, 지역, 사업장주소, 사업자등록번호
                </p>

                <ul class="list-disc">
                  <li class="mb-15">
                    인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로
                    생성되어 수집될 수 있습니다.
                  </li>
                  <li class="mb-15">
                    IP주소, 쿠키, 서비스 이용기록, 방문기록 등
                  </li>
                  <li class="mb-15">
                    협회는 귀하의 정보를 수시로 저장하고 찾아내는
                    ‘쿠키(cookie)’를 운용합니다. 쿠키란 협회의 웹사이트를
                    운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주
                    작은 텍스트 파일 로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
                    협회는 다음과 같은 목적을 위해 쿠키를 사용합니다. 회원과
                    비회원의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과
                    관심분야를 파악하여 서비스 개편 등의 척도로 활용합니다.
                  </li>
                  <li class="mb-15">
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서
                    이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
                    허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면
                    모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를
                    거부하셨을 경우 일부 서비스제공에 어려움이 있을 수 있습니다.
                  </li>
                  <li class="mb-15">
                    쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의
                    경우)은 다음과 같습니다.
                    <p>
                      1. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.
                      <br />
                      2. [개인정보 탭]을 클릭합니다.
                      <br />
                      3. [개인정보취급 수준]을 설정하시면 됩니다.
                    </p>
                  </li>
                  <li>
                    협회는 사업안내 및 홍보를 위한 정보 이외의 민감정보는
                    수집하지 않습니다.
                  </li>
                </ul>
              </dl>
            </li> */}

            {/* <li>
              <h3>06. 개인정보의 파기</h3>
              <ol class="order mb-30">
                <li>
                  협회는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                  불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
                </li>
                <li>
                  정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나
                  처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
                  계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                  데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                </li>
                <li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
              </ol>

              <ul class="fz-18">
                <li class="mb-50 ">
                  <h5 class="mb-15">[파기절차]</h5>
                  <ul class="list-dash">
                    <li>
                      개인정보 : 이용자가 입력한 정보는 목적 달성 후 지체 없이
                      파기합니다.
                      <br />
                    </li>
                    <li>
                      개인정보파일 : 처리 목적 달성, 해당 서비스의 폐지, 사업의
                      종료 등 그 개인정보 파일이 불필요하게 되었을 때에는
                      개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체
                      없이 파기합니다.
                    </li>
                  </ul>
                </li>

                <li>
                  <h5 class="mb-15">[파기방법]</h5>
                  <ul class="list-dash">
                    <li>
                      전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
                      사용합니다.
                    </li>
                    <li>
                      종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다.
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <h3>07. 개인정보 보호책임자</h3>
              <ol class="list-order">
                <li class="mb-30">
                  협회는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
                  처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
                  아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                  <ul class="list-dash fz-18 mt-15">
                    <li>개인정보 관리 책임자 : 오인태 대표</li>
                    <li>
                      개인정보 관리 담당자 : 김은아 사무국장{" "}
                      <a href="mailto:keahkey@gmail.com">keahkey@gmail.com</a>
                    </li>
                  </ul>
                </li>
                <li>
                  이용자는 협회의 서비스(또는 사업)을 이용하면서 발생한 모든
                  개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                  개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 협회는
                  정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                </li>
              </ol>
            </li>

            <li>
              <h3>08. 개인정보 처리방침의 변경에 관한 사항</h3>
              <p>이 개인정보 처리방침은 2021. 7. 15부터 적용됩니다.</p>
            </li>

            <li>
              <h3>
                09. 동의를 거부할 권리가 있다는 사실과 동의 거부에 따른 불이익
              </h3>
              <p>
                이용자는 협회에서 수집하는 개인정보에 대해 동의를 거부할 권리가
                있으며 동의 거부 시에는 협회 정보제공 서비스가 일부 제한됩니다.
              </p>
            </li> */}

            {/* <li>
              <h3>10. 권익침해 구제방법</h3>
              <p class="mb-15">
                정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제,
                상담 등을 문의하실 수 있습니다.
              </p>

              <ul class="fz-18">
                <li class="mb-30">
                  <ul class="list-dash">
                    <h6 class="mb-15">개인정보분쟁 조정위원회</h6>
                    <li>
                      홈페이지 :{" "}
                      <a target="_blank" href="https://www.kopico.go.kr">
                        https://www.kopico.go.kr
                      </a>
                    </li>
                    <li>전화번호 : (국번없이)118(내선2번)</li>
                  </ul>
                </li>
                <li>
                  <h6 class="mb-15">대검찰청 사이버범죄 수사단</h6>
                  <ul class="list-dash">
                    <li>
                      홈페이지 :{" "}
                      <a target="_blank" href="http://www.spo.go.kr">
                        http://www.spo.go.kr
                      </a>
                    </li>
                    <li>전화번호 : 02-3480-3582</li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Terms02;
