import React from 'react';
import Input from '../../atoms/Input';
import {
  MagnifyingGlassIcon,
  LockClosedIcon,
  InboxIcon,
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

const Login = ({ onLoginSubmit }) => {
  return (
    <main className='block w-full overflow-hidden box-border leading-none'>
      <section className='block box-border mx-auto my-0 text-center p-0 align-baseline'>
        <div className='block box-border w-full p-0 my-0 mx-auto overflow-hidden align-baseline'>
          <div className='flex flex-wrap -mx-[10px] m-0 p-0 align-baseline'>
            <div className='box-border block px-2.5 py-0 w-full m-0'>
              <div className='box-border block py-28 px-0 font-sans m-0 align-baseline'>
                <h2 className='box-border block text-4xl tracking-tighter font-black mb-2 ms-0 me-0 m-0 p-0'>
                  정보수정
                </h2>
              </div>
              <form
                className='box-border block w-1/4 md:min-w-[494px] min-w-[325px] my-0 mx-auto p-0 text-left font-sans text-[#666] align-baseline'
                name='authForm'
                onSubmit={onLoginSubmit}
              >
                <Input type='text' name='email' placeholder='이메일 주소' />
                <Input type='password' name='password' placeholder='비밀번호' />
                <button
                  className='box-border block h-20 w-full bg-[#2c578a] text-white font-sans cursor-pointer
                                border-none p-0 m-0 text-2xl tracking-tight mb-5'
                  type='submit'
                >
                  확인
                </button>
              </form>
              <div className='mb-60'>
                <ul className='box-border flex justify-center items-center list-none m-0 p-0 mb-2 align-baseline text-base'>
                  <li className='box-border inline-block m-0 p-0'>
                    <Link
                      className='box-border after:inline-block after:my-0 after:mx-5 after:w-[1px] after:h-4 after:bg-[#777] text-[#777]'
                      to='/store/findEmail'
                    >
                      <MagnifyingGlassIcon
                        className='box-border inline-block w-[14px] h-[13px] mr-4 m-0 p-0 align-baseline'
                        aria-hidden='true'
                      ></MagnifyingGlassIcon>
                      이메일 주소 찾기
                    </Link>
                  </li>
                  <li className='box-border inline-block m-0 p-0'>
                    <Link
                      className='box-border after:inline-block text-[#777]'
                      to='/store/findPwd'
                    >
                      <LockClosedIcon
                        className='box-border inline-block w-[14px] h-[13px] mr-4 m-0 p-0 align-baseline'
                        aria-hidden='true'
                      ></LockClosedIcon>
                      비밀번호 찾기
                    </Link>
                  </li>
                </ul>
                <ul className='flex justify-center items-center list-none'>
                  <li className='inline-block'>
                    <Link
                      className='box-border text-[#777] m-0 p-0'
                      to='/apply/step/1'
                    >
                      <InboxIcon
                        className='inline-block w-[14px] h-[13px] mr-4 m-0 p-0 align-baseline'
                        aria-hidden='true'
                      ></InboxIcon>
                      선한영향력가게 동행 신청
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
