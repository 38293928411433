import React, { useEffect, useState } from "react";
import axios from "axios";
import ICON1 from "../../assets/images/count_icon_1.png";
import ICON2 from "../../assets/images/count_icon_2.png";
import ICON3 from "../../assets/images/count_icon_3.png";

export default function HomeCount2() {
  const [stats, setStats] = useState([
    { id: 1, name: "매장 회원수", value: "0명", icon: ICON1 },
    { id: 2, name: "후원 아동수", value: "0명", icon: ICON2 },
    { id: 3, name: "후원 금액", value: "0원", icon: ICON3 },
  ]);

  useEffect(() => {
    const API_KEY = "Basic " + process.env.REACT_APP_NAVI_KEY;

    const fetchStats = async () => {
      try {
        // 나눔 수 조회
        const nanumCountResponse = await axios.get(
          process.env.REACT_APP_NAVI_API_URL + "nanum-count",
          {
            headers: {
              Authorization: API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        // 후원아동 수 조회
        const sponsoredUserCountResponse = await axios.get(
          process.env.REACT_APP_NAVI_API_URL + "sponsored-user-count",
          {
            headers: {
              Authorization: API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        // 후원금액 조회
        const donationAmountResponse = await axios.get(
          process.env.REACT_APP_NAVI_API_URL + "donation-amount",
          {
            headers: {
              Authorization: API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        setStats([
          {
            id: 1,
            name: "매장 회원수",
            value: `${nanumCountResponse.data.result}명`,
            icon: ICON1,
          },
          {
            id: 2,
            name: "후원 아동수",
            value: `${sponsoredUserCountResponse.data.result}명`,
            icon: ICON2,
          },
          {
            id: 3,
            name: "후원 금액",
            value: `${donationAmountResponse.data.result}원`,
            icon: ICON3,
          },
        ]);
      } catch (error) {
        console.error("API 호출 에러:", error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="bg-yellow-100 sm:py-12 py-8 rounded-3xl mt-12">
      <div className="mx-auto max-w-full px-4">
        <dl className="grid grid-cols-1 text-center lg:grid-cols-3 gap-4">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-full bg-white w-full justify-between rounded-md p-4"
            >
              <div className="flex items-center gap-2">
                <img src={stat.icon} alt="Icon" className="size-6" />
                <p className="md:text-base text-xs leading-7 text-gray-600 font-semibold">
                  {stat.name}
                </p>
              </div>
              <a className="md:text-3xl text-xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                {stat.value}
              </a>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
