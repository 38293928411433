import {
  setAuthAndMutlpartHeaders,
  setAuthHeaders,
  setMultipartHeaders,
} from './index';

const updateAPI = (API) => ({
  update: (data, token) =>
    API.put('/account/update', data, setAuthHeaders(token)),
  addMenu: (formData, token) =>
    API.post(
      '/account/update/addmenu/',
      formData,
      setAuthAndMutlpartHeaders(token)
    ),
  deleteMenu: (idx, token) =>
    API.delete(`/account/update/delmenu/${idx}`, setAuthHeaders(token)),
  updateMenu: (data, idx, token) =>
    API.put(
      `/account/update/editmenu/${idx}`,
      data,
      setAuthAndMutlpartHeaders(token)
    ),
  addStore: (formData, token) =>
    API.post(
      '/account/update/addstorefile/',
      formData,
      setMultipartHeaders(token)
    ),
  deleteStore: (idx, token) =>
    API.delete(`/account/update/delstorefile/${idx}`, setAuthHeaders(token)),
  addMenuAdmin: (formData, storeIdx) =>
    API.post(
      `/admin/store/update/addmenu/${storeIdx}`,
      formData,
      setMultipartHeaders()
    ),
  updateMenuAdmin: (formData, storeIdx, idx) =>
    API.put(
      `/admin/store/update/editmenu/${storeIdx}/${idx}`,
      formData,
      setMultipartHeaders()
    ),
  deleteMenuAdmin: (storeIdx, idx) =>
    API.delete(
      `/admin/store/update/delmenu/${storeIdx}/${idx}`,
      setMultipartHeaders()
    ),

  addStoreAdmin: (formData, storeIdx) =>
    API.post(
      `/admin/store/update/addstorefile/${storeIdx}`,
      formData,
      setMultipartHeaders()
    ),
  updateStoreAdmin: (formData, storeIdx, idx) =>
    API.put(
      `/admin/store/update/editStorefile/${storeIdx}/${idx}`,
      formData,
      setMultipartHeaders()
    ),
  deleteStoreAdmin: (storeIdx, idx) =>
    API.delete(
      `/admin/store/update/delstorefile/${storeIdx}/${idx}`,
      setMultipartHeaders()
    ),
  withdrawalStore: (data) =>
    API.post('/account/withdrawal', data, setAuthHeaders()),
  withdrawalStoreAdmin: (id) =>
    API.get(`/admin/store/withdrawal/${id}`, setAuthHeaders()),
});

export default updateAPI;
