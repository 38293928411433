const signupAPI = (API) => ({
  signup: (data) => API.post('/account/signup', data),
  signupImgfile: (formData) =>
    API.post('/account/signup/imgfile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
});

export default signupAPI;
