import { PlusIcon } from "@heroicons/react/20/solid";

const items = [
  { id: 1, content: "sidsidd" },

  // More items...
];

export default function Dividers() {
  return (
    <div class="border-solid w-full border-b-2 py-6 items-center border-gray-200" />
  );
}
