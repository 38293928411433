import { useEffect, useRef, useState } from "react";

const storeImg = [
  {
    idx: 12974,
    type: "ciImg",
    order_no: "0",
    ori_name: "dog.jpg",
    real_name: "0b006223975a416eb76c03ee15925bf2.jpg",
    size: 132655,
    reg_dt: "2024-07-04T04:32:49.000+00:00",
    img_url:
      "https://www.xn--o39akkz01az4ip7f4xzwoa.com/file/store/4383/0b006223975a416eb76c03ee15925bf2.jpg",
  },
];

export default function AdminStoreImg({
  storeImg,
  type,
  onAddImg,
  name,
  onDeleteImg,
}) {
  // 요소를 클릭하기 위한 주소
  const fileInputRef = useRef(null);

  // 버튼을 누르면 -> input을 누르도록 유도 : input select file을 다른 버튼으로 하고 싶기 때문
  const handleFileSelect = (e) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const afterFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      // 파일 크기 확인
      if (file.size > maxSize) {
        alert("5MB 이하의 이미지만 업로드 가능합니다.");
        return;
      }

      // 파일 타입 확인
      if (!allowedTypes.includes(file.type)) {
        alert("jpg, jpeg, gif, png 형식의 이미지만 업로드 가능합니다.");
        return;
      }

      // 파일 선택 이벤트 전달
      onAddImg(e);
    }
  };

  return (
    <>
      <button
        className="bg-sky-800 hover:bg-sky-950 text-white py-2 px-4 rounded mb-4"
        onClick={handleFileSelect}
        name={name}
      >
        사진 추가
      </button>
      <input
        type="file"
        name={name}
        ref={fileInputRef}
        onChange={afterFileSelect}
        style={{ display: "none" }}
      />

      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
        {storeImg
          .filter((img) => img.type == name)
          .map((img) => {
            return (
              <div key={img.idx} className="group relative">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75">
                  <img
                    src={img.img_url}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    className="mt-4 bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded"
                    id={img.idx}
                    onClick={onDeleteImg}
                    name={name}
                    data-isNew={img.isNew}
                  >
                    삭제
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
