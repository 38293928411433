const ApplyTitle = ({ title }) => {
  return (
    <div className="flex h-[10rem] justify-center items-center sm:h-[9rem] md:mt-32 md:mb-32">
      <h2 className="flex w-fit h-fit font-bold md:underline-yellow underline-yellow-sm text-xl md:text-5xl">
        {title}
      </h2>
    </div>
  );
};

export default ApplyTitle;
