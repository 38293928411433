const AddressButton = ({ title, w, h, textSize, data, onPostcode }) => {
  return (
    <>
      <a
        className={`flex border-solid border border-sky-950 w-${w} h-${h} gap-3 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer`}
        onClick={onPostcode}
      >
        <p className={`text-${textSize} font-medium`}>{title}</p>
      </a>
    </>
  );
};

export default AddressButton;
