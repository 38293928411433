import { useEffect, useRef, useState } from "react";
import FloatingLabelInput from "./FloatingLabelInput";
import TwInput from "./TwInput";

const menus = [
  {
    menu_Name: "아이스크림",
    default_Price: "10000",
    offer_Price: "100",
    free_Yn: "N",
    img_url:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    menu_Name: "아이스크림",
    default_Price: "10000",
    offer_Price: "100",
    free_Yn: "N",
    img_url:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    menu_Name: "아이스크림",
    default_Price: "10000",
    offer_Price: "100",
    free_Yn: "N",
    img_url:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    menu_Name: "아이스크림",
    default_Price: "10000",
    offer_Price: "100",
    free_Yn: "N",
    img_url:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  // More people...
];

export default function AdminMenu({ menu, onNotice, onChangeMenuImg }) {
  // 요소를 클릭하기 위한 주소
  const fileInputRef = useRef(null);

  // 버튼을 누르면 -> input을 누르도록 유도 : input select file을 다른 버튼으로 하고 싶기 때문
  const handleFileSelect = (e) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <li key={menu.idx} className="w-3/4">
      <img
        src={menu.img_url}
        className="aspect-[14/13] w-full object-cover p-4"
        onClick={handleFileSelect}
      />
      <input
        id={menu.idx}
        name="menu_Img"
        type="file"
        multiple="multiple"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onChangeMenuImg}
        data-isNew={menu.isNew}
      />
      <div className="mt-2">
        <span className="pl-1 text-sm font-medium">품목</span>
        <TwInput
          placeholder={menu.menu_Name}
          className="flex-1 mb-4"
          menu={menu}
          name="menu_Name"
          onNotice={onNotice}
          isString={true}
        />
      </div>

      <div className="mt-2">
        <span className="pl-1 text-sm font-medium">기존가격</span>
        <TwInput
          placeholder={menu.default_Price}
          className="flex-1 mb-4"
          menu={menu}
          name="default_Price"
          onNotice={onNotice}
        />
      </div>
      <div className="mt-2">
        <span className="pl-1 text-sm font-medium">제공가격</span>
        <TwInput
          placeholder={menu.offer_Price}
          className="flex-1 mb-4"
          menu={menu}
          name="offer_Price"
          onNotice={onNotice}
        />
      </div>

      <div className="flex items-center justify-center space-x-2 pt-4">
        <span className="text-sm font-medium">무료제공 </span>
        <input
          type="checkbox"
          name="menu_free_Yn"
          id={menu.idx}
          checked={menu.free_Yn == "Y" ? true : false}
          className="w-4 h-4 border-solid border-gray-500 text-sky-950"
          onChange={onNotice}
        />
      </div>
      <div className="flex justify-center">
        <button
          className="mt-4 bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded"
          id={menu.idx}
          onClick={onNotice}
          name="menu_delete"
          data-isNew={menu.isNew}
        >
          삭제
        </button>
      </div>
    </li>
  );
}
