import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';

import './reset.css';
import './fontello-embedded.css';

import App from './App';

//Google Analytics 적용시키기
//https://velog.io/@aseungbo/0906-Google-Analytics-%EC%97%B0%EB%8F%99#%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8%EC%97%90-ga%EB%A5%BC-%EC%A0%81%EC%9A%A9%ED%95%9C-%EC%9D%B4%EC%9C%A0

const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(gaTrackingId, { debug: true }); // react-ga 초기화 및 debug 사용
ReactGA.pageview(window.location.pathname); // 추적하려는 page 설정

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </BrowserRouter>
);
