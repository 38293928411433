import Faq from './Faq';
import { useState, useEffect } from 'react';
import { CommunityAPI } from '../../../api';

const Index = () => {
  const [faqs, setFaqs] = useState([]);
  const [curPages, setCurPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handlePaginationNumber = (e) => {
    setCurPages(e.target.innerText - 1);
    e.target.className =
      'bg-sky-800 text-white inline-flex justify-center items-center w-12 h-12 hover:cursor-pointer';
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePaginationPrev = (e) => {
    if (curPages > 0) {
      setCurPages(curPages - 1);
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePaginationNext = () => {
    if (curPages < totalPages - 1) {
      setCurPages(curPages + 1);
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      alert('마지막 페이지 입니다.');
    }
  };

  useEffect(() => {
    async function fetchData(curPages, pageSize) {
      const result = await CommunityAPI.getFaqs({ curPages, pageSize });
      setFaqs(result['data']);
      setTotalPages(result['page']['totalPages']);
    }
    fetchData(curPages, pageSize);
  }, [curPages]);

  return (
    <Faq
      faqs={faqs}
      curPages={curPages}
      totalPages={totalPages}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      onPaginationNumber={handlePaginationNumber}
      onPaginationPrev={handlePaginationPrev}
      onPaginationNext={handlePaginationNext}
    />
  );
};

export default Index;
