import React from 'react';
import { Link } from 'react-router-dom';
import ApplyPageHeader from '../../atoms/ApplyPageHeader';
import IntroTab from '../../atoms/IntroTab';
import { Element as ScrollElement } from 'react-scroll';
import banner from '../../../assets/images/intro_banner.png';
import img1 from '../../../assets/images/intro_img_1.png';
import img2 from '../../../assets/images/intro_img_5.png';
import img3 from '../../../assets/images/intro_img_3.png';
import img4 from '../../../assets/images/intro_img_4.png';
import button1 from '../../../assets/images/intro_button_1.png';
import button2 from '../../../assets/images/intro_button_2.png';
import button3 from '../../../assets/images/intro_button_3.png';
import button4 from '../../../assets/images/intro_button_4.png';

const Introduction = ({ onDownload }) => {
  return (
    <>
      <div className="flex flex-col mb-16 md:mb-64 p-8 md:p-12">
        <ApplyPageHeader IMG={banner} />
        <div className="flex-col w-full h-auto md:h-full mb-24">
          <div className="flex flex-col w-auto h-auto md:mx-10 mx-4 mb-12 md:mb-16 mt-8 md:mt-12">
            <h2 className="flex flex-col text-2xl md:text-5xl font-semibold leading-tight">
              선한영향력가게
            </h2>
            <div id="info" className="flex flex-col mt-4 md:mt-8">
              <p className="text-sm md:text-base font-normal leading-relaxed md:leading-loose">
                전국 결식아동을 지원하고 돕는 목적으로 다양한 업종 매장
                점주님들이 모여 만든 비영리 사단법인입니다.{' '}
                <br className="hidden md:inline" />
                지난 2019년 서울시 마포에 위치한 ‘진짜파스타’ 매장에서 시작한 이
                캠페인은 결식아동들을 위한 따듯한 손길이 모여{' '}
                <br className="hidden md:inline" />
                ‘선한영향력’ 이라는 단체로 만들어졌습니다.{' '}
                <br className="hidden md:inline" />
                지역마다 더 많은 매장에서 더 쉽게 후원할 수 있고 꼭 필요한
                아동들이 편하게 방문할 수 있는 환경을 만들기 위해 노력하고
                있습니다.
                <br className="hidden md:inline" />
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-16 md:space-y-40 leading-9 my-10">
            <IntroTab />

            <ScrollElement
              name="의장인사말"
              className="grid grid-cols-1 md:grid-cols-12 gap-4"
            >
              <h2 className="col-span-12 md:col-span-3 text-start text-2xl md:text-3xl font-bold mr-4">
                의장인사말
              </h2>
              <div className="col-span-12 md:col-span-3 h-fit">
                <img src={img1} alt="의장 이미지"></img>
              </div>
              <div className="col-span-12 md:col-span-6 w-full text-lg md:text-xl text-left">
                "안녕하세요. 선한영향력가게 의장 오인태입니다.{' '}
                <br className="hidden md:inline" />
                2019년 첫걸음을 뗀 선한영향력가게는 많은 분들의 참여와 응원으로
                느리지만 최선을 다해 나아가고 있습니다.{' '}
                <br className="hidden md:inline" />
                아이들의 삶의 질 개선과 참여해주시는 사장님들의 불편함을
                최소화하고, <br className="hidden md:inline" />
                모든 이들의 행복과 삶을 위해 나아갈 수 있도록 노력하겠습니다.
                <br className="hidden md:inline" />
                항상 최고를 위해 최선을 다하는 선한영향력가게가 되겠습니다.
                <br className="hidden md:inline" />
                감사합니다."
              </div>
            </ScrollElement>

            <ScrollElement
              name="CI"
              className="grid grid-cols-1 md:grid-cols-12 gap-4"
            >
              <h2 className="col-span-12 md:col-span-3 text-start text-2xl md:text-3xl font-bold mr-4">
                CI
              </h2>
              <div className="col-span-12 md:col-span-3 h-fit justify-center">
                <div className="flex flex-col w-3/4 md:w-1/2 justify-between h-full rounded-lg">
                  <img className="mb-24" src={img2} alt="CI"></img>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 w-full text-lg md:text-xl text-left">
                <div className="flex flex-col">
                  <div className="flex flex-col w-full">
                    <p className="font-medium w-full mb-12">
                      결식아동을 위한 선한영향력가게와 잘 어울리도록 아이의
                      즐거운 표정을 형상화하여
                      <br className="hidden md:inline" />
                      아이들과 선한영향력가게에 참여하는 모든이들의 순수한
                      마음을 상징
                    </p>
                    <div className="flex flex-col">
                      <div className="flex flex-col gap-4 mb-6">
                        <h3 className="font-medium">CI 사용가이드</h3>
                        <p className="font-medium text-gray-500">
                          심벌 및 마크를 올바르게 사용하기 위해서는 사용원칙과
                          지침을 정확히 이해하고 적용하며 사용 및 실제작을
                          진행하기에 앞서 담당부서의 협조를 받으시길 바랍니다.
                        </p>
                      </div>
                      <a
                        className="flex flex-row gap-4 hover:cursor-pointer md:w-full w-28"
                        onClick={onDownload}
                      >
                        <img name="AI 다운로드" src={button1}></img>
                        <img name="png 다운로드" src={button2}></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollElement>

            <ScrollElement
              name="CI_2"
              className="grid grid-cols-1 md:grid-cols-12 gap-4"
            >
              <h2 className="col-span-12 md:col-span-3 text-start text-2xl md:text-3xl font-bold mr-4"></h2>
              <div className="col-span-12 md:col-span-3 h-fit justify-center">
                <div className="flex flex-col w-3/4 md:w-1/2 justify-between h-full rounded-lg">
                  <img src={img3} alt="CI"></img>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 w-full text-lg md:text-xl text-left">
                <div className="flex flex-col gap-24">
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4 mb-6">
                      <h3 className="font-medium">CI 사용가이드</h3>
                      <p className="font-medium text-gray-500">
                        배너 사용 가이드 취지에 해치지 않는 선에서 문구와 매장명
                        등 변경해서 사용 가능합니다.
                      </p>
                    </div>
                    <a
                      className="flex flex-row gap-4 hover:cursor-pointer md:w-full w-28"
                      onClick={onDownload}
                    >
                      <img name="배너 다운로드" src={button3}></img>
                    </a>
                  </div>
                </div>
              </div>
            </ScrollElement>
            <ScrollElement
              name="연혁"
              className="grid grid-cols-1 md:grid-cols-12 gap-4"
            >
              <h2 className="col-span-12 md:col-span-3 text-start text-2xl md:text-3xl font-bold mr-4">
                연혁
              </h2>
              <div className="col-span-12 md:col-span-9 h-fit justify-center border-t-2 border-solid">
                <ul className="grid list-inside gap-y-10 mt-12">
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2024년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>4월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        지정기부금단체 신청
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2024년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        비영리 사단법인 승인
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2023년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>10월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        청주시청 제휴
                        <br />
                        <span className="font-normal">
                          청주시청 제휴 청주소재 선한영향력가게
                          급식카드조회서비스내 지도서비스 및 홍보지원, 매장 확대
                          지원
                        </span>
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2023년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>9월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        결식아동 지원활동 확대를 위해 단체와 창톡이 상호
                        유기적으로 협조
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2023년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>7월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        나눔비타민 (결식아동과 매장 연결 예약서비스 플랫폼 개발)
                        업무협약
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2023년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>6월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        원주시청 제휴
                        <br className="hidden md:inline" />
                        <span className="font-normal">
                          원주소재 선한영향력가게 급식카드 조회 서비스 내
                          지도서비스 및 홍보지원
                        </span>
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2023년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>5월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        경기도주식회사 협업 지원사업시작
                        <br />
                        <span className="font-normal">
                          경기도소재 배달특급 앱 활용 지원 및 홍보 지원 비대면
                          결제 가능 및 배달비 지원
                        </span>
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <h2 className="text-end text-lg md:text-xl font-bold"></h2>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        KT와 업무 제휴
                        <br />
                        <span className="font-normal">
                          선한영향력가게 앱 개발
                        </span>
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2022년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>7월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        8개 지역협의회 지부장 선출
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2022년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>3월 ~ 11월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        IBA재단 지원사업 '사랑의 밥차' 푸드트럭 지원 사업 추진
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2021년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>9월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        홈페이지 개편
                      </div>
                    </section>
                  </li>

                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2021년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>7월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        비영리 공식단체 '선한영향력가게' 설립(의장 오인태)
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2020년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>7월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        전국 600여개 가게 참여로 확대
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-6">
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>2019년</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 text-lg md:text-xl font-bold">
                        <p>7월</p>
                      </div>
                      <div className="col-span-6 md:col-span-4 md:col-start-3 font-bold">
                        홍대 '진짜파스타'(대표 오인태)에서 캠페인성 활동으로
                        시작
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                </ul>
              </div>
            </ScrollElement>

            <ScrollElement
              name="찾아오시는 길"
              className="grid grid-cols-1 md:grid-cols-12 gap-4"
            >
              <h2 className="col-span-12 md:col-span-3 text-start text-2xl md:text-3xl font-bold mr-4">
                찾아오시는 길
              </h2>
              <div className="col-span-12 md:col-span-9 h-fit justify-center">
                <ul className="grid list-inside gap-y-10 mt-12">
                  <li>
                    <section className="grid grid-cols-12 items-start">
                      <h2 className="col-span-3 md:col-span-2 text-base md:text-xl font-bold">
                        주소
                      </h2>
                      <div className="col-span-9 md:col-span-10 text-base md:text-xl font-semibold">
                        (06119) 서울특별시 강남구 강남대로118길 43 3층 (지번:
                        서울특별시 강남구 논현동 181-10 3층)
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-12 items-start">
                      <h2 className="col-span-3 md:col-span-2 text-base md:text-xl font-bold">
                        전화
                      </h2>
                      <div className="col-span-9 md:col-span-10 text-base md:text-xl font-semibold">
                        010-2375-0133
                      </div>
                    </section>
                  </li>
                  <a className="border-b-2 border-solid border-gray-100"></a>
                  <li>
                    <section className="grid grid-cols-12 items-start">
                      <h2 className="col-span-3 md:col-span-2 text-base md:text-xl font-bold">
                        이메일
                      </h2>
                      <div className="col-span-9 md:col-span-10 text-base md:text-xl font-semibold">
                        goodimpact012@daum.net
                      </div>
                    </section>
                  </li>
                </ul>
                <img
                  className="mt-24 h-auto w-full"
                  src={img4}
                  alt="지도 이미지"
                ></img>
              </div>
            </ScrollElement>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
