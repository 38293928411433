import { useEffect, useRef, useState } from 'react';
import Step2 from './Step2';
import { APPLY_FORM } from '../../../../constants/apply';
import { SignupAPI } from '../../../../api';
import { DATA } from '../../../../constants/businesses';
import { MENU } from '../../../../constants/menu';
import { STORE_FILE } from '../../../../constants/storeFile';
import { useNavigate } from 'react-router-dom';
import { SECTORS, SUB_SECTORS } from '../../../../constants/sectors';
import {
  PASS_CONDITION_APPLY,
  PASS_REF,
} from '../../../../constants/passCondition';
import { makeClearValue } from '../../../../util/safe';
import Spinner from '../../../atoms/Spinner';

const Index = () => {
  const navigate = useNavigate();
  const [applyForm, setApplyForm] = useState(APPLY_FORM);
  const [menuID, setMenuID] = useState(1);
  const [data, setData] = useState(DATA);
  const [menuList, setMenuList] = useState([{ ...MENU }]);
  const [storeFileList, setStoreFileList] = useState([...STORE_FILE]);
  const [passCondition, setPassCondition] = useState(PASS_CONDITION_APPLY);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const refs = PASS_REF();
  const observer = useRef(null);

  const scrollToRef = (ref) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ref.current.focus();
          observer.current.disconnect();
        }
      },
      { threshold: 1.0 }
    );

    observer.current.observe(ref.current);
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePass = (key, valid) => {
    setPassCondition((prev) => ({ ...prev, [key]: valid }));
  };

  const isAllTrue = (obj) => {
    return Object.values(obj).every((value) => value === true);
  };

  const findFalseKey = (obj) => {
    return Object.keys(obj).find((key) => obj[key] === false);
  };

  const findSectorIdx = (key, value, idx) => {
    if (key == 'sector') {
      return SECTORS.findIndex((sector) => sector.code === value);
    } else {
      return SUB_SECTORS[idx].findIndex((sector) => sector.value === value);
    }
  };

  useEffect(() => {
    handleData();
  }, [applyForm, menuList, storeFileList]);

  const handleFocus = (e) => {
    e.target.setAttribute('required', '');
  };

  // 사업자정보 데이터
  const handleData = () => {
    setData((prevData) => {
      return {
        ...prevData,
        businesses: [
          {
            b_no: applyForm.reg_No,
            start_dt: applyForm.reg_date,
            p_nm: applyForm.reg_Name,
          },
        ],
      };
    });
  };

  // 전체 폼
  const handleApplyForm = (e) => {
    const { name, value, checked, type } = e.target;
    const clearValue = makeClearValue(value);

    if (
      name != 'type' &&
      name != 'zipcode' &&
      name != 'addr1' &&
      name != 'addr2'
    ) {
      handlePass(name, e.target.validity.valid);
    }
    setApplyForm((prevForm) => {
      // prevForm에서 현재 name 값을 가져옴
      const currentValues = prevForm[name] || [];

      // name이 리스트 값을 나타내는 경우
      if (type === 'checkbox') {
        if (checked) {
          // 체크된 경우, value를 리스트에 추가
          return {
            ...prevForm,
            [name]: [...currentValues, clearValue],
          };
        } else {
          // 체크 해제된 경우, clearValue를 리스트에서 제거
          return {
            ...prevForm,
            [name]: currentValues.filter((item) => item !== clearValue),
          };
        }
      }

      if (name === 'type') {
        const sector = findSectorIdx('sector', value[0]);
        const subSector = findSectorIdx('subSector', value[1], sector);
        return {
          ...prevForm,
          type: value[0],
          type2: value[1],
          sector: sector,
          subSector: subSector,
        };
      }

      // 리스트 값이 아닌 경우 기본 처리
      return {
        ...prevForm,
        [name]: clearValue,
      };
    });
  };

  // 메뉴
  const handleMenuChange = (e) => {
    const { name, value, id, files, checked } = e.target;
    const clearValue = makeClearValue(value);

    setMenuList((prevList) =>
      prevList.map((menu) => {
        if (menu.id == id) {
          if (name === 'menu_Img' && files) {
            return {
              ...menu,
              [name]: files,
              img_url: URL.createObjectURL(files[0]),
            };
          } else if (name == 'free_Yn') {
            return { ...menu, [name]: checked ? 'Y' : 'N' };
          }
          return { ...menu, [name]: clearValue };
        } else {
          return menu;
        }
      })
    );
  };

  const handleAddMenu = () => {
    setMenuID(menuID + 1);
    setMenuList([...menuList, { ...MENU, id: menuID }]);
  };

  const handleDeleteMenu = (e) => {
    const menuID = e.target.dataset.id;
    setMenuList((prevMenus) => prevMenus.filter((menu) => menu.id != menuID));
  };

  const handleOnlyNumber = (event) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
    ];
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  // 스토어
  const handleStoreChange = (e) => {
    const { name, files, id, dataset } = e.target;

    setStoreFileList((prevList) =>
      prevList.map((storeFile, index) => {
        if (index == id) {
          const updatedStoreFile = storeFile.map((file, idx) => {
            if (idx == dataset.id) {
              return { ...file, [name]: files };
            }
            return file;
          });

          let hasFileAtZeroIndex = false;
          const tmp = updatedStoreFile.map((file, idx) => {
            if (idx == 0 && file.store_Img) {
              hasFileAtZeroIndex = true;
            }
            if (idx == 2) {
              return hasFileAtZeroIndex;
            }
            return file;
          });

          if (!tmp[0]?.store_Img && tmp[1]?.store_Img) {
            return [
              { ...tmp[1], order_no: 0 },
              { ...tmp[0], order_no: 1, store_Img: null },
              !tmp[2],
            ];
          }
          return tmp;
        }
        return storeFile;
      })
    );
  };

  // 시간
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // 옵션을 설정하여 날짜 형식을 지정합니다.
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    // 날짜 형식을 로컬 시간대에 맞게 변환합니다.
    const formattedDate = date
      .toLocaleString('en-GB', options)
      .replace(',', '');

    // '/'로 구분자를 변경합니다.
    const [day, month, yearAndTime] = formattedDate.split('/');
    const [year, time] = yearAndTime.split(' ');
    return `${year}/${month}/${day} ${time}`;
  };

  // blob 변환
  const fileToBlob = (file) => {
    return new Blob([file], { type: file.type });
  };

  const createFormData = (store_idx) => {
    const formData = new FormData();
    try {
      // menuList 추가, index로 구분 맞음
      menuList.forEach((menu, index) => {
        const {
          default_Price,
          free_Yn,
          img_url,
          menu_Img,
          menu_Name,
          offer_Price,
          reg_dt,
        } = menu;
        if (
          String(default_Price) &&
          free_Yn &&
          img_url &&
          menu_Img &&
          menu_Name &&
          String(offer_Price) &&
          reg_dt
        ) {
          formData.append(`menuList[${index}].default_Price`, default_Price);
          formData.append(`menuList[${index}].free_Yn`, free_Yn);
          formData.append(`menuList[${index}].img_url`, img_url);
          formData.append(
            `menuList[${index}].menu_Img`,
            fileToBlob(menu_Img[0]),
            menu_Img[0].name
          );
          formData.append(`menuList[${index}].menu_Name`, menu_Name);
          formData.append(
            `menuList[${index}].offer_Price`,
            free_Yn === 'Y' ? '0' : offer_Price
          );
          formData.append(`menuList[${index}].reg_dt`, formatDate(reg_dt));
        }
      });
    } catch (error) {
      console.error('Error processing menuList:', error);
    }
    // storeFileList 추가
    try {
      storeFileList.forEach((files, idx) => {
        const pIdx = 2 * idx;
        files.map((file, cIdx) => {
          const idx = pIdx + cIdx;
          const { order_no, store_Img, type } = file;

          if (typeof order_no === 'number' && store_Img && type) {
            formData.append(`storeFileList[${idx}].order_no`, String(order_no));
            formData.append(
              `storeFileList[${idx}].store_Img`,
              fileToBlob(store_Img[0]),
              store_Img[0].name
            );
            formData.append(`storeFileList[${idx}].type`, type);
          }
        });
      });
    } catch (error) {
      console.error('Error processing  storeFileList:', error);
    }
    // id 연결
    formData.append('store_idx', store_idx);

    return formData;
  };

  // 페이지 이동
  const nextTo = () => {
    navigate('/apply/step/3');
    window.scrollTo(0, 0);
  };

  // 제출
  const handleSummit = async () => {
    if (!isAllTrue(passCondition)) {
      const condition = findFalseKey(passCondition);
      // 만족 안된 입력값으로 올라감
      scrollToRef(refs[condition]);

      if (condition == 'businesses') {
        alert('사업자 등록 번호를 인증해주세요.');
      } else if (condition == 'owner_Name') {
        alert(`이름을 확인해주세요`);
      } else if (condition == 'owner_Email') {
        alert('이메일을 확인해주세요.');
      } else if (condition == 'owner_Hp') {
        alert('휴대폰 번호를 확인해주세요.');
      } else if (condition == 'owner_Pwd' || condition == 'owner_Pwd_') {
        alert('비밀번호를 확인해주세요.');
      } else if (condition == 'name') {
        alert('상호명을 확인해주세요.');
      } else if (condition == 'sector') {
        alert('업종을 선택해주세요.');
      } else if (condition == 'tel') {
        alert('매장 번호를 확인해주세요.');
      } else if (condition == 'address') {
        alert('주소를 입력해주세요.');
      }
      return;
    }
    if (applyForm.owner_Pwd_ != applyForm.owner_Pwd) {
      scrollToRef(refs['owner_Pwd_']);
      alert('비밀번호 일치 여부를 확인해주세요.');
      return;
    }

    // 메뉴 항목에 대한 밸리데이션
    for (const menu of menuList) {
      if (
        menu.menu_Img &&
        (!menu.menu_Name || !menu.default_Price || !menu.offer_Price)
      ) {
        alert(
          '제공품목의 경우 메뉴 항목에는 "품목 이름", "기존 가격", "제공 가격"을 모두 입력해야 합니다.'
        );
        return; // 유효성 검사 실패 시 제출 중단
      }
    }

    setIsSubmitting(true);
    try {
      // 1. store signup
      const temp1 = applyForm.close_Info.join(', ').trim();
      const temp2 = applyForm.target2_Code.join(', ').trim();
      const { owner_Pwd_, ...summitData } = applyForm;
      const data = {
        ...summitData,
        close_Info: temp1,
        target2_Code: temp2,
        sns_Url1: applyForm.sns_Type1 == 'SNS' ? null : applyForm.sns_Url1,
        sns_Url2: applyForm.sns_Type2 == 'SNS' ? null : applyForm.sns_Url2,
      };
      const signupRes = await SignupAPI.signup(data);
      const storeIdx = signupRes.data.idx;

      // 2. store img
      try {
        const form = createFormData(storeIdx);
        const imgFileRes = await SignupAPI.signupImgfile(form);
      } catch (error) {
        console.log('이미지 업로드 실패: ', error.response);
        // 이미지 업로드 실패를 무시
      }

      alert('동행신청이 완료됐습니다!');
      setIsSubmitting(true);
      nextTo();
    } catch (error) {
      console.log(error);
      console.log(error.response.data.message);
      if (
        error.response.data.message ===
        'could not execute statement; SQL [n/a]; constraint [store.UK_OwnerEmail]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement'
      ) {
        alert('중복된 정보가 있습니다. (이메일, 핸드폰번호...)');
        setIsSubmitting(false);
        return;
      } else {
        alert(`필수 입력값을 확인해 주세요.`);
      }
    }
  };

  return (
    <>
      {isSubmitting ? (
        <Spinner /> //
      ) : (
        <Step2
          {...{
            handleFocus,
            handleApplyForm,
            data,
            menuID,
            menuList,
            storeFileList,
            applyForm,
            refs,
          }}
          onMenuChange={handleMenuChange}
          onAddMenu={handleAddMenu}
          onDeleteMenu={handleDeleteMenu}
          onSummit={handleSummit}
          onStoreChange={handleStoreChange}
          onPass={handlePass}
          onOnlyNumber={handleOnlyNumber}
        />
      )}
    </>
  );
};

export default Index;
