import { saveAs } from "file-saver";
import pdf from "../../../assets/2024년도_결식아동 _급식_업무_표준매뉴얼.pdf";
import Apply from "./Apply";

const Index = () => {
  const handleDownload = () => {
    saveAs(pdf, "2024년도_결식아동_급식_업무_표준매뉴얼.pdf");
  };
  return <Apply onDownload={handleDownload} />;
};

export default Index;
