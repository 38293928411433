import React from 'react';
import Input from '../../atoms/Input';

const FindPassword = ({
  secretModal,
  setSecretModal,
  onFindPasswordSubmit,
  onResetPasswordClick,
  newPassword,
  setNewPassword,
  checkNewPassword,
  setCheckNewPassword,
}) => {
  return (
    <main className='block w-full overflow-hidden box-border leading-none'>
      <section className='block box-border mx-auto my-0 text-center p-0 align-baseline'>
        <div className='block box-border w-full p-0 my-0 mx-auto overflow-hidden align-baseline'>
          <div className='flex flex-wrap -mx-[10px] m-0 p-0 align-baseline'>
            <div className='box-border block px-2.5 py-0 w-full m-0'>
              <div className='box-border block py-28 px-0 font-sans m-0 align-baseline'>
                <h2 className='box-border block text-4xl tracking-tighter font-black mb-6 ms-0 me-0 m-0 p-0'>
                  비밀번호 찾기
                </h2>
                <p className='box-border block text-lg tracking-tighter font-medium mb-2 ms-0 me-0 m-0 p-0'>
                  아래의 정보를 입력하시면 비밀번호를 재설정 할 수 있습니다.
                </p>
              </div>
              <form
                className='box-border block w-1/4 md:min-w-[494px] min-w-[325px] my-0 mx-auto p-0 text-left font-sans text-[#666] align-baseline'
                name='authForm'
                onSubmit={onFindPasswordSubmit}
              >
                <Input type='text' name='name' placeholder='이름' />
                <Input
                  type='text'
                  name='phone'
                  placeholder='가입 휴대폰 번호'
                />
                <Input type='text' name='email' placeholder='이메일 입력' />
                <button
                  className='box-border block h-20 w-full bg-[#2c578a] text-white font-sans cursor-pointer
                                border-none p-0 m-0 text-2xl tracking-tight mb-5'
                  type='submit'
                >
                  확인
                </button>
              </form>
              {secretModal && (
                <div className='fixed flex top-0 bottom-0 left-0 right-0 justify-center content-center text-center items-center'>
                  <div
                    className='fixed bg-gray-800 opacity-80 w-full h-full'
                    onClick={() => setSecretModal(false)}
                  ></div>
                  <div className='xl:w-1/2 bg-white flex flex-col justify-center content-center items-center w-full h-3/4 border border-solid border-sky-800 z-40'>
                    <p className='text-lg font-semibold mb-6'>
                      새로운 비밀번호를 입력해주세요
                    </p>
                    <div className='flex flex-col mt-4 mx-4'>
                      <div>
                        <label className='flex text-left mb-2 font-semibold'>
                          새 비밀번호
                        </label>
                        <input
                          className='box-border mb-5 text-2xl tracking-tight font-medium pl-[1.8rem] block w-full h-16 border-1 border border-solid border-sky-800'
                          type='password'
                          name='newPassword'
                          onChange={(e) => setNewPassword(e.target.value)}
                          autoComplete='off'
                          placeholder='새 비밀번호'
                        />
                      </div>
                      <div>
                        <label className='flex text-left mb-2 font-semibold'>
                          새 비밀번호 확인
                        </label>
                        <input
                          className='box-border mb-5 text-2xl tracking-tight font-medium pl-[1.8rem] block w-full h-16 border-1 border border-solid border-sky-800'
                          type='password'
                          name='checkNewPassword'
                          onChange={(e) => setCheckNewPassword(e.target.value)}
                          autoComplete='off'
                          placeholder='새 비밀번호 확인'
                        />
                      </div>

                      <div>
                        <div
                          className='float-right text-center content-center bg-sky-800 text-white w-24 h-12'
                          onClick={onResetPasswordClick}
                        >
                          확인
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='mb-60'></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FindPassword;
