import React, { useEffect, useState } from 'react';
import PopupForm from './PopupForm';
import { AdminPostAPI } from '../../../../api';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();
  const [popupData, setPopupData] = useState({
    noticeIdx: '',
    title: '',
    contents: '',
    viewYn: 'Y',
    oriName: '',
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await AdminPostAPI.getPopupNotices();
        setPopupData({
          noticeIdx: data.noticeIdx,
          title: data.title,
          contents: data.contents,
          viewYn: data.viewYn,
          oriName: data.oriName,
          realName: data.realName,
        });
      } catch (error) {
        console.log('어드민 팝업 에러', error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPopupData({
      ...popupData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']; // 허용된 이미지 MIME 타입

    const file = e.target.files[0];

    // 파일 유형 체크
    if (!allowedTypes.includes(file.type)) {
      alert('이미지 파일만 업로드할 수 있습니다. (jpg, png, gif)');
      return; // 허용되지 않은 파일 유형일 경우 업로드 중단
    }

    // 파일 크기 체크
    if (file.size > MAX_FILE_SIZE) {
      alert('파일 크기는 5MB를 초과할 수 없습니다.');
      return; // 파일 크기가 5MB를 초과하면 업로드 중단
    }

    setPopupData({
      ...popupData,
      file: file, // 유효한 파일일 경우에만 파일 상태를 업데이트
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('title', popupData.title);
    formData.append('contents', popupData.contents); // content에서 변경
    formData.append('viewYn', popupData.viewYn);
    formData.append('noticeIdx', popupData.noticeIdx); // notice_idx에서 변경

    if (popupData.file) {
      formData.append('file', popupData.file); // image에서 file로 변경
    }
    const data2 = Object.fromEntries(formData.entries());

    try {
      const result = await AdminPostAPI.postPopupNotice({ data: formData });
      alert('팝업 공지사항이 성공적으로 생성되었습니다.');
      navigate(0);
    } catch (error) {
      alert('팝업 공지사항 생성에 실패했습니다.');
    }
  };

  return (
    <PopupForm
      popupData={popupData}
      setPopupData={setPopupData}
      handleChange={handleChange}
      handleFileChange={handleFileChange}
      handleSubmit={handleSubmit}
    />
  );
};

export default Index;
