export const MENU = {
  idx: null,
  id: 0,
  default_Price: null,
  free_Yn: "N",
  img_url: null,
  menu_Img: null,
  menu_Name: null,
  offer_Price: null,
  reg_dt: new Date().toISOString(),
};
