import React, { useEffect, useState } from 'react';
import ApplyInputTitle from './ApplyInputTitle';
import ApplyInput from '../blocks/ApplyInput/ApplyInput';
import ApplyInputContent from './ApplyInputContent';

function Password({ handleApplyForm, handleFocus, applyForm, refs }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isMatch, setIsMatch] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsMatch(confirmPassword === e.target.value);
    handleApplyForm(e);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setIsMatch(password === e.target.value);
  };

  const handleChage = (e) => {
    handleConfirmPasswordChange(e);
    handleApplyForm(e);
  };

  return (
    <>
      <ApplyInput id={'비밀번호'}>
        <ApplyInputTitle title={`비밀번호`} redStar={true} />
        <ApplyInputContent>
          <div className="flex flex-col md:w-2/3 w-5/6 md:mt-12 mt-0">
            <label className="flex flex-col">
              <input
                name="owner_Pwd_"
                type="password"
                className="peer w-full md:text-base text-xs"
                pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{10,16}$"
                placeholder="비밀번호를 입력하세요."
                onChange={handlePasswordChange}
                onFocus={handleFocus}
                ref={refs.owner_Pwd_}
              />
              <a
                className="flex w-full border-solid border-b
                   border-gray-300"
              ></a>
              <div className="flex flex-col md:text-base text-2xs mt-1">
                <p>※ 영문 대소문자/숫자/특수문자 조합, 10자-16자</p>
              </div>
              <p className="mt-2 invisible peer-invalid:visible text-pink-600 md:text-sm text-2xs">
                입력 양식이 맞지 않습니다.
              </p>
            </label>
          </div>
        </ApplyInputContent>
      </ApplyInput>

      <ApplyInput id={'비밀번호 확인'}>
        <ApplyInputTitle title={`비밀번호 확인`} redStar={true} />
        <ApplyInputContent>
          <div className="flex flex-col md:w-2/3 w-5/6">
            <label className="flex flex-col">
              <div
                className="flex w-full border-solid border-b
                   border-gray-300"
              >
                <input
                  name="owner_Pwd"
                  type="password"
                  className="peer w-full md:text-base text-xs"
                  pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{10,16}$"
                  placeholder="비밀번호를 다시 입력해주세요."
                  onChange={handleChage}
                  onFocus={handleFocus}
                  value={applyForm.owner_Pwd}
                  ref={refs.owner_Pwd}
                />
              </div>
              {!isMatch && (
                <p className="text-pink-600 md:text-sm text-2xs">
                  비밀번호가 일치하지 않습니다.
                </p>
              )}
            </label>
          </div>
        </ApplyInputContent>
      </ApplyInput>
    </>
  );
}

export default Password;
