import React, { useRef, useState } from 'react';
import Th from '../../../atoms/Th';
import Td from '../../../atoms/Td';
import SelectSimpleCustom from '../../../atoms/SelectSimpleCustom';
import { SECTORS, SUB_SECTORS } from '../../../../constants/sectors';
import HoverButton from '../../../atoms/HoverButton';
import RatioSimpleInlineList from '../../../atoms/RatioSimpleInlineList';
import { SNS } from '../../../../constants/sns';
import AdminMenu from '../../../atoms/AdminMenu';
import AdminStoreImg from '../../../atoms/AdminStoreImg';
import SelectSNS from '../../../atoms/SelectSNS';
import { BusinessesAPI } from '../../../../api';
import { DATA } from '../../../../constants/businesses';
import SearchPostcodeAdmin from '../../../atoms/SearchPostcodeAdmin';
import Button from '../../../atoms/Button';
import PasswordChangeModal from '../../../atoms/PasswordChangeModal';

const days = ['월', '화', '수', '목', '금', '토', '일', '휴무일 상시변경'];

const StoreForm = ({
  data,
  onNotice,
  update,
  onChangeSector,
  onAddMenu,
  onAddImg,
  onDeleteImg,
  onSubmit,
  onKeyDown,
  onChangeMenuImg,
  onDeleteStore,
  onPasswordChange,
  onSubmitPasswordChange,
  onCloseModal,
  moveToMain,
  passwordModal,
}) => {
  const handleBusinessesNum = async (data) => {
    try {
      const summitData = {
        ...DATA,
        businesses: [
          { b_no: data.reg_No, p_nm: data.p_nm, start_dt: data.start_dt },
        ],
      };
      const response = await BusinessesAPI.createPost(summitData);
      const isVaild = response.data.data[0].valid;
      if (isVaild === '01') {
        alert('인증되었습니다.');
      } else {
        alert('사업자등록정보를 확인해 주세요.');
      }
    } catch (error) {
      console.log(error);
      alert('오류가 발생했습니다.');
    }
  };

  return (
    <>
      <table className='table-auto w-full border-collapse border-t-2 border-solid'>
        <colgroup>
          <col className='w-[20%]' />
          <col className='w-[30%]' />
          <col className='w-[20%]' />
          <col className='w-[30%]' />
        </colgroup>
        <tbody>
          {/* 기본(회원) 정보 */}
          <tr>
            <Th>이름</Th>
            <Td>{data.owner_Name}</Td>
            <Th>이메일 주소</Th>
            <Td>{data.owner_Email}</Td>
          </tr>
          <tr>
            <Th>
              휴대폰 번호<span className='text-red-400'> *</span>
            </Th>
            <Td>
              <PhonInput
                placeholder={data.owner_Hp}
                onNotice={onNotice}
                onKeyDown={onKeyDown}
                name={'owner_Hp'}
                data={data}
              />
            </Td>
            <Th>비밀번호</Th>
            <Td>
              <button
                className='bg-sky-800 hover:bg-sky-950 text-white py-2 px-4 rounded'
                onClick={onPasswordChange}
              >
                비밀번호 초기화
              </button>
              <PasswordChangeModal
                isOpen={passwordModal}
                onClose={onCloseModal}
                onSubmit={onSubmitPasswordChange}
              />
            </Td>
          </tr>
          <tr>
            <Th>회원구분</Th>
            <Td>
              {data.regularYn && (
                <RatioSimpleInlineList
                  name='regularYn'
                  check={data.regularYn}
                  data={[
                    { id: 'regularYn_Y', title: '정회원' },
                    { id: 'regularYn_N', title: '준회원' },
                  ]}
                  onNotice={onNotice}
                />
              )}
            </Td>
            <Th>입금 확인</Th>
            <Td>
              {data.creditYn && (
                <RatioSimpleInlineList
                  name='creditYn'
                  check={data.creditYn}
                  data={[
                    { id: 'creditYn_Y', title: '입금' },
                    { id: 'creditYn_N', title: '미입금' },
                  ]}
                  onNotice={onNotice}
                />
              )}
            </Td>
          </tr>
          <tr>
            <Th>스티커 발송</Th>
            <Td>
              {data.stickerYn && (
                <RatioSimpleInlineList
                  name='stickerYn'
                  check={data.stickerYn}
                  data={[
                    { id: 'sticker_Y', title: '예' },
                    { id: 'sticker_N', title: '아니오' },
                  ]}
                  onNotice={onNotice}
                />
              )}
            </Td>
            <Th>키트 발송</Th>
            <Td>
              {data.kitYn && (
                <RatioSimpleInlineList
                  name='kitYn'
                  check={data.kitYn}
                  data={[
                    { id: 'kit_Y', title: '예' },
                    { id: 'kit_N', title: '아니오' },
                  ]}
                  onNotice={onNotice}
                />
              )}
            </Td>
          </tr>
          <tr>
            <Th class='tbl_tt_s'>
              사업자 등록번호 <span className='text-red-400'> *</span>
            </Th>
            <Td colSpan={4}>
              <div className='flex space-x-3'>
                <div className='flex w-1/4'>
                  <TwInput
                    placeholder={'사업자번호'}
                    value={data.reg_No}
                    onNotice={onNotice}
                    name={'reg_No'}
                  />
                </div>
                <div className='flex w-1/4'>
                  <TwInput
                    placeholder={'가입연월일'}
                    onNotice={onNotice}
                    name={'start_dt'}
                  />
                </div>
                <div className='flex w-1/4'>
                  <TwInput
                    placeholder={'대표자 성명'}
                    onNotice={onNotice}
                    name={'p_nm'}
                  />
                </div>
                <button
                  className='bg-sky-800 hover:bg-sky-950 text-white py-2 px-4 rounded'
                  onClick={() => handleBusinessesNum(data)}
                >
                  인증
                </button>
              </div>
            </Td>
          </tr>
          {/* 가게정보 */}
          <tr>
            <Td colSpan={4}>
              <span className='font-semibold'>가게정보</span>
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>
              상호명 <span className='text-red-400'> *</span>
            </Th>
            <Td colSpan={1}>
              <TwInput
                placeholder={data.name}
                name={'name'}
                onNotice={onNotice}
              />
            </Td>
          </tr>
          <tr>
            <Th>
              업종(대분류) <span className='text-red-400'> *</span>
            </Th>
            <Td colSpan={1}>
              {data.sector != null && (
                <SelectSimpleCustom
                  options={SECTORS}
                  onChangeSector={onChangeSector}
                  value={data.sector}
                  isSub={false}
                  onNotice={onNotice}
                />
              )}
            </Td>
            <Th>
              업종(중분류) <span className='text-red-400'> *</span>
            </Th>
            <Td colSpan={1}>
              {data.subSector != null && (
                <SelectSimpleCustom
                  options={SUB_SECTORS[data.sector]}
                  onChangeSector={onChangeSector}
                  value={data.subSector}
                  onNotice={onNotice}
                  isSub={true}
                />
              )}
            </Td>
          </tr>
          <tr>
            <Th>
              매장번호 <span className='text-red-400'> *</span>
            </Th>
            <Td>
              <TwInput
                placeholder={data.tel}
                name={'tel'}
                onNotice={onNotice}
              />
            </Td>
            <Th>
              매장번호 노출여부 <span className='text-red-400'> *</span>
            </Th>
            <Td>
              {data.tel_Yn && (
                <RatioSimpleInlineList
                  data={[
                    { id: 'Y', title: '예' },
                    { id: 'N', title: '아니오' },
                  ]}
                  name={'tel_Yn'}
                  check={data.tel_Yn}
                  onNotice={onNotice}
                />
              )}
            </Td>
          </tr>
          <tr>
            <Th class='tbl_tt_s'>주소</Th>
            <Td colSpan={4}>
              <SearchPostcodeAdmin
                data={data}
                onNotice={onNotice}
              ></SearchPostcodeAdmin>
            </Td>
          </tr>
          <tr>
            <Th rowSpan={1}>가게 SNS</Th>
            <Td colSpan={4}>
              <div className='flex space-x-4'>
                <div className='flex w-5/12'>
                  <div className='w-40'>
                    {data.sns1 !== null && (
                      <SelectSNS
                        options={SNS}
                        idx={data.sns1}
                        onNotice={onNotice}
                        name={'sns_Type1'}
                      />
                    )}
                  </div>
                  <TwInput
                    placeholder={data.sns_Url1}
                    name={'sns_Url1'}
                    onNotice={onNotice}
                  />
                </div>
                <div className='flex w-5/12'>
                  <div className='w-40'>
                    {data.sns2 !== null && (
                      <SelectSNS
                        options={SNS}
                        idx={data.sns2}
                        onNotice={onNotice}
                        name={'sns_Type2'}
                      />
                    )}
                  </div>
                  <TwInput
                    placeholder={data.sns_Url2}
                    name={'sns_Url2'}
                    onNotice={onNotice}
                  />
                </div>
              </div>
            </Td>
          </tr>
          {/* 추가정보 */}
          <tr>
            <Td colSpan={4}>
              <span className='font-semibold'>추가정보</span>{' '}
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>영업시간</Th>
            <Td colSpan={1}>
              <div className='flex'>
                <input
                  id='open_Hour'
                  name='open_Hour'
                  type='time'
                  className='w-full md:text-sm'
                  onChange={onNotice}
                  value={data.open_Hour}
                />
                <p className='text-2xl'>~</p>
                <input
                  id='close_Hour'
                  name='close_Hour'
                  type='time'
                  className='w-full md:text-sm'
                  onChange={onNotice}
                  value={data.close_Hour}
                />
              </div>
            </Td>
            <Th>브레이크 타임</Th>
            <Td>
              <div className='flex'>
                <input
                  id='break_Start_Hour'
                  name='break_Start_Hour'
                  type='time'
                  className='w-full md:text-sm'
                  onChange={onNotice}
                  value={data.break_Start_Hour}
                />
                <p className='text-2xl'>~</p>
                <input
                  id='break_End_Hour'
                  name='break_End_Hour'
                  type='time'
                  className='w-full md:text-sm'
                  onChange={onNotice}
                  value={data.break_End_Hour}
                />
              </div>
            </Td>
          </tr>
          <tr>
            <Th>휴무일</Th>
            <Td colSpan={4}>
              <div className='flex flex-wrap gap-8' onChange={onNotice}>
                {data.close_Info &&
                  days.map((day, index) => (
                    <label key={index} className='flex items-center gap-1'>
                      <input
                        name='close_Info'
                        value={day}
                        type='checkbox'
                        checked={data.close_Info.includes(day)}
                        className='w-4 h-4 border-solid border-gray-500 text-sky-950'
                      />
                      {day}
                    </label>
                  ))}
              </div>
            </Td>
          </tr>

          <tr>
            <Th>제공품목</Th>

            <Td colSpan={4}>
              <button
                className='bg-sky-800 hover:bg-sky-950 text-white py-2 px-4 rounded mb-4'
                onClick={onAddMenu}
              >
                추가
              </button>

              <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <ul
                  role='list'
                  className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4'
                >
                  {data.menu &&
                    data.menu.map((item) => (
                      <AdminMenu
                        menu={item}
                        onNotice={onNotice}
                        onChangeMenuImg={onChangeMenuImg}
                      />
                    ))}
                </ul>
              </div>
            </Td>
          </tr>
          <tr>
            <Th>제공대상1</Th>
            <Td colSpan={4}>
              <div className='flex'>
                {data.target1_Code && (
                  <RatioSimpleInlineList
                    name={'target1_Code'}
                    check={data.target1_Code}
                    data={[
                      { id: '01', title: '아이 본인만' },
                      { id: '02', title: '동반 1인' },
                      { id: '03', title: '동반 2인' },
                      { id: '99', title: '기타' },
                    ]}
                    onNotice={onNotice}
                  />
                )}
                <div className='flex w-32 pl-4'>
                  <TwInput
                    placeholder={
                      data.target1_Code == '99' ? data.target1_Etc : ''
                    }
                    name={'target1_Etc'}
                    onNotice={onNotice}
                  />
                </div>
              </div>
            </Td>
          </tr>
          <tr>
            <Th>제공대상2</Th>
            <Td colSpan={4}>
              <div className='flex'>
                <div className='flex flex-wrap gap-8' onChange={onNotice}>
                  {data.target2_Code &&
                    [
                      { code: '01', title: '결식아동' },
                      { code: '02', title: '소방관' },
                      { code: '99', title: '기타' },
                    ].map((el, index) => (
                      <label key={index} className='flex items-center gap-1'>
                        <input
                          name={'target2_Code'}
                          type='checkbox'
                          value={el.code}
                          checked={data.target2_Code.includes(el.code)}
                          className='w-4 h-4 border-solid border-gray-500 text-sky-950'
                        />
                        {el.title}
                      </label>
                    ))}
                </div>
                <div className='flex w-44 pl-4'>
                  <TwInput
                    placeholder={data.target2_Etc}
                    name={'target2_Etc'}
                    onNotice={onNotice}
                  />
                </div>
              </div>
            </Td>
          </tr>
          {/* 첨부이미지 */}
          <tr>
            <Td colSpan={4}>
              <span className='font-semibold'>첨부이미지</span>
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>상호명(CI)</Th>
            <Td colSpan={4}>
              {data.storeFile && (
                <AdminStoreImg
                  storeImg={data.storeFile}
                  type='ciImg'
                  onAddImg={onAddImg}
                  name='ciImg'
                  onDeleteImg={onDeleteImg}
                />
              )}
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>가게전면</Th>
            <Td colSpan={4}>
              {data.storeFile && (
                <AdminStoreImg
                  storeImg={data.storeFile}
                  type='outImg'
                  onAddImg={onAddImg}
                  name='outImg'
                  onDeleteImg={onDeleteImg}
                />
              )}
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>가게내부</Th>
            <Td colSpan={4}>
              {data.storeFile && (
                <AdminStoreImg
                  storeImg={data.storeFile}
                  type='inImg'
                  onAddImg={onAddImg}
                  name='inImg'
                  onDeleteImg={onDeleteImg}
                />
              )}
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>메뉴판</Th>
            <Td colSpan={4}>
              {data.storeFile && (
                <AdminStoreImg
                  storeImg={data.storeFile}
                  type='menuImg'
                  onAddImg={onAddImg}
                  name='menuImg'
                  onDeleteImg={onDeleteImg}
                />
              )}
            </Td>
          </tr>
          <tr className='border-t-1 border-solid border-slate-200'>
            <Th>대표메뉴</Th>
            <Td colSpan={4}>
              {data.storeFile && (
                <AdminStoreImg
                  storeImg={data.storeFile}
                  type='repImg'
                  onAddImg={onAddImg}
                  name='repImg'
                  onDeleteImg={onDeleteImg}
                />
              )}
            </Td>
          </tr>
        </tbody>
      </table>
      <div className='flex justify-around py-16 w-full'>
        <div className='flex flex-1/3 w-full'></div>
        <div className='flex flex-1/3 space-x-2 w-full justify-center'>
          <Button
            title={'저장'}
            className={'px-12 py-2 rounded-none'}
            onClick={onSubmit}
          />
          <Button
            title={'취소'}
            className={'px-12 py-2 rounded-none bg-slate-500'}
            onClick={moveToMain}
          />
        </div>
        <div className='flex space-x-2 flex-1/3 w-full justify-end'>
          <Button
            title={'삭제'}
            className={'px-12 py-2 rounded-none bg-slate-500'}
            onClick={onDeleteStore}
          />
        </div>
      </div>
    </>
  );
};

const TwInput = ({ placeholder, onNotice, name, value }) => {
  return (
    <div className='w-full '>
      <input
        type='text'
        name={name}
        value={value}
        id={name}
        className='w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm '
        placeholder={placeholder}
        onChange={onNotice}
      />
    </div>
  );
};

const PhonInput = ({ placeholder, onNotice, name, onKeyDown, data }) => {
  return (
    <div className='w-full '>
      <input
        type='email'
        name={name}
        id={name}
        className='w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm '
        placeholder={placeholder}
        value={data.owner_Hp}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default StoreForm;
