import React, { useState } from 'react';
import Admin from './Admin';
import { AdminAuthAPI } from '../../../api';
import { adminUserState } from '../../../store/stateAtoms';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    userId: '',
    passwd: '',
  });

  const setAdminUser = useSetRecoilState(adminUserState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!form.userId || !form.passwd) {
      alert('빈칸 없이 입력해주세요.');
      return;
    }

    try {
      const result = await AdminAuthAPI.login({ data: form });

      if (result.status === 200) {
        setAdminUser(result.data);
        navigate('/admin/storeList');
      } else if (result.status === 403) {
        alert('로그인이 만료됐습니다.');
        navigate('/admin');
      } else {
        alert('아이디 혹은 비밀번호가 틀렸습니다.');
      }
    } catch (error) {
      alert('오류가 발생했습니다.');
    }
  };

  return <Admin {...{ handleLogin }} {...{ handleChange }} form={form} />;
};

export default Index;
