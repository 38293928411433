import { EMAIL_ITEMS } from "../../../../constants/emailItems";
import ApplyTitle from "../../../atoms/ApplyTitle";
import PolicyContent from "../../../atoms/PolicyContent";

const Email = ({}) => {
  return (
    <>
      <ApplyTitle title={"이메일무단수집거부"} />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        {EMAIL_ITEMS.map((item) => {
          return (
            <>
              <PolicyContent item={item} />
            </>
          );
        })}
      </div>
    </>
  );
};

export default Email;
