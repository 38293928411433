import React, { useEffect, useState } from 'react';
import StoreForm from './StoreForm';
import { StoreAPI, UpdateAPI } from '../../../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { SECTORS, SUB_SECTORS } from '../../../../constants/sectors';
import { SNS } from '../../../../constants/sns';
import { MENU } from '../../../../constants/menu';
import { STORE_FILE_INFO } from '../../../../constants/storeFile';
import { makeClearValue } from '../../../../util/safe';

import Skeleton from '../../../atoms/Skeleton';
import Spinner from '../../../atoms/Spinner';

const Index = () => {
  const [notice, setNotice] = useState({});
  const [updateNotice, setUpdateNotice] = useState({});
  const [menuID, setMenuID] = useState(0);
  const [storeID, setStoreID] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [ownerHp, setOwnerHp] = useState('');
  // 0: add, 1: update, 2: delete
  const [updatedMenu, setUpdatedMenu] = useState([[], [], []]);
  const [updatedStoreFile, setUpdatedStoreFile] = useState([[], [], []]);
  const [passwordModal, setPasswordModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const idx = queryParams.get('idx');

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (idx) {
      setIsLoading(true); // 데이터 로드 시작
      const fetchData = async () => {
        try {
          const result = await StoreAPI.getStore({ id: idx }); // API 호출

          // 추가 데이터
          result.sector = SECTORS.findIndex(
            (sector) => sector.code == result.type
          );
          result.subSector = SUB_SECTORS[result.sector].findIndex(
            (sector) => sector.value == result.type2
          );
          result.sns_Type1 =
            result.sns_Type1 === null ? 'SNS' : result.sns_Type1;
          result.sns_Type2 =
            result.sns_Type2 === null ? 'SNS' : result.sns_Type2;
          result.sns1 = SNS.findIndex((sns) => sns.value == result.sns_Type1);
          result.sns2 = SNS.findIndex((sns) => sns.value == result.sns_Type2);
          result.sns1 = result.sns1 === -1 ? 0 : result.sns1;
          result.sns2 = result.sns2 === -1 ? 0 : result.sns2;

          result.close_Info = result.close_Info
            .split(', ')
            .map((item) => item.trim());
          setUpdateNotice((prev) => {
            return { ...prev, close_Info: result.close_Info };
          });
          result.target2_Code = result.target2_Code
            .split(', ')
            .map((item) => item.trim());
          result.storeFile = result.storeFile.map((item, id) => ({
            ...item,
            id: id,
          }));
          if (result.storeFile.length) {
            setStoreID(result.storeFile[result.storeFile.length - 1].idx + 1);
          }
          result.menu = result.menu.map((item, id) => ({
            ...item,
            id: id,
          }));
          if (result.menu.length) {
            setMenuID(result.menu[result.menu.length - 1].idx + 1);
          }
          setNotice(result); // 상태에 결과 저장
        } catch (error) {
          console.error('Failed to fetch notice:', error);
        }
        setIsLoading(false); // 데이터 로드 완료
      };
      fetchData();
    } else {
      setIsLoading(false); // idx가 없을 경우 로딩 완료로 설정
    }
  }, [idx]);

  const handleNotice = (e) => {
    const { name, value, type, checked, id, dataset } = e.target;
    const clearValue = makeClearValue(value);

    setNotice((prev) => {
      // prevForm에서 현재 name 값을 가져옴
      const currentValues = prev[name] || [];

      if (name.startsWith('menu')) {
        if (type === 'checkbox') {
          return {
            ...prev,
            menu: prev.menu.map((item) =>
              item.idx == id
                ? { ...item, [name.slice(5)]: checked == true ? 'Y' : 'N' }
                : item
            ),
          };
        }
        if (name.slice(5) === 'delete') {
          // new 면 add 에서 삭제
          removeValueToList(0, id);
          // 아니면 삭제할 리스트에 추가, update 리스트에서 삭제
          if (!dataset.isnew) {
            addValueToList(2, id);
            removeValueToList(1, id);
          }
          return {
            ...prev,
            menu: prev.menu.filter((item) => item.idx != id),
          };
        }
        addValueToList(1, id);
        return {
          ...prev,
          menu: prev.menu.map((item) =>
            item.idx == id ? { ...item, [name.slice(5)]: clearValue } : item
          ),
        };
      }
      // name이 리스트 값을 나타내는 경우
      if (type === 'checkbox') {
        if (checked) {
          // 체크된 경우, clearValue를 리스트에 추가
          return {
            ...prev,
            [name]: [...currentValues, clearValue],
          };
        } else {
          // 체크 해제된 경우, clearValue를 리스트에서 제거
          return {
            ...prev,
            [name]: currentValues.filter((item) => item != clearValue),
          };
        }
      }

      if (name === 'type') {
        const sector = findSectorIdx('sector', value[0]);
        const subSector = findSectorIdx('subSector', value[1], sector);
        return {
          ...prev,
          type: value[0],
          type2: value[1],
          sector: sector,
          subSector: subSector,
        };
      }

      return { ...prev, [name]: clearValue };
    });
  };

  const addValueToList = (idx, value, isStore) => {
    if (isStore == null) {
      setUpdatedMenu((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 값이 이미 리스트에 있는지 확인
        if (list.includes(value)) {
          return prev; // 값이 이미 있으면 아무 것도 하지 않고 이전 상태 반환
        }
        // 찾은 리스트에 새로운 요소 추가
        const newList = [...prev];
        newList[idx] = [...list, value];
        return newList;
      });
    } else {
      setUpdatedStoreFile((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 값이 이미 리스트에 있는지 확인
        if (list.includes(value)) {
          return prev; // 값이 이미 있으면 아무 것도 하지 않고 이전 상태 반환
        }
        // 찾은 리스트에 새로운 요소 추가
        const newList = [...prev];
        newList[idx] = [...list, value];
        return newList;
      });
    }
  };

  const removeValueToList = (idx, value, isStore) => {
    if (isStore == null) {
      setUpdatedMenu((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 찾은 리스트에서 value 제거
        const newList = [...prev];
        newList[idx] = newList[idx].filter((item) => item != value);
        return newList;
      });
    } else {
      setUpdatedStoreFile((prev) => {
        // idx 값에 맞는 리스트 찾기
        const list = prev[idx];
        // 찾은 리스트에서 value 제거
        const newList = [...prev];
        newList[idx] = newList[idx].filter((item) => item != value);
        return newList;
      });
    }
  };

  const handlePasswordChange = () => {
    setPasswordModal(true);
  };

  const handleCloseModal = () => {
    setPasswordModal(false);
  };

  const handleSubmitPasswordChange = (newPassword, confirmPassword) => {
    const clearValue = makeClearValue(confirmPassword);
    handleNotice({
      target: {
        name: 'owner_Pwd',
        value: clearValue,
      },
    });
    handleCloseModal();
  };

  const handleChangeSector = (e) => {
    handleNotice({
      target: {
        name: 'sector',
        value: e.id - 1,
      },
    });
  };

  const findSectorIdx = (key, value, idx) => {
    if (key == 'sector') {
      return SECTORS.findIndex((sector) => sector.code === value);
    } else {
      return SUB_SECTORS[idx].findIndex((sector) => sector.value === value);
    }
  };

  const handleAddMenu = () => {
    const newMenu = { ...MENU };
    setMenuID(menuID + 1);
    addValueToList(0, menuID);
    setNotice((prev) => ({
      ...prev,
      menu: [
        ...prev.menu,
        { ...newMenu, id: menuID, idx: menuID, isNew: true },
      ],
    }));
  };

  // blob 변환
  const fileToBlob = (file) => {
    const blob = new Blob([file], { type: file.type });
    return blob;
  };

  // // 이미지 url -> 파일로 변환
  // const urlToFile = async (url, fileName) => {
  //   try {
  //     const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  //     const imageUrl = 'https://picsum.photos/200';
  //     const res = await fetch(proxyUrl + imageUrl);
  //     const blob = await res.blob();

  //     // 기본 MIME 타입을 설정합니다.
  //     const mimeType = blob.type || 'image/jpeg';

  //     const file = [new File([blob], fileName, { type: mimeType })];
  //     return file;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // 이미지 url -> 파일로 변환
  // const urlToFile = async (url, fileName) => {
  //   try {
  //     const res = await fetch(url); // 프록시 없이 직접 URL 사용
  //     const blob = await res.blob();

  //     // 기본 MIME 타입을 설정합니다.
  //     const mimeType = blob.type || 'image/jpeg';

  //     // Blob을 File 객체로 변환
  //     const file = new File([blob], fileName, { type: mimeType });

  //     return file; // 배열이 아닌 File 객체 자체를 반환
  //   } catch (e) {
  //     console.error('Error in urlToFile:', e);
  //     throw e; // 오류 발생 시 throw하여 호출부에서 처리할 수 있게 함
  //   }
  // };
  const urlToFile = async (url, fileName = 'image.jpg') => {
    try {
      const blob = await (await fetch(url)).blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error('Failed to convert URL to File:', error);
      throw error;
    }
  };

  // 시간
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // 옵션을 설정하여 날짜 형식을 지정합니다.
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    // 날짜 형식을 로컬 시간대에 맞게 변환합니다.
    const formattedDate = date
      .toLocaleString('en-GB', options)
      .replace(',', '');

    // '/'로 구분자를 변경합니다.
    const [day, month, yearAndTime] = formattedDate.split('/');
    const [year, time] = yearAndTime.split(' ');
    return `${year}/${month}/${day} ${time}`;
  };

  const handleChangeMenuImg = (e) => {
    const { id, files, dataset } = e.target;
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

    // 파일 크기 체크
    if (files[0].size > MAX_FILE_SIZE) {
      alert('파일 크기는 5MB를 초과할 수 없습니다.');
      return; // 파일 크기가 5MB를 초과하면 업로드 중단
    }

    const menu = notice.menu.find((item) => item.idx == id);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = e.target.result;
        const newImgFile = {
          ...menu,
          menu_Img: files,
          img_url: img,
        };
        if (!dataset.isnew) {
          addValueToList(1, id);
        }
        setNotice((prev) => ({
          ...prev,
          menu: prev.menu.map((item) => (item.idx == id ? newImgFile : item)),
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleAddImg = (e) => {
    const { name, files } = e.target;
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

    if (files[0].size > MAX_FILE_SIZE) {
      alert('파일 크기는 5MB를 초과할 수 없습니다.');
      return; // 파일 크기가 5MB를 초과하면 업로드 중단
    }

    const storeFile = Object.values(notice.storeFile).filter(
      (item) => item.type === name
    );

    if (storeFile.length >= 2) {
      alert('사진은 두장까지 등록 가능합니다.');
    } else {
      const num = storeFile.length !== 1 ? 0 : 1;

      setStoreID(storeID + 1);
      addValueToList(0, storeID, true);
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = e.target.result;
          const newStoreFile = {
            ...STORE_FILE_INFO,
            idx: storeID,
            order_no: num,
            type: name,
            img_url: img,
            store_Img: files,
            isNew: true,
          };

          setNotice((prev) => {
            const newKey = Object.keys(prev.storeFile).length.toString();
            return {
              ...prev,
              storeFile: [...prev.storeFile, newStoreFile],
            };
          });
        };
        reader.readAsDataURL(files[0]);
      }
    }
  };

  const handleDeleteImg = (e) => {
    const { id, dataset } = e.target;
    removeValueToList(0, id, true);
    if (!dataset.isnew) {
      addValueToList(2, id, true);
    }

    setNotice((prev) => {
      const updatedStoreFile = prev.storeFile.filter((item) => item.idx != id);
      return {
        ...prev,
        storeFile: updatedStoreFile,
      };
    });
  };

  const handlePhoneNumberFormat = (event) => {
    const origin = event.target.value;
    const key = event.key;

    var result;
    // 삭제일때
    if (key == 'Backspace' || key == 'Delete') {
      // 마지막이 - 일때 2개빼주기
      if (origin.slice(origin.length - 1) == '-') {
        result = origin.slice(0, origin.length - 2);
      } else {
        result = origin.slice(0, origin.length - 1);
      }
      //추가일때
    } else if (origin.length >= 13) {
      return;
    } else if (/^\d+$/.test(key)) {
      result = origin.replace(/\D/g, '') + key;
      if (result.length >= 7) {
        result = result.replace(/^(\d{3})(\d{4})(\d{0,4})$/, '$1-$2-$3');
      } else if (result.length >= 3) {
        result = result.replace(/^(\d{3})(\d{0,4})$/, '$1-$2');
      } else {
        result = result;
      }
    }
    setNotice((prev) => ({ ...prev, owner_Hp: result }));
  };

  const makeSubmitForm = (obj) => {
    return obj;
  };

  const convertURLtoFile = async (url) => {
    try {
      const response = await fetch(url, { mode: 'cors' });
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const data = await response.blob();
      const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
      const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/${ext}` };
      return new File([data], filename, metadata);
    } catch (error) {
      console.error('Error in convertURLtoFile:', error);
      throw error;
    }
  };

  const createMenu = (menu, isupdate) => {
    const formData = new FormData();

    const {
      default_Price,
      free_Yn,
      img_url,
      menu_Img,
      menu_Name,
      offer_Price,
      reg_dt,
    } = menu;
    if (
      String(default_Price) &&
      free_Yn &&
      img_url &&
      menu_Name &&
      String(offer_Price) &&
      reg_dt
    ) {
      formData.append(`default_Price`, default_Price);
      formData.append(`free_Yn`, free_Yn);

      if (!menu_Img) {
        const menuImg = convertURLtoFile(img_url, '파일이름');
        // const menuImg = img_url;
        formData.append(`menu_Img`, fileToBlob(menuImg[0]), menuImg[0].name);
      } else {
        formData.append(`menu_Img`, fileToBlob(menu_Img[0]), menu_Img[0].name);
      }

      formData.append(`menu_Name`, menu_Name);
      formData.append(`offer_Price`, offer_Price);
      formData.append(`reg_dt`, formatDate(reg_dt));

      // FormData 콘솔에 출력

      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      return formData;
    }
    return null;
  };

  const createStore = (store) => {
    const formData = new FormData();

    const { order_no, store_Img, type } = store;

    if ((String(order_no), store_Img, type)) {
      formData.append(`order_no`, order_no);
      formData.append(`store_Img`, fileToBlob(store_Img[0]), store_Img[0].name);
      formData.append(`type`, type);
      return formData;
    }
    return null;
  };

  const moveToMain = () => {
    window.history.go(-1);
  };

  const completeDelete = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const handleDeleteStore = async (password, reason) => {
    const confirmed = window.confirm('정말 삭제 하시겠습니까?');
    if (confirmed) {
      try {
        const res = await UpdateAPI.withdrawalStoreAdmin(notice.idx);
        if (res.status == 200) {
          alert('회원 탈퇴가 완료되었습니다.');
          completeDelete();
        } else {
          alert('회원 탈퇴에 실패했습니다.');
        }
      } catch (error) {
        console.error(error);
        alert('비밀번호를 확인해주세요.');
      } finally {
        window.location.reload();
      }
    }
  };

  const validateMenuItems = () => {
    for (const item of notice.menu) {
      // 메뉴에 이미지가 있는데, "품목", "기존가격", "제공가격" 중 하나라도 비어 있으면 false 반환
      if (
        item.menu_Img &&
        (!item.menu_Name || !item.default_Price || !item.offer_Price)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    const isConfirmed = window.confirm('정보를 변경하시겠습니까?');

    if (!isConfirmed) {
      return;
    }

    // 메뉴 항목에 대한 밸리데이션 수행
    if (!validateMenuItems()) {
      alert(
        '제공품목의 경우 "품목", "기존가격", "제공가격"을 모두 입력해야 합니다.'
      );
      return; // 밸리데이션 실패 시 폼 제출 중단
    }

    setIsSubmitting(true);

    const dk = [
      // "creditYn",
      // "kitYn",
      // "stickerYn",
      'owner_Email',
      'owner_Name',
      'sector',
      'subSector',
      'sns1',
      'sns2',
      'viewYn',
      'storeFile',
      'menu',
      'idx',
      '_links',
      'item_Code',
      'item_Etc',
      'p_nm',
      'start_dt',
    ];
    // 비밀번호 변경이 있을경우
    if (notice.owner_Pwd == null) {
      dk.push('owner_Pwd');
    }
    // notice.credit_Yn = notice.creditYn;
    // notice.kit_Yn = notice.kitYn;
    // notice.sticker_Yn = notice.stickerYn;

    const submitData = { ...notice };

    // 조건에 따라
    if (submitData.target1_Code != '99') {
      submitData.target1_Etc = '';
    }
    if (submitData.target2_Code != '99') {
      submitData.target2_Etc = '';
    }

    // 리스트 -> 스트링 변환
    submitData.close_Info = submitData.close_Info.join(', ').trim();
    submitData.target2_Code = submitData.target2_Code.join(', ').trim();

    // storeFile, menu 추출
    const { storeFile, menu } = submitData;

    // storeFile 제출 형태로 변환
    const submitStoreFile = makeSubmitForm(storeFile);
    // menu 제출 형태로 변환
    const submitMenu = makeSubmitForm(menu);

    // 안쓰는 컬럼 삭제
    dk.forEach((key) => delete submitData[key]);

    try {
      const responseForm = await StoreAPI.updateStore({
        id: idx,
        data: submitData,
      });
      const storeIdx = idx;

      // 2. 메뉴
      // add, update

      try {
        for (const menu of notice.menu) {
          if (updatedMenu[0].includes(menu.idx)) {
            const data = createMenu(menu);
            console.log('메뉴리스트 보내는 데이터', data);
            if (data) {
              const res = await UpdateAPI.addMenuAdmin(data, storeIdx);

              console.log('메뉴리스트', res);
            }
          } else if (updatedMenu[1].includes(menu.idx.toString())) {
            console.log('updata하는 메뉴', notice.menu);
            const data = createMenu(menu);
            const res = await UpdateAPI.updateMenuAdmin(
              data,
              storeIdx,
              menu.idx
            );
            console.log('메뉴리스트2', res);
          }
        }
      } catch (error) {
        console.log('메뉴리스트 error', error);
      }

      return;

      // delete
      for (const idx of updatedMenu[2]) {
        const res = await UpdateAPI.deleteMenuAdmin(storeIdx, idx);
      }

      // 3. 스토어 파일
      // 생성, 삭제
      for (const store of notice.storeFile) {
        if (updatedStoreFile[0].includes(store.idx)) {
          const data = createStore(store);
          if (data) {
            const res = await UpdateAPI.addStoreAdmin(data, storeIdx);
          }
        } else if (updatedStoreFile[1].includes(store.idx.toString())) {
          const data = createStore(store);
          const res = await UpdateAPI.updateMenuAdmin(
            data,
            storeIdx,
            store.idx
          );
        }
      }

      // delete
      for (const idx of updatedStoreFile[2]) {
        const res = await UpdateAPI.deleteStoreAdmin(storeIdx, idx);
      }

      // alert('정보가 변경 되었습니다.');
      // navigate(0);
      setIsSubmitting(false); // 로딩 상태 종료
    } catch (error) {
      console.error('Error updating store:', error);
    } finally {
      // alert('정보가 변경 되었습니다.');
      // navigate(0);
      // setIsSubmitting(false); // 로딩 상태 종료
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      {isSubmitting ? (
        <Spinner /> // handleSubmit이 실행 중일 때 스피너 표시
      ) : (
        idx &&
        notice.idx && (
          <StoreForm
            data={notice}
            navigate={navigate}
            onNotice={handleNotice}
            update={updateNotice}
            onChangeSector={handleChangeSector}
            onAddMenu={handleAddMenu}
            onAddImg={handleAddImg}
            onDeleteImg={handleDeleteImg}
            onSubmit={handleSubmit}
            onKeyDown={handlePhoneNumberFormat}
            onChangeMenuImg={handleChangeMenuImg}
            onDeleteStore={handleDeleteStore}
            onPasswordChange={handlePasswordChange}
            onSubmitPasswordChange={handleSubmitPasswordChange}
            onCloseModal={handleCloseModal}
            moveToMain={moveToMain}
            passwordModal={passwordModal}
          />
        )
      )}
    </>
  );
};

export default Index;
