import ApplyTitle from '../../../atoms/ApplyTitle';
import ApplyProgress from '../../../atoms/ApplyProgress';
import { useNavigate } from 'react-router-dom';
import BasicInfo from '../../../blocks/BasicInfo';
import StoreInfo from '../../../blocks/StoreInfo';
import AdditionalInfo from '../../../blocks/AdditionalInfo';

const Step2 = ({
  handleFocus,
  handleApplyForm,
  data,
  menuID,
  menuList,
  onMenuChange,
  onAddMenu,
  onDeleteMenu,
  onSummit,
  onStoreChange,
  storeFileList,
  applyForm,
  onPass,
  refs,
  onOnlyNumber,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {/* section1 */}
      <section id="section1" className="">
        <ApplyTitle title={'선한영향력가게 회원 신청'} />
      </section>

      {/* section2 */}
      <section
        id="section2"
        className="flex flex-col items-center md:mx-0 mx-4"
      >
        <ApplyProgress
          title={'기본정보'}
          semiTitle={'* 필수입력사항'}
          step={2}
        />

        <form className="flex flex-col w-full items-center">
          <BasicInfo
            {...{ handleFocus, handleApplyForm, data, applyForm, onPass, refs }}
          />
          <StoreInfo
            {...{ handleFocus, handleApplyForm, applyForm, onPass, refs }}
          />
          <AdditionalInfo
            {...{
              handleFocus,
              handleApplyForm,
              menuID,
              menuList,
              onMenuChange,
              onAddMenu,
              onDeleteMenu,
              onStoreChange,
              storeFileList,
              applyForm,
              onOnlyNumber,
            }}
          />
          <button
            className="flex border-solid border-2 border-sky-950 text-sky-950 md:w-80 w-48 h-16 gap-3 mb-24 bg-white justify-center items-center self-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer"
            disabled={false}
            onClick={(e) => {
              e.preventDefault();
              onSummit();
            }}
          >
            <p className="md:text-xl text-base font-bold">다음 단계</p>
          </button>
        </form>
      </section>
    </>
  );
};

export default Step2;
