import { AuthAPI, setAuthHeaders } from '.';

const mypageAPI = (API) => ({
  get: async (token) => {
    // await AuthAPI.reissue();
    return API.get('/account/mypage', setAuthHeaders(token));
  },
  getInfo: async (token) => {
    // await AuthAPI.reissue();
    return API.get('/account/mypage/getinfo', setAuthHeaders(token));
  },
  getMenuInfo: async (token) => {
    await AuthAPI.reissue();
    return API.get('/account/mypage/getmenuinfo', setAuthHeaders(token));
  },

  getStoreFileInfo: async (token) => {
    await AuthAPI.reissue();
    return API.get('/account/mypage/getstorefileinfo', setAuthHeaders(token));
  },
});

export default mypageAPI;
