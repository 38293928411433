import { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Toggle({
  disabled = false,
  index,
  id = null,
  onToggle = null,
  value,
}) {
  const [enabled, setEnabled] = useState(value === 'Y' ? true : false);

  return (
    <Switch
      disabled={disabled}
      checked={enabled}
      onChange={setEnabled}
      onClick={() => {
        onToggle({ index, id, enabled });
      }}
      className={classNames(
        enabled ? 'bg-main' : 'bg-gray-200',
        'relative inline-flex items-center h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
}
