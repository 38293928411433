import StoreInfo from "./StoreInfo";
import { SUB_SECTORS } from "../../../constants/sectors";
import { useEffect, useState } from "react";

const Index = ({ handleFocus, handleApplyForm, applyForm, onPass, refs }) => {
  const [sector, setSector] = useState(applyForm.sector);
  const [subSector, setSubSector] = useState(SUB_SECTORS[sector]);
  const [radio, setRadio] = useState("n");

  useEffect(() => {
    setSubSector(SUB_SECTORS[sector]);
  }, [sector]);

  const handleChangeSector = (e) => {
    setSector(e.id - 1);
  };

  const handleRadio = (e) => {
    setRadio(e.target.value);
    handleApplyForm(e);
  };

  return (
    <StoreInfo
      {...{
        handleFocus,
        handleApplyForm,
        radio,
        applyForm,
        sector,
        subSector,
        refs,
      }}
      onRadio={handleRadio}
      onChangeSector={handleChangeSector}
      onPass={onPass}
    />
  );
};

export default Index;
