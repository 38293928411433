import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import AddressButton from "./AddressButton";
import Td from "./Td";

const SearchPostcodeAdmin = ({ onNotice, data }) => {
  const [postcode, setPostcode] = useState(data.zipcode);
  const [roadAddress, setRoadAddress] = useState(data.addr1);
  const [detailAddress, setDetailAddress] = useState(data.addr2);
  const [guide, setGuide] = useState("");
  const scriptLoadedRef = useRef(false);

  useEffect(() => {}, [postcode, roadAddress, detailAddress]);

  useEffect(() => {
    setPostcode(data.zipcode || "");
    setRoadAddress(data.addr1 || "");
    setDetailAddress(data.addr2 || "");
  }, [data.zipcode]);

  const handleDetailAddr = (e) => {
    onNotice(e);
    setDetailAddress(e.target.value);
  };
  const handlePostcode = () => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        let roadAddr = data.roadAddress; // 도로명 주소 변수
        let extraRoadAddr = ""; // 참고 항목 변수

        if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
          extraRoadAddr += data.bname;
        }
        if (data.buildingName !== "" && data.apartment === "Y") {
          extraRoadAddr +=
            extraRoadAddr !== "" ? ", " + data.buildingName : data.buildingName;
        }
        if (extraRoadAddr !== "") {
          extraRoadAddr = " (" + extraRoadAddr + ")";
        }

        setPostcode(data.zonecode);
        setRoadAddress(roadAddr);
        setDetailAddress("");

        onNotice({
          target: { name: "zipcode", value: data.zonecode, dataset: "" },
        });
        onNotice({
          target: { name: "addr1", value: roadAddr, dataset: "" },
        });
        onNotice({
          target: { name: "addr2", value: "", dataset: "" },
        });

        if (data.autoRoadAddress) {
          const expRoadAddr = data.autoRoadAddress + extraRoadAddr;
          setGuide("(예상 도로명 주소 : " + expRoadAddr + ")");
        } else if (data.autoJibunAddress) {
          const expJibunAddr = data.autoJibunAddress;
          setGuide("(예상 지번 주소 : " + expJibunAddr + ")");
        } else {
          setGuide("");
        }
      },
    }).open();
  };

  return (
    <>
      <Helmet>
        <script
          src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
          async
          onLoad={() => {
            scriptLoadedRef.current = true;
          }}
        />
      </Helmet>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <input
            name="zipcode"
            type="text"
            className="w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
            placeholder="우편번호"
            value={postcode}
            disabled
            onChange={onNotice}
          />
        </div>

        <div className="w-full mb-4">
          <input
            name="addr1"
            type="text"
            className="w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
            placeholder="기본주소"
            value={roadAddress}
            disabled
            onChange={onNotice}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <input
            name="addr2"
            type="text"
            className="w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
            placeholder="상세 주소 (선택)"
            onChange={handleDetailAddr}
            value={detailAddress}
          />
        </div>
        <div className="w-full">
          <button
            className="bg-sky-800 hover:bg-sky-950 text-white py-2 px-4 rounded"
            onClick={handlePostcode}
          >
            주소 검색
          </button>
        </div>
      </div>
      <span
        id="guide"
        style={{ color: "#999", display: guide ? "block" : "none" }}
      >
        {guide}
      </span>
    </>
  );
};

export default SearchPostcodeAdmin;
