import React from 'react';

const DonationForm = ({ donationData, handleSubmit, handleChange }) => {
  const { totalDonations, totalDonors, totalExpenses } = donationData;

  return (
    <form
      onSubmit={handleSubmit}
      className='max-w-xl mx-auto bg-white p-8 rounded-lg shadow-lg'
    >
      <div className='mb-4'>
        <label className='block text-gray-700 text-sm font-bold mb-2'>
          총 기부금
        </label>
        <input
          type='number'
          name='totalDonations'
          value={totalDonations}
          onChange={handleChange}
          required
          className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700 text-sm font-bold mb-2'>
          총 기부자 수
        </label>
        <input
          type='number'
          name='totalDonors'
          value={totalDonors}
          onChange={handleChange}
          required
          className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700 text-sm font-bold mb-2'>
          총 지출
        </label>
        <input
          type='number'
          name='totalExpenses'
          value={totalExpenses}
          onChange={handleChange}
          required
          className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
        />
      </div>
      <button
        type='submit'
        className='w-full bg-main text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400'
      >
        저장 하기
      </button>
    </form>
  );
};

export default DonationForm;
