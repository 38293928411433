import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import AdminHeader from '../../../blocks/Admin/AdminHeader';
import AdminNavibar from '../../../blocks/Admin/AdminNavibar';
import AdminTitle from '../../../blocks/Admin/AdminTitle';
import StoreForm from '../../../blocks/Admin/StoreForm';

import PageButtonList from '../../../atoms/PageButtonList';
import Button from '../../../atoms/Button';

import { STORE_TABLE_LAYOUT } from '../../../../constants/admin';
import Toggle from '../../../atoms/Toggle';
import SelectSimpleCustom from '../../../atoms/SelectSimpleCustom';
import {
  SEARCH_SECTORS,
  SEARCH_SUB_SECTORS,
} from '../../../../constants/sectors';

const Store = ({
  selector,
  stores,
  curPages,
  setCurPages,
  totalPages,
  totalElements,
  onPaginationNumber,
  onPaginationPrev,
  onPaginationNext,
  onToggle,
  onSelectAll,
  onSelectStore,
  selectedStores,
  onExportToExcel,
  onSubmit,
  onChangeSector,
  onNotice,
  onChange,
}) => {
  const match = useMatch('/admin/storeList/storeForm');
  const navigate = useNavigate();
  const layout = STORE_TABLE_LAYOUT;
  const data = stores;
  const form = 'storeForm';

  return (
    <div id='wrapper' className='flex flex-col bg-slate-100'>
      <AdminHeader />
      <div
        id='container'
        className={`flex justify-between container mx-auto ${
          !match && ''
        } bg-slate-100`}
      >
        <AdminNavibar />
        <div id='white-box' className='flex flex-col w-full bg-white p-4'>
          <div className='flex justify-between'>
            <div id='title-container' className='flex w-full'>
              <div className='hidden lg:block'>
                <AdminTitle title={'선한영향력가게 관리'} />
              </div>
              {!match && (
                <div id='search-wrapper' className='flex'>
                  <div className='flex p-2 space-x-2'>
                    <div className='w-28'>
                      <SelectSimpleCustom
                        options={SEARCH_SECTORS}
                        onChangeSector={onChangeSector}
                        value={selector.sector}
                        onNotice={onNotice}
                        isSub={false}
                      />
                    </div>
                    <div className='w-28'>
                      <SelectSimpleCustom
                        options={SEARCH_SUB_SECTORS[selector.sector]}
                        onChangeSector={onChangeSector}
                        value={selector.subSector}
                        onNotice={onNotice}
                        isSub={true}
                      />
                    </div>
                  </div>
                  <div className='flex space-x-2'>
                    <div className='mt-2 w-32'>
                      <input
                        onChange={onChange}
                        id='startDate'
                        name='startDate'
                        type='date'
                        placeholder='yyyy-mm-dd'
                        className='block w-full rounded-md border-1 border-gray-200 border-solid py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                    <div className='mt-2 w-32'>
                      <input
                        onChange={onChange}
                        id='endDate'
                        name='endDate'
                        type='date'
                        placeholder='yyyy-mm-dd'
                        className='block w-full rounded-md border-1 border-gray-200 border-solid py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>

                    <form id='search' onSubmit={onSubmit}>
                      <div className='relative mt-2 rounded-md shadow-sm border-solid border-gray-300 border w-72'>
                        <input
                          onChange={onChange}
                          id='keyword'
                          name='keyword'
                          type='text'
                          className='block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 
                    ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                    focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        />

                        <div
                          className='absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer'
                          onClick={onSubmit}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                            class='size-5'
                          >
                            <path
                              fill-rule='evenodd'
                              d='M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z'
                              clip-rule='evenodd'
                            />
                          </svg>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
          {match && (
            <div id='store-form'>
              <StoreForm />
            </div>
          )}
          {!match && (
            <div>
              <table className='min-w-full bg-white border border-gray-200'>
                <thead>
                  <tr className='bg-gray-100 border-black border-t-2 border-solid'>
                    <th className='px-4 py-2.5 text-center text-sm font-semibold whitespace-nowrap text-gray-600'>
                      <input
                        type='checkbox'
                        className='w-4 h-4 border-solid border-gray-500 text-sky-950'
                        onChange={onSelectAll}
                        checked={selectedStores.length === stores.length}
                      />
                    </th>
                    {layout.map(({ name, value, width }) => (
                      <th
                        key={name}
                        className={`${width} px-4 py-2.5 text-center text-sm font-semibold whitespace-nowrap text-gray-600`}
                      >
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={item.idx}
                      className='border-b border-solid border-gray-300 hover:bg-gray-50'
                    >
                      <td
                        key={item.idx}
                        className='text-center pb-1 align-middle'
                      >
                        <input
                          type='checkbox'
                          className='w-4 h-4 border-solid border-gray-500 text-sky-950'
                          onChange={() => onSelectStore(item)}
                          checked={selectedStores.includes(item)}
                        />
                      </td>
                      {layout.map(({ name }) => {
                        const value = item[name];
                        let tableValue = value;

                        if (name === 'viewYn') {
                          return (
                            <td
                              key={name}
                              className='text-center pt-1 align-middle'
                            >
                              <Toggle
                                onToggle={onToggle}
                                index={index}
                                idx={item.idx}
                                value={item.viewYn}
                              />
                            </td>
                          );
                        } else if (
                          name === 'creditYn' ||
                          name === 'stickerYn' ||
                          name === 'kitYn'
                        ) {
                          return (
                            <td
                              key={name}
                              className='text-center pb-1 align-middle'
                            >
                              <input
                                type='checkbox'
                                checked={value === 'Y'}
                                className='w-4 h-4 border-solid border-gray-300 bg-gray-50 text-sky-950'
                                disabled
                              />
                            </td>
                          );
                        } else if (name === 'regularYn') {
                          tableValue = value === 'Y' ? '정회원' : '-';
                        } else if (name === 'reg_Dt') {
                          tableValue = value.split(' ')[0];
                        } else if (name === 'idx') {
                          return (
                            <td
                              key={name}
                              className='text-center p-2 text-sm text-gray-700 cursor-pointer align-middle'
                              onClick={() =>
                                navigate(`${form}?idx=${item.idx}`)
                              }
                            >
                              {totalElements - (curPages * 10 + index)}
                            </td>
                          );
                        }

                        return (
                          <td
                            key={name}
                            className='text-center p-2 text-sm text-gray-700 cursor-pointer align-middle'
                            onClick={() => navigate(`${form}?idx=${item.idx}`)}
                          >
                            {tableValue}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
              <PageButtonList
                onPaginationPrev={onPaginationPrev}
                onPaginationNext={onPaginationNext}
                curPages={curPages}
                setCurPages={setCurPages}
                totalPages={totalPages}
                onPaginationNumber={onPaginationNumber}
              />
              <div className='flex space-x-8'>
                <Button
                  title={'선택항목 엑셀받기'}
                  className={'p-2 rounded-none'}
                  onClick={() => {
                    onExportToExcel('select');
                  }}
                />
                <Button
                  title={'검색항목 엑셀받기'}
                  className={'p-2 rounded-none'}
                  onClick={() => {
                    onExportToExcel('search');
                  }}
                />
              </div>
            </div>
          )}
          <div className='p-4' />
        </div>
      </div>
    </div>
  );
};

export default Store;
