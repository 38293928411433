import { CameraIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';

const StoreImages = ({
  titleId,
  storeFile,
  onStoreChange,
  onFileSelect,
  isUpdate,
  setUpdatedStoreFile,
}) => {
  const [imageURL1, setImageURL1] = useState(storeFile[0].img_url);
  const [imageURL2, setImageURL2] = useState(storeFile[1].img_url);
  const [file1, setFile1] = useState(storeFile[0]);
  const [file2, setFile2] = useState(storeFile[1]);
  const [isActive, setIsActive] = useState(storeFile[2]);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  useEffect(() => {
    if (storeFile[0].store_Img && storeFile[0].store_Img[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageURL1(e.target.result);
      };
      reader.readAsDataURL(storeFile[0].store_Img[0]);
    } else {
      setImageURL1(storeFile[0].img_url);
    }
    if (storeFile[1].store_Img && storeFile[1].store_Img[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageURL2(e.target.result);
      };
      reader.readAsDataURL(storeFile[1].store_Img[0]);
    } else {
      setImageURL2(storeFile[1].img_url);
    }
  }, [storeFile]);

  const handleImgFile = (e, order) => {
    e.preventDefault();
    e.target = e.currentTarget; //
    if (order == 0) {
      if (storeFile[0].store_Img) {
        onStoreChange({
          target: {
            id: titleId,
            dataset: { id: 0, originId: storeFile[0].idx },
            name: 'store_Img',
            files: null,
          },
        });
        setImageURL1(storeFile[0].img_url);
      } else {
        fileInputRef1.current.click();
      }
    } else {
      if (storeFile[1].store_Img) {
        onStoreChange({
          target: {
            id: titleId,
            dataset: { id: 0, originId: storeFile[1].idx },
            name: 'store_Img',
            files: null,
          },
        });
        setImageURL2(storeFile[1].img_url);
      } else {
        fileInputRef2.current.click();
      }
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <p className="text-gray-600">{file1.title}</p>
        <div className="flex flex-row gap-2">
          <a
            key={1}
            name="store_Img"
            className="flex border-solid border border-sky-950 size-20 gap-3 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer"
            onClick={(e) => handleImgFile(e, 0)}
          >
            {imageURL1 ? (
              <img src={imageURL1} alt="Uploaded" className="size-full" />
            ) : (
              <CameraIcon className="size-16" />
            )}
          </a>
          <input
            id={titleId}
            data-id={0}
            name="store_Img"
            type="file"
            ref={fileInputRef1}
            style={{ display: 'none' }}
            onChange={onFileSelect}
          />

          {storeFile[2] && file1.title !== '상호명(CI)' && (
            <>
              <a
                key={2}
                name="store_Img"
                className="flex border-solid border border-sky-950 size-20 gap-3 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer"
                onClick={(e) => handleImgFile(e, 1)}
              >
                {imageURL2 ? (
                  <img src={imageURL2} alt="Uploaded" className="size-full" />
                ) : (
                  <CameraIcon className="size-16" />
                )}
              </a>
              <input
                id={titleId}
                data-id={1}
                name="store_Img"
                type="file"
                ref={fileInputRef2}
                style={{ display: 'none' }}
                onChange={onStoreChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StoreImages;
