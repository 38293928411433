import Dividers from "./Dividers";

export default function PolicyContent({ item }) {
  return (
    <div className="flex flex-col mb-20">
      <div className="flex font-bold text-xl">{item.title}</div>
      <br />
      {item.describe && <div className="flex text-lg">{item.describe}</div>}
      {item.descriptionLines &&
        item.descriptionLines.map((line, index) => (
          <div key={index}>
            {line}
            <br />
          </div>
        ))}
      {item.describe4 && <div className="flex text-lg">{item.describe4}</div>}
      <br />
      <ol className="flex flex-col text-lg">
        {item.contents.map((content) => {
          return (
            <li key={content.id} className="flex flex-col gap-2">
              {content.id + ". "}
              {content.content}
              <ol className="pl-8 text-base">
                {content.subContent.map((sub) => {
                  return (
                    <li key={sub.id}>
                      {sub.id + ") "}
                      {sub.content}
                    </li>
                  );
                })}
              </ol>
              <br />
            </li>
          );
        })}
      </ol>
      {item.link && (
        <>
          <div className="flex text-lg">{item.describe2}</div>
          <br />
          <div className="flex text-lg">{item.describe3}</div>
        </>
      )}
      {item.id != 19 && item.type != "email" && item.last != true ? (
        <Dividers />
      ) : (
        ""
      )}
    </div>
  );
}
