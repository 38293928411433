const faqAPI = (API) => ({
  getFaqs: ({ params }) =>
    API.get('admin/faq/list', {
      params: { page: params.curPages, size: params.pageSize },
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => {
      const result = response.data;
      const page = result['page'];
      const links = result['_links'];
      if (page.totalElements > 0) {
        const data = result['_embedded']['allFAQResourceList'];
        return { data, page, links };
      } else {
        return result;
      }
    }),

  getFaq: ({ id }) =>
    API.get(`admin/faq/${id}`, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  updateFaq: ({ id, data }) =>
    API.patch(`admin/faq/update/${id}`, data, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  createFaq: ({ data }) =>
    API.post('admin/faq/', data, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  deleteFaq: ({ id }) =>
    API.delete(`admin/faq/delete/${id}`, {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  uploadFaqImage: ({ data }) =>
    API.post(`admin/faq/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  addFileFaq: ({ id, data }) =>
    API.post(`admin/faq/update/addfile/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),

  deleteFileFaq: ({ data }) =>
    API.delete(`admin/faq/update/delfile`, {
      data: data,
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    }).then((response) => response.data),
});

export default faqAPI;
