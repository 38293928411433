// 메소드가 담긴 객체를 반환
const businessesAPI = (API) => ({
  createPost: (postData) =>
    API.post(
      `https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=${process.env.REACT_APP_BUSINESSES_KEY}`,
      postData,
      {
        // baseURL 무시를 위해 headers 재설정이 필요한 경우, 추가
        headers: {
          'Content-Type': 'application/json',
          Web: undefined,
        },
        withCredentials: false,
      }
    ),
});

export default businessesAPI;
