export const APPLY_FORM = {
  // 동행신청 api
  owner_Email: null,
  owner_Name: null,
  owner_Pwd: null,
  owner_Pwd_: null,
  owner_Hp: null,
  name: '',
  type: '00',
  type2: null,
  tel: null,
  tel_Yn: 'Y',
  reg_No: null,
  zipcode: null,
  addr1: null,
  addr2: null,
  sns_Type1: '00',
  sns_Url1: '',
  sns_Type2: '00',
  sns_Url2: '',
  open_Info: '오픈정보',
  close_Info: [],
  target1_Code: '01',
  target1_Etc: '',
  target2_Code: [],
  target2_Etc: '',
  agreeYn: 'N',
  regularYn: 'y',
  break_End_Hour: '',
  break_Start_Hour: '',
  close_Hour: '',
  open_Hour: '',

  //옵션
  // lat: "",
  // lng: "",
  reg_Name: '',

  // area: "모지?",
  // item_code: "???",
  // item_etc: "???",
  // business_status: "",
  reg_date: null,

  //필요데이터
  sector: 0,
  subSector: 0,
};
