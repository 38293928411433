import React from 'react';

const RatioSimpleInlineList = ({ data, name, check, onNotice }) => {
  return (
    <div className="space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
      {data.map((notificationMethod) => (
        <div key={notificationMethod.id} className="flex items-center">
          <input
            id={notificationMethod.id}
            name={name}
            type="radio"
            checked={
              notificationMethod.id.slice(-1) == check ||
              notificationMethod.id == check
            }
            value={
              notificationMethod.id.slice(-1) || notificationMethod.id == check
            }
            className="outline border border-solid box-shadow h-4 w-4 border-gray-300 text-sky-800 focus:ring-sky-800"
            onChange={onNotice}
          />
          <label
            htmlFor={notificationMethod.id}
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            {notificationMethod.title}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RatioSimpleInlineList;
