import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import AdminHeader from '../../../blocks/Admin/AdminHeader';
import AdminNavibar from '../../../blocks/Admin/AdminNavibar';
import AdminTable from '../../../blocks/Admin/AdminTable';
import AdminTitle from '../../../blocks/Admin/AdminTitle';
import AskForm from '../../../blocks/Admin/AskForm';

import Button from '../../../atoms/Button';
import PageButtonList from '../../../atoms/PageButtonList';

import { ASK_TABLE_LAYOUT } from '../../../../constants/admin';
import { ASK_TYPE } from '../../../../constants/sectors';

const Ask = ({
  asks,
  curPages,
  setCurPages,
  totalPages,
  totalElements,
  onPaginationNumber,
  onPaginationPrev,
  onPaginationNext,
}) => {
  const match = useMatch('/admin/askList/askForm');
  const navigate = useNavigate();
  const layout = ASK_TABLE_LAYOUT;
  const data = asks;
  const form = 'askForm';

  const getTypeValueByCode = (code) => {
    const type = ASK_TYPE.find((item) => item.code === code);
    return type ? type.value : 'Undefined';
  };

  return (
    <div id='wrapper' className='flex flex-col bg-slate-100'>
      <AdminHeader />
      <div
        id='container'
        className='flex justify-between container mx-auto h-screen bg-slate-100'
      >
        <AdminNavibar />
        <div id='white-box' className='flex flex-col w-full bg-white p-4'>
          <AdminTitle title={'1:1문의'} />
          {match && (
            <div id='ask-form'>
              <AskForm />
            </div>
          )}
          {!match && (
            <div>
              {/* <AdminTable layout={ASK_TABLE_LAYOUT} data={asks} form={'askForm'} /> */}
              <table className='min-w-full bg-white border border-gray-200'>
                <thead>
                  <tr className='bg-gray-100 border-black border-t-2 border-solid'>
                    {layout.map(({ name, value, width }) => (
                      <th
                        key={name}
                        className={`${width} px-4 py-2.5 text-center text-sm font-semibold whitespace-nowrap text-gray-600`}
                      >
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={item.idx}
                      className='border-b border-solid border-gray-300 hover:bg-gray-50'
                    >
                      {layout.map(({ name }) => {
                        const value = item[name];
                        let tableValue = value;

                        if (name === 'answerYn') {
                          return (
                            <td
                              key={name}
                              className='text-center pb-1 align-middle'
                            >
                              <input
                                type='checkbox'
                                checked={value === 'Y'}
                                disabled
                                className='w-4 h-4 border-solid border-gray-300 bg-gray-50 text-sky-950'
                              />
                            </td>
                          );
                        } else if (name === 'type') {
                          tableValue = getTypeValueByCode(value);
                        } else if (name === 'regDt') {
                          tableValue = value.split(' ')[0];
                        } else if (name === 'idx') {
                          return (
                            <td
                              key={name}
                              className='text-center p-2 text-sm text-gray-700 cursor-pointer align-middle'
                              onClick={() =>
                                navigate(`${form}?idx=${item.idx}`)
                              }
                            >
                              {totalElements - (curPages * 10 + index)}
                            </td>
                          );
                        }

                        return (
                          <td
                            key={name}
                            className='text-center p-2 text-sm text-gray-700 cursor-pointer align-middle'
                            onClick={() => navigate(`${form}?idx=${item.idx}`)}
                          >
                            {tableValue}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
              <PageButtonList
                onPaginationPrev={onPaginationPrev}
                onPaginationNext={onPaginationNext}
                curPages={curPages}
                setCurPages={setCurPages}
                totalPages={totalPages}
                onPaginationNumber={onPaginationNumber}
              />
            </div>
          )}

          <div className='p-4' />
        </div>
      </div>
    </div>
  );
};

export default Ask;
