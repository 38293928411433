import { useState, useEffect } from 'react';
import ApplyProgress from '../../atoms/ApplyProgress';
import ApplyTitle from '../../atoms/ApplyTitle';
import AdditionalInfo from '../../blocks/AdditionalInfo';
import BasicInfo from '../../blocks/BasicInfo';
import StoreInfo from '../../blocks/StoreInfo';

const MyInfo = ({
  handleFocus,
  handleApplyForm,
  data,
  menuList,
  onMenuChange,
  onAddMenu,
  onDeleteMenu,
  onSummit,
  onStoreChange,
  storeFileList,
  applyForm,
  setUpdatedStoreFile,
  onPass,
  refs,
  onStoreDelete,
}) => {
  const [secretModal, setSecretModal] = useState(false);
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');

  useEffect(() => {}, []);

  const handleDeleteClick = () => {
    setSecretModal(true);
  };

  const handleSecretCheckClick = (e) => {
    e.preventDefault();
    setSecretModal(false);
    onStoreDelete(password, reason); // 비밀번호와 사유를 전달
  };

  return (
    <>
      {secretModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">비밀번호 입력</h2>
            <form onSubmit={handleSecretCheckClick}>
              <div className="mb-4">
                <label
                  htmlFor="secretPwd"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호
                </label>
                <input
                  type="password"
                  id="secretPwd"
                  name="secretPwd"
                  required
                  className="mt-1 block w-full px-3 py-2 border-sky-800 border-2 border-solid rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="reason"
                  className="block text-sm font-medium text-gray-700"
                >
                  탈퇴 사유
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  rows="4"
                  className="mt-1 block w-full px-3 py-2 border-sky-800 border-2 border-solid rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="submit"
                  className="bg-sky-800 text-white px-4 py-2 rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  확인
                </button>
                <button
                  type="button"
                  onClick={() => setSecretModal(false)}
                  className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  취소
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* section1 */}
      <section id="section1" className="md:mb-0 mb-4">
        <ApplyTitle title={'나의 정보'} />
      </section>

      {/* section2 */}
      <section
        id="section2"
        className="flex flex-col items-center md:mx-0 mx-4"
      >
        <div className="flex flex-row md:w-5/6 w-full md:mb-8 justify-between border-solid border-b-2 border-gray-500">
          <h3 className="flex md:text-2xl text-sm font-bold md:mb-4">
            기본정보
          </h3>
        </div>

        <form className="flex flex-col w-full items-center">
          {
            <BasicInfo
              {...{
                handleFocus,
                handleApplyForm,
                data,
                applyForm,
                onPass,
                refs,
              }}
            />
          }
          {applyForm.type2 && (
            <StoreInfo
              {...{ handleFocus, handleApplyForm, applyForm, onPass, refs }}
            />
          )}
          {applyForm.type2 && (
            <AdditionalInfo
              {...{
                handleFocus,
                handleApplyForm,
                menuList,
                onMenuChange,
                onAddMenu,
                onDeleteMenu,
                onStoreChange,
                storeFileList,
                applyForm,
                setUpdatedStoreFile,
              }}
            />
          )}
          <div className="flex gap-4">
            <div className="flex ">
              <button
                className="flex border-solid border-2 border-sky-950 text-sky-950 md:w-80 w-36 h-16 gap-3 mb-24 md:mt-0 mt-12 bg-white justify-center items-center self-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  onSummit();
                }}
              >
                <p className="md:text-xl text-base font-bold">정보 수정</p>
              </button>
            </div>

            <div className="flex">
              <button
                className="flex border-solid border-2 border-sky-950 text-sky-950 md:w-80 w-36 h-16 gap-3 mb-24 md:mt-0 mt-12 bg-white justify-center items-center self-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteClick();
                }}
              >
                <p className="md:text-xl text-base font-bold text-red-700">
                  회원 탈퇴
                </p>
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default MyInfo;
