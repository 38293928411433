const PRIVACY_ITEMS = [
  {
    id: 1,
    title: "01. 개인정보의 처리목적",
    describe:
      "협회는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.",
    contents: [
      {
        id: 1,
        content: "온라인 회원 가입 및 관리",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content:
              "회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.",
          },
        ],
      },
      {
        id: 2,
        content: "민원사무처리",
        subContent: [
          {
            id: 2,
            idx: "2, ",
            content:
              "민원사항 확인, 사실 확인을 위한 연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.",
          },
        ],
      },
      {
        id: 3,
        content: "협회 사업 안내 및 홍보, 유관기관 사업 홍보를 위한 처리",
        subContent: [
          {
            id: 3,
            idx: "3, ",
            content: "협회 내에서 수행하는 모든 사업과 유관기관 사업.",
          },
        ],
      },
      {
        id: 4,
        content:
          "세미나·포럼, 교육·연수, 교류회, 조사, 기타 사업 등 안내 및 후속 사업 안내",
        subContent: [],
      },
      {
        id: 3,
        content:
          "협회는 사업안내 및 홍보를 위한 정보 이외의 민감정보는 수집하지 않습니다.",
        subContent: [],
      },
    ],
  },
  {
    id: 2,
    title: "02. 개인정보의 처리 및 보유기간",
    describe:
      "본 약관은 선한영향력가게 홈페이지 (이하 '당 홈페이지’)가 제공하는 모든 서비스(이하 '서비스')의 이용조건 및 절차, 이용자/회원과 당 홈페이지의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.",
    contents: [
      {
        id: 1,
        content:
          "당 홈페이지는 이용자/회원이 본 약관 내용에 동의하는 것을 조건으로 이용자/회원에 한하여 서비스를 제공할 것이며, 이용자/회원이 본 약관의 내용에 동의하는 경우, 당 홈페이지의 서비스 제공 행위 및 이용자/회원의 서비스 사용 행위에는 본 약관이 우선적으로 적용됩니다.",
        subContent: [],
      },
      {
        id: 2,
        content:
          "당 홈페이지는 합리적 이유가 있는 경우 약관을 변경할 수 있으며, 변경된 약관은 서비스 화면에 게재하거나 기타 방법으로 이용자에게 공지함으로써 효력을 발생합니다.",
        subContent: [],
      },
      {
        id: 3,
        content:
          "회원은 변경된 약관에 동의하지 않을 경우, 서비스 이용을 중단하고 탈퇴할 수 있습니다. 약관이 변경된 이후에도 계속적으로 서비스를 이용하는 경우에는 회원이 약관의 변경 사항에 동의한 것으로 봅니다.",
        subContent: [],
      },
    ],
  },
  {
    id: 3,
    title: "03. 개인정보의 제3자 제공",
    describe: "협회는 개인정보를 제3자에게 제공하지 않습니다.",
    contents: [],
  },
  {
    link: true,
    id: 4,
    title: "04. 개인정보의 제3자 제공",
    describe:
      "1) “협회”는 원활한 개인 정보처리를 위해 다음과 같이 개인 정보 처리 업무를 위탁 또는 재위탁하고 있습니다.",
    describe2:
      "2) “협회”는 위탁계약 체결 시 개인정보보호법 제26조에 따라 위탁업무 수행 목적 외 개인 정보 처리 금지, 기술적·관리적 보호 조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인 정보를 안전하게 처리하는지를 감독하고 있습니다.",
    describe3:
      "3) 위탁업무의 내용이나 수탁자가 변경될 경우 지체없이 본 개인 정보처리 방침을 통해 공개하도록 하겠습니다.",
    contents: [
      {
        id: 1,
        content: "위탁업체명: ㈜코즈웍스",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "위탁 형태 : 위탁",
          },
          {
            id: 2,
            idx: "2, ",
            content:
              "위탁업무의 내용: 플랫폼 개발, 운영, 관리, 유지 보수 등 시스템 관련 운영 전반",
          },
          {
            id: 3,
            idx: "3, ",
            content: "위탁 기간: 위탁계약 종료 시까지",
          },
        ],
      },
      {
        id: 2,
        content: "위탁업체명: Amazon Web Service",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "위탁 형태 : 재위탁",
          },
          {
            id: 2,
            idx: "2, ",
            content:
              "위탁업무의 내용: 플랫폼 서비스 운영을 위한 클라우드 서비스",
          },
          {
            id: 3,
            idx: "3, ",
            content: "위탁 기간: 위탁계약 종료 시까지",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: "05. 정보주체의 권리·의무 및 행사방법",
    describe: "",
    contents: [
      {
        id: 1,
        content:
          "정보주체는 협회에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "개인정보 열람요구",
          },
          {
            id: 2,
            idx: "2, ",
            content: "오류 등이 있을 경우 정정 요구",
          },
          {
            id: 3,
            idx: "3, ",
            content: "삭제 요구",
          },
          {
            id: 4,
            idx: "4, ",
            content: "처리정지 요구",
          },
        ],
      },
      {
        id: 2,
        content:
          "제1항에 따른 권리 행사는 협회에 대해 서면, 전화, 전자우편 등을 통하여 하실 수 있으며 협회는 이에 대해 지체 없이 조치하겠습니다.",
        subContent: [],
      },
      {
        id: 3,
        content:
          "정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 협회는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.",
        subContent: [],
      },
      {
        id: 4,
        content:
          "제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.",
        subContent: [],
      },
      {
        id: 5,
        content:
          "정보주체는 개인정보 보호법 등 관계법령을 위반하여 협회가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.",
        subContent: [],
      },
    ],
  },
  {
    id: 6,
    title: "06. 처리하는 개인정보 항목",
    describe: "[온라인 회원사 가입 및 관리]",
    describe4:
      "필수항목 : 이메일(ID), 이름, 비밀번호, 휴대폰 번호, 전화번호, 상호명, 업종, 지역, 사업장주소, 사업자등록번호",
    contents: [
      {
        id: 1,
        content:
          "인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.",
        subContent: [],
      },
      {
        id: 2,
        content: "IP주소, 쿠키, 서비스 이용기록, 방문기록 등",
        subContent: [],
      },
      {
        id: 3,
        content:
          "협회는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 운용합니다. 쿠키란 협회의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일 로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 협회는 다음과 같은 목적을 위해 쿠키를 사용합니다. 회원과 비회원의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과 관심분야를 파악하여 서비스 개편 등의 척도로 활용합니다.",
        subContent: [],
      },
      {
        id: 4,
        content:
          "이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부하셨을 경우 일부 서비스제공에 어려움이 있을 수 있습니다.",
        subContent: [],
      },
      {
        id: 5,
        content:
          "쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.",
          },
          {
            id: 2,
            idx: "2, ",
            content: "[개인정보 탭]을 클릭합니다.",
          },
          {
            id: 3,
            idx: "3, ",
            content: "[개인정보취급 수준]을 설정하시면 됩니다.",
          },
        ],
      },
      {
        id: 6,
        content:
          "협회는 사업안내 및 홍보를 위한 정보 이외의 민감정보는 수집하지 않습니다.",
        subContent: [],
      },
    ],
  },
  {
    id: 8,
    title: "07. 개인정보의 파기",
    descriptionLines: [
      "협회는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.",
      "정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.",
      "개인정보 파기의 절차 및 방법은 다음과 같습니다.",
    ],
    contents: [
      {
        id: 1,
        content: "파기절차",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content:
              "개인정보 : 이용자가 입력한 정보는 목적 달성 후 지체 없이 파기합니다.",
          },
          {
            id: 2,
            idx: "2, ",
            content:
              "개인정보파일 : 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보 파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 파기합니다.",
          },
        ],
      },
      {
        id: 1,
        content: "파기방법",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content:
              "전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.",
          },
          {
            id: 2,
            idx: "2, ",
            content: "종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다.",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    title: "08. 개인정보 보호책임자",
    describe: "",
    contents: [
      {
        id: 1,
        content:
          "협회는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "개인정보 관리 책임자 : 오인태 대표",
          },
          {
            id: 2,
            idx: "2, ",
            content:
              "개인정보 관리 담당자 : 오인태 대표 goodimpact012@naver.com",
          },
        ],
      },
      {
        id: 1,
        content:
          "이용자는 협회의 서비스(또는 사업)을 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 협회는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.",
        subContent: [],
      },
    ],
  },
  {
    id: 10,
    title: "09. 개인정보 처리방침의 변경에 관한 사항",
    describe: "이 개인정보 처리방침은 2024.07.31부터 적용됩니다.",
    contents: [],
  },
  {
    id: 11,
    title: "10. 동의를 거부할 권리가 있다는 사실과 동의 거부에 따른 불이익",
    describe:
      "이용자는 협회에서 수집하는 개인정보에 대해 동의를 거부할 권리가 있으며 동의 거부 시에는 협회 정보제공 서비스가 일부 제한됩니다.",
    contents: [],
  },
  {
    last: true,
    id: 12,
    title: "11. 권익침해 구제방법",
    describe:
      "정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.",
    contents: [
      {
        id: 1,
        content: "개인정보분쟁 조정위원회",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "홈페이지 : https://www.kopico.go.kr",
          },
          {
            id: 2,
            idx: "2, ",
            content: "전화번호 : (국번없이)118(내선2번)",
          },
        ],
      },
      {
        id: 2,
        content: "대검찰청 사이버범죄 수사단",
        subContent: [
          {
            id: 1,
            idx: "1, ",
            content: "홈페이지 : http://www.spo.go.kr",
          },
          {
            id: 2,
            idx: "2, ",
            content: "전화번호 : 02-3480-3582",
          },
        ],
      },
    ],
  },
];

export { PRIVACY_ITEMS };
