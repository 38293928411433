import React, { useEffect, useState } from 'react';
import DonationForm from './DonationForm';
import { AdminPostAPI } from '../../../../api';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();
  const [donationData, setDonationData] = useState({
    totalDonations: 0,
    totalDonors: 0,
    totalExpenses: 0,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await AdminPostAPI.getDonation();

        setDonationData({
          totalDonations: data.totalDonations,
          totalDonors: data.totalDonors,
          totalExpenses: data.totalExpenses,
        });
      } catch (error) {
        console.log('도네이션 에러', error);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDonationData({
      ...donationData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // API 호출 부분 (저장 로직 구현)
      const result = await AdminPostAPI.postDonation({ data: donationData });
      alert('변경됐습니다.', result);
      navigate(0);
    } catch (error) {
      console.error('Error saving donation data:', error);
      alert('Failed to save donation data');
    }
  };

  return (
    <DonationForm
      {...{ donationData }}
      {...{ handleChange }}
      {...{ handleSubmit }}
    />
  );
};

export default Index;
