import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import AdminHeader from '../../../blocks/Admin/AdminHeader';
import AdminNavibar from '../../../blocks/Admin/AdminNavibar';
import AdminTitle from '../../../blocks/Admin/AdminTitle';
import NoticeForm from '../../../blocks/Admin/NoticeForm';

import Toggle from '../../../atoms/Toggle';
import PageButtonList from '../../../atoms/PageButtonList';

import { NOTICE_TABLE_LAYOUT } from '../../../../constants/admin';

const Notice = ({
  notices,
  curPages,
  setCurPages,
  totalPages,
  totalElements,
  onPaginationNumber,
  onPaginationPrev,
  onPaginationNext,
  onToggle,
}) => {
  const match = useMatch('/admin/noticeList/noticeForm');
  const navigate = useNavigate();
  const layout = NOTICE_TABLE_LAYOUT;
  const data = notices;
  const form = 'noticeForm';

  return (
    <div id='wrapper' className='flex flex-col bg-slate-100'>
      <AdminHeader />
      <div
        id='container'
        className='flex justify-between container mx-auto bg-slate-100'
      >
        <AdminNavibar />
        <div id='white-box' className='flex flex-col w-full  bg-white p-4'>
          <AdminTitle hasAddButton={!match} title={'공지사항'} form={form} />
          {match && (
            <div id='notice-form'>
              <NoticeForm />
            </div>
          )}
          {!match && (
            <div>
              <table className='min-w-full bg-white border border-gray-200'>
                <thead>
                  <tr className='bg-gray-100 border-black border-t-2 border-solid'>
                    {layout.map(({ name, value, width }) => (
                      <th
                        key={name}
                        className={`${width} px-4 py-2.5 text-center text-sm font-semibold whitespace-nowrap text-gray-600`}
                      >
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={item.idx}
                      className='border-b border-solid border-gray-300 hover:bg-gray-50'
                    >
                      {layout.map(({ name }) => {
                        const value = item[name];
                        let tableValue = value;

                        if (name === 'viewYn') {
                          return (
                            <td
                              key={name}
                              className='text-center pt-1 align-middle'
                            >
                              <Toggle
                                index={index}
                                id={item.idx}
                                onToggle={onToggle}
                                value={item.viewYn}
                              />
                            </td>
                          );
                        } else if (name === 'type') {
                          tableValue =
                            value === '01'
                              ? '공지'
                              : value === '02'
                              ? '뉴스'
                              : 'Undefined';
                        } else if (name === 'regDt') {
                          tableValue = value.split(' ')[0];
                        } else if (name === 'idx') {
                          return (
                            <td
                              key={name}
                              className='text-center p-2 text-sm text-gray-700 cursor-pointer align-middle'
                              onClick={() =>
                                navigate(`${form}?idx=${item.idx}`)
                              }
                            >
                              {totalElements - (curPages * 10 + index)}
                            </td>
                          );
                        }

                        return (
                          <td
                            key={name}
                            className='text-center p-2 text-sm text-gray-700 cursor-pointer align-middle'
                            onClick={() => navigate(`${form}?idx=${item.idx}`)}
                          >
                            {tableValue}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
              <PageButtonList
                onPaginationPrev={onPaginationPrev}
                onPaginationNext={onPaginationNext}
                curPages={curPages}
                setCurPages={setCurPages}
                totalPages={totalPages}
                onPaginationNumber={onPaginationNumber}
              />
            </div>
          )}
          <div className='p-4' />
        </div>
      </div>
    </div>
  );
};

export default Notice;
