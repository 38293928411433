import FindEmail from './FindEmail';
import { AuthAPI } from '../../../api';
import { useState } from 'react';

const Index = () => {
  const [secretModal, setSecretModal] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState('');
  const handleFindEmailSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get('name');
    const phone = formData.get('phone');

    // 전화번호에 하이픈을 추가하는 함수
    const formatPhoneNumber = (phone) => {
      const cleaned = phone.replace(/\D/g, ''); // 숫자가 아닌 모든 문자를 제거
      let formattedPhone = cleaned;

      if (cleaned.length > 3 && cleaned.length <= 7) {
        formattedPhone = cleaned.replace(/(\d{3})(\d+)/, '$1-$2');
      } else if (cleaned.length > 7) {
        formattedPhone = cleaned.replace(/(\d{3})(\d{4})(\d+)/, '$1-$2-$3');
      }

      return formattedPhone;
    };

    // 전화번호를 하이픈이 포함된 형식으로 변환

    async function findEmailProcess(name, phone) {
      if (name === '' || undefined || phone === '' || undefined) {
        alert('이름과 전화번호를 확인해 주세요');
      } else {
        phone = formatPhoneNumber(phone);

        const result = await AuthAPI.findEmail({
          ownerHp: phone,
          ownerName: name,
        });
        if (result.status === 200) {
          setSecretModal(true);
          setOwnerEmail(result.data.message);
        } else {
          alert('이름과 전화번호를 확인해 주세요');
        }
      }
    }
    findEmailProcess(name, phone);
  };
  return (
    <FindEmail
      onFindEmailSubmit={handleFindEmailSubmit}
      ownerEmail={ownerEmail}
      secretModal={secretModal}
      setSecretModal={setSecretModal}
    />
  );
};

export default Index;
