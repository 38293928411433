import React, { useEffect, useState } from 'react';
import Ask from './Ask';

import { AskAPI } from '../../../../api';
import { PAGE_SIZE } from '../../../../constants/common';

const Index = () => {
  const [asks, setAsks] = useState([]);
  const [curPages, setCurPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const handlePaginationNumber = (e) => {
    setCurPages(e.target.innerText - 1);
  };

  const handlePaginationPrev = (e) => {
    if (curPages > 0) {
      setCurPages(curPages - 1);
    }
  };

  const handlePaginationNext = () => {
    if (curPages < totalPages - 1) {
      setCurPages(curPages + 1);
    } else {
      alert('마지막 페이지 입니다.');
    }
  };

  useEffect(() => {
    async function fetchData(curPages, pageSize) {
      const result = await AskAPI.getAsks({ params: { curPages, pageSize } });
      setAsks(result['data']);
      setTotalPages(result['page']['totalPages']);
      setTotalElements(result['page']['totalElements']);
    }
    fetchData(curPages, PAGE_SIZE);
  }, [curPages]);

  return (
    <Ask
      asks={asks}
      curPages={curPages}
      setCurPages={setCurPages}
      totalPages={totalPages}
      totalElements={totalElements}
      onPaginationNumber={handlePaginationNumber}
      onPaginationPrev={handlePaginationPrev}
      onPaginationNext={handlePaginationNext}
    />
  );
};

export default Index;
