import React, { useEffect, useState } from 'react';
import Post from '../../atoms/Post';
import ArrowRight from '../../../assets/svg/Arrow-right';
import HomeCount from '../../atoms/HomeCount';
import VIDEO from '../../atoms/JTBCPlayer';
import IMG1 from '../../../assets/images/homepage1.png';
import IMG2 from '../../../assets/images/homepage2.png';
import Stats from '../../atoms/Stats';
import { Link } from 'react-router-dom';
import { scrollTop } from '../../../util/scroll';
import Spinner from '../../atoms/Spinner';

const Home = ({ posts }) => {
  const videoId = 'CrG0Ag6KvkM'; // YouTube 비디오 ID
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  return (
    <div className='flex flex-col'>
      {/* Section 1 */}
      {/* <div className='flex flex-col justify-center items-center h-64 w-full relative bg-[#FAE5A6] md:py-6 md:h-120'>
        <VIDEO />
      </div> */}
      <div className=''>
        <VIDEO />
      </div>
      <div className='p-8'>
        {/* Section 2 */}
        <div className='flex flex-col md:mt-24'>
          <Stats />
        </div>

        {/* Section 4 */}
        <div
          id='about'
          className='flex-col w-full md:h-full md:bg-midBg md:bg-right-top-4 bg-75% bg-right-top-2 bg-no-repeat md:pt-36 pt-8'
        >
          <div className='flex flex-col w-auto h-auto md:mx-10 mx-4 md:my-6 md:mb-16'>
            <h2 className='md:flex md:flex-col flex-1 md:text-5xl text-xl font-semibold md:leading-tight '>
              상생하는 세상 꿈꾸는 '선한영향력'에
              <br className='hidden md:inline' /> 동행 하실 분들을 모집합니다!
            </h2>
            <br className='inline md:hidden' />
            <div id='info' className='flex flex-col md:my-8'>
              <p className='md:text-lg text-xs font-normal md:leading-7 leading-relaxed md:font-normal'>
                선한영향력가게는 결식 아동을 자발적으로 지원하는 가게입니다.
                <br className='hidden md:inline' />
                지난 2019년 서울시 마포에 위치한 ‘진짜파스타’ 매장에서 시작한 이
                캠페인은 결식아동들을 위한 따듯한 손길이 모여 ‘선한영향력’이라는
                단체로 만들어졌습니다.
                <br className='hidden md:inline' />
                음식점 뿐만 아니라 아이들에게 도움이 될 수 있는 선한영 향력을
                함께하는 다양한 분야의 점주님들이 함께하고 있습니다.
                <br className='hidden md:inline' />
                세상을 위한 좋은 변화를 위해 함께 하실 분들은 신청해 주세요!
              </p>
            </div>
            <div className='flex mt-6 justify-center '>
              <a
                className='flex md:m-0 md:p-0 border-solid border-2 border-gray-400 text-black md:w-80 w-52 md:gap-3 gap-1 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer rounded-full'
                href='/apply'
              >
                <p className='md:text-lg text-xs md:leading-7 md:font-medium p-2'>
                  선한영향력가게 동행신청
                </p>
                <ArrowRight size={10} />
              </a>
            </div>
          </div>
        </div>

        {/* Section 5 */}
        <div className='relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:h-120 hidden md:flex justify-center items-center'>
          <div className='relative h-4/5 w-96 md:w-2/3 border border-gray-300'>
            <div className='absolute left-0 top-0'>
              <img src={IMG1} className='object-cover w-96 h-96' />
            </div>
            <div className='absolute right-0 bottom-0'>
              <img src={IMG2} className='object-cover w-96 h-96' />
            </div>
          </div>
        </div>
        <div className='md:hidden flex flex-col items-center mt-8'>
          <div className='mb-4'>
            <img src={IMG1} className='object-cover w-72 h-72' />
          </div>
          <div>
            <img src={IMG2} className='object-cover w-72 h-72' />
          </div>
        </div>

        <div
          id='wrapper3'
          className='flex flex-col w-full md:h-full h-[26rem] md:mt-10 mt-8' // padding top 추가
        >
          <div className='flex md:flex-row flex-col w-auto md:h-[35rem] h-[25rem] md:my-20'>
            <div className='md:basis-1/4 basis-11 flex-col md:m-4 m-0'>
              <h2 className='md:text-5xl text-xl md:p-0 p-2 font-semibold'>
                커뮤니티
              </h2>
            </div>
            <div className='flex-1 flex-col md:m-4 md:p-4 p-0 w-full h-full border-solid border-t-2 border-black'>
              <ul className='flex flex-col w-full md:text-2xl text-xs leading-loose'>
                {posts.map((post, i) => {
                  return (
                    <Post
                      key={i}
                      idx={post.idx}
                      type={post.type}
                      title={post.title}
                    />
                  );
                })}
              </ul>
              <div className='flex py-4 justify-start md:p-0 mt-4'>
                {' '}
                {/* 마진 상단 추가 */}
                <Link to='/community/notice' onClick={scrollTop}>
                  <div className='flex md:m-0 md:mt-8 md:ml-5 md:p-0 border-solid border-2 border-gray-300 text-sky-950 md:w-24 w-20 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer rounded-full'>
                    <div className='flex p-2 items-center'>
                      <p className='md:text-lg text-xs md:leading-7 md:font-medium'>
                        더보기
                      </p>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='md:w-6 w-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
