const donationAPI = (API) => ({
  getDonation: () =>
    API.get(`donation`, {
      headers: {
        Web: 'Y',
      },
    }).then((response) => response.data),
});

export default donationAPI;
