import React, { useEffect, useState } from 'react';
import Store from './Store';

import { StoreAPI } from '../../../../api';
import { exportToExcel } from '../../../../util/excel';
import { PAGE_SIZE } from '../../../../constants/common';
import {
  SEARCH_SECTORS,
  SEARCH_SUB_SECTORS,
} from '../../../../constants/sectors';
import { makeClearValue } from '../../../../util/safe';

const Index = () => {
  const [selector, setSeletor] = useState({
    sector: 0,
    subSector: 0,
    selectkey: '',
    selectValue: '',
  });
  const [stores, setStores] = useState([]);
  const [curPages, setCurPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedStores, setSelectedStores] = useState([]);
  const [searchForm, setSearchForm] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [searchCondition, setSearchCondition] = useState({});

  useEffect(() => {}, [selector]);
  const handlePaginationNumber = (e) => {
    setCurPages(e.target.innerText - 1);
  };

  const handlePaginationPrev = (e) => {
    if (curPages > 0) {
      setCurPages(curPages - 1);
    }
  };

  const handlePaginationNext = () => {
    if (curPages < totalPages - 1) {
      setCurPages(curPages + 1);
    } else {
      alert('마지막 페이지 입니다.');
    }
  };

  useEffect(() => {
    async function fetchData(curPages, pageSize) {
      var result;
      if (!isSearch) {
        result = await StoreAPI.getStores({ params: { curPages, pageSize } });
      } else {
        result = await StoreAPI.searchStore({
          params: { curPages, pageSize },
          data: searchCondition,
        });
      }
      setStores(result['data']);
      setTotalPages(result['page']['totalPages']);
      setTotalElements(result['page']['totalElements']);
    }

    fetchData(curPages, PAGE_SIZE);
  }, [curPages]);

  const handleSelectAll = () => {
    if (selectedStores.length === stores.length) {
      setSelectedStores([]);
    } else {
      setSelectedStores(stores);
    }
  };

  const handleSelectStore = (store) => {
    if (selectedStores.includes(store)) {
      setSelectedStores(selectedStores.filter((s) => s !== store));
    } else {
      setSelectedStores([...selectedStores, store]);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const clearValue = makeClearValue(value);
    setSearchForm((prev) => {
      return { ...prev, [name]: clearValue };
    });
  };

  const handleSearch = async () => {
    setIsSearch(true);

    // 유효성 검사: startDate와 endDate가 둘 다 존재해야 함
    if (searchForm.startDate && !searchForm.endDate) {
      alert('마지막 날짜를 선택해주세요.');
      return;
    }
    if (!searchForm.startDate && searchForm.endDate) {
      alert('시작 날짜를 선택해주세요.');
      return;
    }

    try {
      let seletedOption = {};

      const optionName = selector.selectkey;
      const optionValue = selector.selectValue;

      if (optionName && optionValue) {
        seletedOption = { [optionName]: optionValue };
      }
      const params = { curPages: curPages, pageSize: PAGE_SIZE };
      const data = { ...searchForm, ...seletedOption };

      if (Object.keys(data).length === 0) {
        alert('조건을 설정해주세요.');
        return;
      }

      if (JSON.stringify(data) === JSON.stringify({ keyword: '' })) {
        setCurPages(0);
      }

      const result = await StoreAPI.searchStore({
        params: params,
        data: data,
      });

      setSearchCondition(data);
      setStores(result['data']);
      setTotalPages(result['page']['totalPages']);
      setTotalElements(result['page']['totalElements']);
    } catch (error) {
      console.log(error);
      alert('검색 결과가 없습니다.');
    }
  };

  const handleExportToExcel = async (type) => {
    if (isSearch && type === 'search') {
      try {
        const excelData = await StoreAPI.searchResultStore({
          data: searchCondition,
        });
        if (excelData && excelData.length > 0) {
          exportToExcel(excelData);
        } else {
          console.log('No data to export');
          alert('검색 결과가 없습니다.');
        }
      } catch (error) {
        console.log('error', error);
        alert('오류가 발생했습니다.');
      }
      return;
    }

    if (selectedStores.length > 0 && type === 'select') {
      try {
        const excelData = [];

        for (const el of selectedStores) {
          const data = await StoreAPI.getStore({ id: el.idx });
          excelData.push(data);
        }

        if (excelData.length > 0) {
          exportToExcel(excelData);
        } else {
          alert('선택된 항목에 데이터가 없습니다.');
        }
      } catch (error) {
        console.log('error', error);
        alert('선택된 항목을 가져오는 중 오류가 발생했습니다.');
      }
    } else {
      alert('받을 항목을 선택해주세요.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleNotice = ({ target }) => {
    const { name, value, isMain } = target;
    const clearValue = makeClearValue(value);

    setSeletor((prev) => {
      return { ...prev, selectkey: '', selectValue: '' };
    });

    setSeletor((prev) => {
      if (name === 'type') {
        const sector = findSectorIdx('sector', value[0]);
        const subSector = findSectorIdx('subSector', value[1], sector);
        const selectkey = target.value[3];
        const selectValue = target.value[2];
        return {
          ...prev,
          type: value[0],
          type2: value[1],
          sector: sector,
          subSector: subSector,
          selectkey: selectkey,
          selectValue: selectValue,
        };
      }

      return { ...prev, [name]: clearValue };
    });
  };

  const handleChangeSector = (e) => {
    handleNotice({
      target: {
        name: 'sector',
        value: e.id - 1,
        isMain: true,
      },
    });
  };

  const handleToggle = async ({ index, id, enabled }) => {
    if (id === null || id === undefined) {
      id = stores[index].idx;
    }

    try {
      const data = { viewYn: enabled ? 'N' : 'Y' };
      const result = await StoreAPI.updateStore({ id, data });
      console.log('업데이트 성공', result);
    } catch (error) {
      console.log('에러', error);
    }
  };

  const findSectorIdx = (key, value, idx) => {
    if (key === 'sector') {
      return SEARCH_SECTORS.findIndex((sector) => sector.code === value);
    } else if (key === 'subSector') {
      return SEARCH_SUB_SECTORS[idx].findIndex(
        (sector) => sector.value === value
      );
    }
  };

  return (
    <Store
      selector={selector}
      stores={stores}
      curPages={curPages}
      setCurPages={setCurPages}
      totalPages={totalPages}
      totalElements={totalElements}
      onPaginationNumber={handlePaginationNumber}
      onPaginationPrev={handlePaginationPrev}
      onPaginationNext={handlePaginationNext}
      onSelectAll={handleSelectAll} // 전체 선택 핸들러
      onSelectStore={handleSelectStore} // 개별 선택 핸들러
      selectedStores={selectedStores} // 선택된 항목들
      onExportToExcel={handleExportToExcel} // 엑셀 내보내기 핸들러
      onSubmit={handleSubmit}
      onChangeSector={handleChangeSector}
      onNotice={handleNotice}
      onChange={handleChange}
      onToggle={handleToggle}
    />
  );
};

export default Index;
