export const D_DATA = {
  businesses: [
    {
      b_no: "1078614075",
      start_dt: "20020401",
      p_nm: "조주완",
    },
    // // KT
    // {
    //   b_no: "1028142945",
    //   start_dt: "19811210",
    //   p_nm: "김영섭",
    // },
    // // LG전자
    // {
    //   b_no: "1078614075",
    //   start_dt: "20020401",
    //   p_nm: "조주완",
    // },
    // //카카오
    // {
    //   b_no: "1208147521",
    //   start_dt: "19950216",
    //   p_nm: "홍은택",
    // },
  ],
};
