const communityAPI = (API) => ({
  getAsks: (params) =>
    API.get('ask/', {
      params: { page: params.curPages, size: params.pageSize },
    }).then(function (response) {
      const result = response.data;
      const data = result['_embedded']['allAskResourceList'];
      const page = result['page'];
      const links = result['_links'];
      return { data, page, links };
    }),
  getNotices: (params) =>
    API.get('notice/', {
      params: { page: params.curPages, size: params.pageSize },
    }).then(function (response) {
      const result = response.data;
      const data = result['_embedded']['allNoticeResourceList'];
      const page = result['page'];
      const links = result['_links'];
      return { data, page, links };
    }),
  getAsk: (id) =>
    API.get('ask/' + id, {}).then(function (response) {
      return response.data;
    }),
  getNotice: (id) =>
    API.get('notice/' + id, {}).then(function (response) {
      return response.data;
    }),
  createAsk: (params) =>
    API.post('ask/', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(function (response) {
      return response;
    }),
  checkSecretAskPwd: (params) =>
    API.post('ask/pwdcheck', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(function (response) {
      return response.data;
    }),
  getFaqs: (params) =>
    API.get('faq/', {
      params: { page: params.curPages, size: params.pageSize },
    }).then(function (response) {
      const result = response.data;
      const page = result['page'];
      const links = result['_links'];
      if (page.totalElements > 0) {
        const data = result['_embedded']['allFAQResourceList'];
        return { data, page, links };
      } else {
        return result;
      }
    }),
  getFaq: (id) =>
    API.get('faq/' + id, {}).then(function (response) {
      return response.data;
    }),
});

export default communityAPI;
