const SEARCH_SECTORS = [
  { id: 1, code: '00', value: '전체' },
  { id: 2, code: '01', value: '회원' },
  { id: 3, code: '02', value: '입금' },
  { id: 4, code: '03', value: '스티커' },
  { id: 5, code: '04', value: '키트' },
  { id: 6, code: '05', value: '업종' },
];

const SEARCH_SUB_SECTORS = [
  [{ id: 1, code: '00', value: '전체' }],
  [
    { id: 1, code: '01', value: '선택', answer: '', name: 'regularYn' },
    { id: 2, code: '01', value: '정회원', answer: 'Y', name: 'regularYn' },
    { id: 3, code: '01', value: '준회원', answer: 'N', name: 'regularYn' },
  ],
  [
    { id: 1, code: '02', value: '선택', answer: '', name: 'creditYn' },
    { id: 2, code: '02', value: '입금', answer: 'Y', name: 'creditYn' },
    { id: 3, code: '02', value: '미입금', answer: 'N', name: 'creditYn' },
  ],
  [
    { id: 1, code: '03', value: '선택', answer: '', name: 'stickerYn' },
    { id: 2, code: '03', value: '완료', answer: 'Y', name: 'stickerYn' },
    { id: 3, code: '03', value: '대기', answer: 'N', name: 'stickerYn' },
  ],
  [
    { id: 1, code: '04', value: '선택', answer: '', name: 'kitYn' },
    { id: 2, code: '04', value: '완료', answer: 'Y', name: 'kitYn' },
    { id: 3, code: '04', value: '대기', answer: 'N', name: 'kitYn' },
  ],
  [
    { id: 1, code: '05', value: '선택', answer: '' },
    { id: 2, code: '05', value: '식음료', answer: '01', name: 'type' },
    { id: 3, code: '05', value: '교육', answer: '02', name: 'type' },
    { id: 4, code: '05', value: '생활(서비스)', answer: '03', name: 'type' },
    { id: 5, code: '05', value: '기타', answer: '99', name: 'type' },
  ],
];

const SECTORS = [
  { id: 1, code: '00', value: '대분류' },
  { id: 2, code: '01', value: '식음료' },
  { id: 3, code: '02', value: '교육' },
  { id: 4, code: '03', value: '생활(서비스)' },
  { id: 5, code: '99', value: '기타' },
];

const SUB_SECTORS = [
  [{ id: 1, code: '00', value: '중분류' }],
  [
    { id: 1, code: '01', value: '선택' },
    { id: 2, code: '01', value: '한식' },
    { id: 3, code: '01', value: '양식' },
    { id: 4, code: '01', value: '중식' },
    { id: 5, code: '01', value: '일식' },
    { id: 6, code: '01', value: '일반음식' },
    { id: 7, code: '01', value: '패스트푸드' },
    { id: 8, code: '01', value: '커피전문점' },
    { id: 9, code: '01', value: '제과점' },
    { id: 10, code: '01', value: '유흥주점' },
    { id: 11, code: '01', value: '단란주점' },
    { id: 12, code: '01', value: '맥주홀' },
  ],
  [
    { id: 1, code: '02', value: '선택' },
    { id: 2, code: '02', value: '체험(공방)' },
    { id: 3, code: '02', value: '직업전문학원' },
    { id: 4, code: '02', value: '스터디카페' },
    { id: 5, code: '02', value: '운동' },
  ],
  [
    { id: 1, code: '03', value: '선택' },
    { id: 2, code: '03', value: '안경' },
    { id: 3, code: '03', value: '미용' },
    { id: 4, code: '03', value: '꽃' },
    { id: 5, code: '03', value: '숙박/여행' },
    { id: 6, code: '03', value: '기계' },
    { id: 7, code: '03', value: '의료' },
    { id: 8, code: '03', value: '스튜디오' },
  ],
  [
    { id: 1, code: '99', value: '선택' },
    { id: 2, code: '99', value: '슈퍼마켓' },
    { id: 3, code: '99', value: '정육점' },
    { id: 4, code: '99', value: '식품잡화' },
    { id: 5, code: '99', value: '편의점' },
  ],
];

const NOTICE_TYPE = [
  { id: 0, code: '00', value: '선택' },
  { id: 1, code: '01', value: '공지' },
  { id: 2, code: '02', value: '뉴스' },
];

const VIEW_TYPE = [
  { id: 1, code: 'Y', value: '공개' },
  { id: 2, code: 'N', value: '비공개' },
];

const ASK_TYPE = [
  { id: 0, code: '00', value: '선택' },
  { id: 1, code: '01', value: '회원정보' },
  { id: 2, code: '02', value: '선한가게신청' },
  { id: 3, code: '03', value: '후원' },
  { id: 4, code: '04', value: '기타' },
  { id: 5, code: '05', value: '학생' },
];

export {
  SEARCH_SECTORS,
  SEARCH_SUB_SECTORS,
  SECTORS,
  SUB_SECTORS,
  NOTICE_TYPE,
  VIEW_TYPE,
  ASK_TYPE,
};
