import AskForm from "./AskForm";
import React, { useState, useRef } from "react";
import { CommunityAPI } from "../../../api";
import { makeClearValue } from "../../../util/safe";

const Index = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSecret, setSecret] = useState(false);
  const [emailNotify, setEmailNotify] = useState(false);
  const [contentsLength, setContentsLength] = useState(0);
  const [fileName, setFileName] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const secretRef = useRef("");
  const emailRef = useRef("");

  const handleSecretChange = () => {
    setSecret(!isSecret);

    if (!isSecret) {
      secretRef.current.focus();
    }
  };
  const handleEmailNotifyChange = () => {
    setEmailNotify(!emailNotify);

    if (!emailNotify) {
      emailRef.current.focus();
    }
  };
  const handleEmailChange = (e) => {
    setEmailValid(
      makeClearValue(String(e.target.value))
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  const handleContentsChange = (e) => {
    setContentsLength(e.target.value.length);
  };
  const handleFileChange = (e) => {
    setFileName("업로드 한 파일 : " + makeClearValue(e.target.value));
  };
  const handleAskSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    const secretYn = formData.getAll("secretYn");
    const notiYn = formData.getAll("notiYn");

    if (secretYn[0] === "true") {
      data.secretYn = "Y";
    } else {
      data.secretYn = "N";
      data.secretPwd = "";
    }
    if (notiYn[0] === "true") {
      data.notiYn = "Y";
    } else {
      data.notiYn = "N";
      data.notiEmail = "";
    }
    async function createAsk(params) {
      const result = await CommunityAPI.createAsk(params);
      if (result.status === 200) {
        alert("1:1 문의를 작성하였습니다.");
        window.location.replace("/community/ask");
      }
    }
    createAsk(data);
  };
  return (
    <AskForm
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      isSecret={isSecret}
      secretRef={secretRef}
      contentsLength={contentsLength}
      fileName={fileName}
      emailNotify={emailNotify}
      emailRef={emailRef}
      isEmailValid={isEmailValid}
      onAskSubmit={handleAskSubmit}
      onSecretChange={handleSecretChange}
      onFileChange={handleFileChange}
      onContentsChange={handleContentsChange}
      onEmailChange={handleEmailChange}
      onEmailNotifyChange={handleEmailNotifyChange}
    />
  );
};

export default Index;
