import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ADMIN_NAVIGATE_LIST } from '../../../../constants/admin';

const AdminNavibar = () => {
  const location = useLocation();
  const isActive = (path, query) =>
    location.pathname.includes(path) && location.search.includes(query);

  const postCategory = [
    { title: '팝업 띄우기', url: '/admin/post?type=popup' },
    { title: '기부금 설정', url: '/admin/post?type=donation' },
  ];

  return (
    <div id="nav-bar" className="p-4 hidden md:block sm:w-48">
      <div className="p-2">
        <Link to="/admin/storeList">
          <button
            className={`whitespace-nowrap font-semibold ${
              isActive('storeList', '') ? 'text-[#365ca5]' : ''
            }`}
          >
            선한영향력가게 관리
          </button>
        </Link>
      </div>

      <div className="p-2">
        <Link to="/admin/noticeList">
          <button
            className={`mt-2 mb-1 font-semibold ${
              isActive('noticeList', '') ? 'text-[#365ca5]' : ''
            }`}
          >
            커뮤니티 관리
          </button>
        </Link>
        {ADMIN_NAVIGATE_LIST.map(({ title, url }) => (
          <Link to={url} key={url}>
            <p
              className={`m-1 pl-2 text-sm font-normal cursor-pointer ${
                isActive(url, '')
                  ? 'text-[#365ca5] font-semibold'
                  : 'text-gray-500'
              }`}
            >
              - {title}
            </p>
          </Link>
        ))}
      </div>

      <div className="p-2">
        <Link to="/admin/post?type=popup">
          <button
            className={`mt-2 mb-1 font-semibold ${
              isActive('post', '') ? 'text-[#365ca5]' : ''
            }`}
          >
            홈페이지 관리
          </button>
        </Link>
        {postCategory.map(({ title, url }) => {
          const query = new URLSearchParams(url.split('?')[1]).get('type');
          return (
            <Link to={url} key={url}>
              <p
                className={`m-2 text-sm font-normal cursor-pointer ${
                  isActive('post', `type=${query}`)
                    ? 'text-[#365ca5] font-semibold'
                    : 'text-gray-500'
                }`}
              >
                - {title}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default AdminNavibar;
