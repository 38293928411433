import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminHeader from '../../../blocks/Admin/AdminHeader';
import AdminNavibar from '../../../blocks/Admin/AdminNavibar';
import AdminTitle from '../../../blocks/Admin/AdminTitle';

import DonationForm from '../../../blocks/Admin/DonationForm';
import PopupForm from '../../../blocks/Admin/PopupForm';

const Post = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  return (
    <div id='wrapper' className='flex flex-col bg-slate-100'>
      <AdminHeader />
      <div
        id='container'
        className='flex justify-between container mx-auto bg-slate-100'
      >
        <AdminNavibar />
        <div
          id='white-box'
          className='flex flex-col w-full h-screen bg-white p-4'
        >
          <AdminTitle hasAddButton={false} title={'홈페이지 관리'} />
          <div className='h-auto'>
            {type === 'donation' && <DonationForm />}
            {type === 'popup' && <PopupForm />}
            {/* <div className="pb-16" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
