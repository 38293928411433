// 메소드가 담긴 객체를 반환
const requestAPI = (API) => ({
  getPopupNotices: () =>
    API.get('notice/getpopup', {
      headers: {
        Access_Token: '1111111',
        Web: 'Y',
      },
    })
      .then(function (response) {
        return response; // 응답 데이터만 반환
      })
      .catch(function (error) {
        return error.response;
      }),
});

export default requestAPI;
