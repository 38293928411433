import React, { useEffect, useState } from "react";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
import "react-quill/dist/quill.snow.css";

import RatioSimpleInlineList2 from "../../../atoms/RatioSimpleInlineList2";
import SelectSimpleCustom2 from "../../../atoms/SelectSimpleCustom2";

import Th from "../../../atoms/Th";
import Td from "../../../atoms/Td";
import HoverButton from "../../../atoms/HoverButton";
import Button from "../../../atoms/Button";

import { NOTICE_TYPE, VIEW_TYPE } from "../../../../constants/sectors";

const NoticeForm = ({
  data,
  onChange,
  onFileChange,
  onDeleteFileChange,
  onDelete,
  files,
  quillElement,
  quillInstance,
  onSubmit,
  onCancle,
}) => {
  const { title, contents, type, viewYn } = data;

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }],
      ["clean"],
    ],
    ImageResize: {
      parchment: {
        image: {
          attributes: ["width", "height", "align"],
        },
      },
    },
  };

  Quill.register("modules/ImageResize", ImageResize);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    quillInstance.current = new Quill(quillElement.current, {
      theme: "snow",
      placeholder: "내용을 작성해주세요.",
      modules: modules,
    });
    setInitialLoad(false);
  }, []);

  // Quill 이미지 선택 시, foucs 앞으로 이동
  useEffect(() => {
    if (!initialLoad && quillInstance.current && contents) {
      const quill = quillInstance.current;
      const range = quill.getSelection();

      if (range) {
        quill.setSelection(range);
      }
    }
  }, [contents, initialLoad]);

  // Quill Contents 존재 시, 초기화
  useEffect(() => {
    try {
      if (contents) {
        const quill = quillInstance.current;
        const delta = quill.clipboard.convert(contents);
        quill.setContents(delta, "silent");
      }
    } catch (error) {
      console.log("quill 초기화", error);
    }
  }, []);

  return (
    <div className="overflow-x-auto h-screen">
      <table className="table-auto w-full border-collapse border-t-2 border-solid">
        <colgroup>
          <col className="w-[20%]" />
          <col className="w-[30%]" />
          <col className="w-[20%]" />
          <col className="w-[30%]" />
        </colgroup>
        <tbody>
          <tr>
            <Th>
              구분<span className="text-red-400"> *</span>
            </Th>
            <Td>
              <SelectSimpleCustom2
                defaultValue={type}
                options={NOTICE_TYPE}
                handleChange={onChange}
              />
            </Td>
            <Th>
              게재 여부<span className="text-red-400"> *</span>
            </Th>
            <Td>
              <RatioSimpleInlineList2
                name={"viewYn"}
                defaultValue={viewYn}
                options={VIEW_TYPE}
                handleChange={onChange}
              />
            </Td>
          </tr>
          <tr>
            <Th>
              제목<span className="text-red-400"> *</span>
            </Th>
            <Td colSpan={4}>
              <TwInput value={title} onChange={onChange} />
            </Td>
          </tr>
          <tr>
            <Th>
              내용<span className="text-red-400"> *</span>
            </Th>
            <Td colSpan={4}>
              <div id="quill-element" ref={quillElement} />
            </Td>
          </tr>
          <tr>
            <Th>파일</Th>
            <Td colSpan={4}>
              <div className="relative inline-block">
                <input
                  type="file"
                  id="fileUpload"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  onChange={onFileChange}
                  multiple
                />
                <HoverButton
                  title={"파일첨부"}
                  w={"24"}
                  h={"8 md:h-10"}
                  textSize={"xs md:text-sm"}
                />
              </div>
              <div className="mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center text-sm text-gray-700"
                  >
                    <a
                      href={"file/notice/" + file.realName}
                      download={file.oriName || file.name}
                      className="hover:underline"
                    >
                      {file.oriName || file.name}
                    </a>
                    <button
                      onClick={() => {
                        console.log(index);
                        onDeleteFileChange(index);
                      }}
                      className="pl-2 text-red-500"
                    >
                      삭제
                    </button>
                  </div>
                ))}
              </div>
            </Td>
          </tr>
        </tbody>
      </table>

      <div className="flex justify-between py-4">
        <Button title={"선택항목 엑셀받기"} className={"opacity-0"} />
        <div className="space-x-2">
          <Button
            title={"저장"}
            className={"px-12 py-2 rounded-none"}
            onClick={onSubmit}
          />
          <Button
            title={"취소"}
            className={"px-12 py-2 rounded-none bg-slate-500"}
            onClick={onCancle}
          />
        </div>
        <Button
          title={"삭제"}
          className={"px-12 py-2 rounded-none bg-slate-500"}
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

const TwInput = ({ value, placeholder, onChange }) => {
  return (
    <div className="w-full">
      <input
        type="text"
        name="title"
        id="title"
        value={value}
        onChange={onChange}
        className="w-full block border-solid border-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
        placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
        placeholder={placeholder}
      />
    </div>
  );
};

export default NoticeForm;
