import { useEffect, useLayoutEffect, useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectSimpleCustom({
  options,
  isSub,
  onChangeSector,
  onNotice,
  value,
}) {
  const [selected, setSelected] = useState(
    options[value] ? options[value] : options[0]
  );
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    if (isSub && options.length > 0 && isFirst == false) {
      setSelected(options[0]);
    } else {
      setIsFirst(false);
    }
  }, [options]); // opt

  const handleSelect = (e) => {
    const { id, code, value, answer, name } = e;

    setSelected(e);
    if (!isSub) {
      onChangeSector(e);
    } else {
      const data = {
        target: {
          name: "type",
          value: [code, value, answer, name],
        },
      };
      onNotice(data);
    }
  };

  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }) => (
        <>
          <div className="relative border-solid border border-gray-200">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {options.map(({ id, code, value, answer = "", name = "" }) => (
                <ListboxOption
                  key={id}
                  className={({ focus }) =>
                    classNames(
                      focus ? "bg-sky-500 text-white" : "",
                      !focus ? "text-gray-900" : "",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value={{ id, code, value, answer, name }}
                >
                  {({ selected, focus }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {value}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            focus ? "text-white" : "text-sky-500",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}
