const ApplyInputContent = ({ children }) => {
  return (
    <>
      <div className="flex flex-col md:w-10/12 w-3/4 p-4 justify-center">
        {children}
      </div>
    </>
  );
};

export default ApplyInputContent;
