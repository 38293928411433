import React, { useEffect, useRef, useState } from "react";

import IMG from "../../../assets/images/bizinfo_banner.png";
import NewCardNews2 from "../../atoms/NewCardNews2";
import HomeCount2 from "../../atoms/HomeCount2";
import ApplyPageHeader from "../../atoms/ApplyPageHeader";

const BizInfo = ({ showModal, onModal, onLink }) => {
  return (
    <>
      <div className="flex flex-col mb-64 p-8">
        <ApplyPageHeader IMG={IMG} />
        <HomeCount2 />
        <NewCardNews2 {...{ showModal }} onModal={onModal} onLink={onLink} />
      </div>
    </>
  );
};

export default BizInfo;
