import React, { useEffect, useState } from "react";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
import "react-quill/dist/quill.snow.css";

import Th from "../../../atoms/Th";
import Td from "../../../atoms/Td";
import HoverButton from "../../../atoms/HoverButton";
import Button from "../../../atoms/Button";

const AskForm = ({
  data,
  onChange,
  onFileChange,
  onDeleteFileChange,
  onDelete,
  files,
  quillElement,
  quillInstance,
  onSubmit,
  onCancle,
}) => {
  const {
    answer,
    answerDt,
    answerYn,
    contents,
    notiEmail,
    notiYn,
    regDt,
    secretYn,
    title,
    type,
    fileName,
    filePath,
  } = data;

  const askType = ["회원정보", "선한가게신청", "후원", "기타", "학생"];

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }],
      ["clean"],
    ],
    ImageResize: {
      parchment: {
        image: {
          attributes: ["width", "height", "align"],
        },
      },
    },
  };

  Quill.register("modules/ImageResize", ImageResize);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    quillInstance.current = new Quill(quillElement.current, {
      theme: "snow",
      placeholder: "내용을 작성해주세요.",
      modules: modules,
    });
    setInitialLoad(false);
  }, []);

  // Quill 이미지 선택 시, foucs 앞으로 이동
  useEffect(() => {
    if (!initialLoad && quillInstance.current && answer) {
      const quill = quillInstance.current;
      const range = quill.getSelection();

      if (range) {
        quill.setSelection(range);
      }
    }
  }, [answer, initialLoad]);

  // Quill Contents 존재 시, 초기화
  useEffect(() => {
    try {
      if (answer) {
        const quill = quillInstance.current;
        const formattedAnswer = answer.replace(/\n/g, "<br>"); // 줄바꿈을 <br> 태그로 변환
        const delta = quill.clipboard.convert(formattedAnswer);
        quill.setContents(delta, "silent");
      }
    } catch (error) {}
  }, []);

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full border-collapse border-t-2 border-solid">
        <colgroup>
          <col className="w-[15%]" />
          <col className="w-[20%]" />
          <col className="w-[15%]" />
          <col className="w-[25%]" />
          <col className="w-[15%]" />
          <col className="w-[10%]" />
        </colgroup>
        <tbody>
          <tr>
            <Th>문의구분</Th>
            <Td>{askType[type - 1]}</Td>
            <Th>수신 이메일</Th>
            <Td>{notiEmail}</Td>
            <Th>이메일 수신여부</Th>
            <Td>{notiYn}</Td>
          </tr>
          <tr>
            <Th>제목</Th>
            <Td colSpan={3}>
              <div className="p-2">{title}</div>
            </Td>
            <Th>비밀글 여부</Th>
            <Td>{secretYn}</Td>
          </tr>
          <tr>
            <Th>첨부파일</Th>
            <Td colSpan={3}>
              <a
                href={"/file/ask/" + filePath}
                download={fileName}
                className="hover:underline"
              >
                {fileName}
              </a>
            </Td>
            <Th>등록일</Th>
            <Td>{regDt}</Td>
          </tr>
          <tr>
            <Th>내용</Th>
            <Td colSpan={5}>
              <div className="p-2 whitespace-pre-wrap">{contents}</div>
            </Td>
          </tr>
        </tbody>
      </table>

      <table className="table-auto w-full border-collapse border-t-2 border-solid mt-4">
        <colgroup>
          <col className="w-[15%]" />
          <col className="w-[85%]" />
        </colgroup>
        <tbody>
          <tr className="border-solid border-t-2">
            <Th>답변일</Th>
            <Td colSpan={1}>{answerDt}</Td>
          </tr>
          <tr>
            <Th>답변</Th>
            <Td colSpan={4}>
              <div id="quill-element" ref={quillElement} />
            </Td>
          </tr>
          <tr>
            <Th>파일</Th>
            <Td colSpan={4}>
              <div className="relative inline-block">
                <input
                  type="file"
                  id="fileUpload"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  onChange={onFileChange}
                  multiple
                />
                <HoverButton
                  title={"파일첨부"}
                  w={"24"}
                  h={"8 md:h-10"}
                  textSize={"xs md:text-sm"}
                />
              </div>
              <div className="mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center text-sm text-gray-700"
                  >
                    <a
                      href={"file/notice" + file.realName}
                      download={file.oriName || file.name}
                      className="hover:underline"
                    >
                      {file.oriName || file.name}
                    </a>
                    <button
                      onClick={() => {
                        onDeleteFileChange(index);
                      }}
                      className="pl-2 text-red-500"
                    >
                      삭제
                    </button>
                  </div>
                ))}
              </div>
            </Td>
          </tr>
        </tbody>
      </table>

      <div className="flex justify-between py-4">
        <Button title={"선택항목 엑셀받기"} className={"opacity-0"} />
        <div className="space-x-2">
          <Button
            title={"저장"}
            className={"px-12 py-2 rounded-none"}
            onClick={onSubmit}
          />
          <Button
            title={"취소"}
            className={"px-12 py-2 rounded-none bg-slate-500"}
            onClick={onCancle}
          />
        </div>
        <Button
          title={"삭제"}
          className={"px-12 py-2 rounded-none bg-slate-500"}
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

export default AskForm;
