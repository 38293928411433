import { CameraIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';

const Menu = ({
  menu,
  onMenuChange,
  onDeleteMenu,
  onFileSelect,
  onOnlyNumber,
}) => {
  const { id, idx, menu_Name, offer_Price, default_Price, free_Yn, menu_Img } =
    menu;
  const [imageURL, setImageURL] = useState(menu.img_url);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (menu_Img && menu_Img[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageURL(e.target.result);
      };
      reader.readAsDataURL(menu_Img[0]);
    }
  }, [menu_Img]);

  const handleImgFile = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="flex flex-col md:flex-row mt-6 rounded-md border-solid border border-sky-950 p-4 gap-4">
        {/* 모바일 레이아웃에서 삭제 및 무료 제공 위치 조정 */}
        <div className="flex md:hidden flex-row w-full justify-between items-center mb-2">
          <div className="flex items-center">
            <p className="text-xs font-medium whitespace-nowrap mr-2">
              무료 제공
            </p>
            <input
              id={id}
              checked={free_Yn === 'Y'}
              name="free_Yn"
              type="checkbox"
              className="w-5 h-5 border-solid border-gray-500 text-sky-950"
              onChange={onMenuChange}
            />
          </div>
          <div
            data-id={id}
            data-idx={idx}
            className="text-xs underline underline-offset-2 font-medium hover:cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onDeleteMenu(e);
            }}
          >
            삭제
          </div>
        </div>

        <div className="flex flex-row md:w-auto w-full md:px-4 px-1 md:gap-4 gap-2 justify-start items-center">
          <a
            name="menu_Img"
            value={imageURL}
            onClick={handleImgFile}
            className="flex border-solid border border-sky-950 md:w-28 md:h-28 w-16 h-16 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer rounded-md"
          >
            {imageURL ? (
              <img
                src={imageURL}
                alt="Uploaded"
                className="w-full h-full object-cover rounded-md"
              ></img>
            ) : (
              <CameraIcon className="w-8 h-8 md:w-16 md:h-16"></CameraIcon>
            )}
          </a>
          <input
            id={id}
            name="menu_Img"
            type="file"
            multiple="multiple"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onFileSelect}
          />
          <div className="flex flex-col md:h-full justify-center w-full md:w-auto">
            <label className="flex flex-col w-full justify-center">
              <input
                id={id}
                name="menu_Name"
                type="text"
                className="peer w-full h-full items-center text-xs md:text-base border-b border-gray-500 text-sky-950"
                placeholder="품목 이름"
                onChange={onMenuChange}
                value={menu_Name}
              />
              <span className="invisible peer-invalid:visible text-pink-600 text-xs md:text-base">
                품목을 입력해주세요.
              </span>
            </label>
            <div className="flex flex-row gap-2 w-full">
              <label className="flex flex-col w-1/2">
                <input
                  id={id}
                  name="default_Price"
                  type="text"
                  className="peer w-full h-full items-center text-xs md:text-base border-b border-gray-500 text-sky-950"
                  pattern="\d*"
                  placeholder="기존 가격"
                  onChange={onMenuChange}
                  value={default_Price}
                  onKeyDown={onOnlyNumber}
                />
                <span className="invisible peer-invalid:visible text-pink-600 text-xs md:text-sm">
                  숫자만 입력 가능합니다.
                </span>
              </label>
              <label className="flex flex-col w-1/2">
                <input
                  id={id}
                  name="offer_Price"
                  type="text"
                  className="peer w-full h-full items-center text-xs md:text-base border-b border-gray-500 text-sky-950"
                  pattern="\d*"
                  placeholder="제공 가격"
                  onChange={onMenuChange}
                  value={free_Yn === 'Y' ? '0' : offer_Price}
                  disabled={free_Yn === 'Y'}
                />
                <span className="invisible peer-invalid:visible text-pink-600 text-xs md:text-sm">
                  숫자만 입력 가능합니다.
                </span>
              </label>
            </div>
          </div>

          {/* 데스크탑에서 삭제 및 무료 제공 원래 위치 유지 */}
          <div className="hidden md:flex flex-col md:w-auto items-center order-none md:order-3 gap-2">
            <p className="md:text-sm text-xs font-medium whitespace-nowrap">
              무료 제공
            </p>
            <input
              id={id}
              checked={free_Yn === 'Y'}
              name="free_Yn"
              type="checkbox"
              className="w-5 h-5 border-solid border-gray-500 text-sky-950"
              onChange={onMenuChange}
            />
            <div
              data-id={id}
              data-idx={idx}
              className="text-xs underline underline-offset-2 font-medium hover:cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteMenu(e);
              }}
            >
              삭제
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
