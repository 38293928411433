import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApplyPageHeader from '../../atoms/ApplyPageHeader';
import BANNER from '../../../assets/images/community_notice_banner.png';
import LockIcon from '../../atoms/LockIcon';

// Ask 컴포넌트
const Faq = ({
  faqs,
  curPages,
  totalPages,
  dropdownOpen,
  setDropdownOpen,
  onPaginationNumber,
  onPaginationPrev,
  onPaginationNext,
}) => {
  return (
    <section className="mx-auto my-0 text-center p-8 align-baseline">
      <ApplyPageHeader IMG={BANNER} />
      <div className="flex flex-col items-center justify-center p-0 align-baseline">
        <div className="flex flex-col w-full h-auto md:mx-10 mx-4 md:mb-0 md:mt-12 items-start">
          <h2 className="md:flex md:flex-col flex-1 md:text-5xl text-xl font-semibold md:leading-tight md:mt-0 mt-4">
            커뮤니티
          </h2>
          <div id="info" className="flex flex-col md:my-8">
            <p className="md:text-lg text-xs font-normal md:leading-7 leading-relaxed md:font-normal">
              선한영향력가게의 다양한 정보를 공유하고 알려 드립니다.
            </p>
          </div>
        </div>
        <div className="box-border block font-bold w-full">
          <div className="flex justify-center md:justify-end md:mt-4 mt-16 mb-4  disabled:true">
            <a className="flex items-center justify-center text-white bg-white border-2 border-white rounded-lg transition duration-300 px-4 py-2 md:px-6 md:py-3 md:text-lg text-sm md:leading-7 md:font-medium border-solid">
              <p className="mb-0 ">남일정준예성</p>
            </a>
          </div>
          <ul className="xl:flex xl:list-none hidden">
            <Link
              className="text-xl w-1/6 bg-gray-100 h-20 flex justify-center items-center hover:underline hover:decoration-solid hover:text-sky-800 rounded-t-2xl border-solid border-b-2 border-sky-800"
              to="/community/notice"
            >
              <li>공지</li>
            </Link>
            <Link
              className="text-xl w-1/6 bg-gray-100 h-20 flex justify-center items-center hover:underline hover:decoration-solid hover:text-sky-800 rounded-t-2xl border-solid border-b-2 border-sky-800"
              to="/community/ask"
            >
              <li>1:1문의</li>
            </Link>
            <Link
              className="text-sky-800 text-xl w-1/6 bg-white h-20 flex justify-center items-center hover:underline hover:decoration-solid rounded-t-2xl border-solid border-x-2 border-t-2"
              to="/community/faq"
            >
              <li>FAQ</li>
            </Link>

            <div className="flex flex-grow border-b-2 border-solid border-sky-800"></div>
          </ul>
          <div className="xl:hidden flex cursor-pointer relative">
            <div
              className="flex items-center justify-between font-medium text-xl w-full border text-sky-800 border-solid border-sky-800 p-4"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              FAQ
              <svg
                className="-mr-1 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            {dropdownOpen && (
              <div>
                <Link
                  to="/community/notice"
                  className="absolute left-0 align-center items-center p-4 font-medium text-xl text-sky-800 flex w-full translate-y-14 bg-white border border-solid border-sky-800 mt-1"
                >
                  공지
                </Link>
                <Link
                  to="/community/ask"
                  className="absolute left-0 align-center items-center p-4 font-medium text-xl text-sky-800 flex w-full translate-y-28 bg-white border border-solid border-sky-800 mt-1"
                >
                  1:1문의
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="font-sans w-full">
          <div className="mb-10 border-b-black border-solid">
            <ul>
              {faqs?.map((item) =>
                item.viewYn == 'Y' ? (
                  <li
                    key={item.id}
                    className="xl:flex xl:text-center w-full text-left items-center py-6 border-b border-b-gray-300 border-solid"
                  >
                    <div className="flex xl:mb-0 mb-4 w-60">
                      <span className="flex rounded-md bg-blue-50 w-24 h-12 text-sm font-semibold text-blue-900 text-center items-center justify-center">
                        FAQ
                      </span>
                    </div>
                    <div className="xl:mb-0 mb-4 flex-grow text-left font-semibold text-lg transition hover:text-sky-700">
                      <Link to={'/community/faq/view/' + item.idx}>
                        {item.title}
                      </Link>
                    </div>
                    <div className="w-60 text-gray-500">
                      {item.regDt.split(' ')[0]}
                    </div>
                  </li>
                ) : (
                  <li
                    key={item.id}
                    className="xl:flex xl:text-center w-full text-left items-center py-6 border-b border-b-gray-300 border-solid"
                  >
                    <div className="flex xl:mb-0 mb-4 w-60">
                      <span className="flex rounded-md bg-blue-50 w-24 h-12 text-sm font-semibold text-blue-900 text-center items-center justify-center">
                        FAQ
                      </span>
                    </div>
                    <div className="xl:mb-0 mb-4 flex-grow text-left font-semibold text-lg transition hover:text-sky-700">
                      <Link>{item.title}</Link>
                    </div>
                    <div className="w-fit">
                      <LockIcon />
                    </div>
                    <div className="w-60 text-gray-500">
                      {item.regDt.split(' ')[0]}
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <div className="xl:block flex text-center text-xl mb-48">
          <div className="xl:inline-block inline-flex">
            <button
              className="float-left inline-flex justify-center items-center w-12 h-12"
              onClick={(e) => onPaginationPrev(e)}
            >
              <ChevronLeftIcon className="text-gray-500">prev</ChevronLeftIcon>
            </button>
            <ul className="xl:float-left xl:mx-10 list-none">
              {[...Array(totalPages).keys()]
                .map((i) => i + 1)
                .slice(
                  parseInt(curPages / 10) * 10,
                  parseInt(curPages / 10) * 10 + 10
                )
                ?.map((idx) => (
                  <li className="xl:float-left inline-flex" key={idx}>
                    <div
                      onClick={(e) => onPaginationNumber(e)}
                      className={
                        idx === curPages + 1
                          ? 'bg-sky-800 text-white inline-flex justify-center items-center w-12 h-12 hover:cursor-pointer'
                          : 'text-gray-500 inline-flex justify-center items-center w-12 h-12 hover:cursor-pointer'
                      }
                    >
                      {idx}
                    </div>
                  </li>
                ))}
            </ul>
            <button
              className="float-left inline-flex justify-center items-center w-12 h-12"
              onClick={(e) => onPaginationNext(e)}
            >
              <ChevronRightIcon className="text-gray-500">
                next
              </ChevronRightIcon>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
