import React, { useEffect, useState } from 'react';

const RatioSimpleInlineList = ({
  name,
  defaultValue = 'Y',
  options,
  handleChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleInternalChange = (e) => {
    const { value } = e.target;
    const selectedItem = options.find((item) => item.id.toString() === value);
    if (selectedItem) {
      const selectedCode = selectedItem.code;
      setSelectedValue(selectedCode);
      handleChange({ target: { name, value: selectedCode } });
    }
  };

  return (
    <div className="space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
      {options.map((notificationMethod) => (
        <div key={notificationMethod.id} className="flex items-center">
          <input
            id={notificationMethod.id}
            name={name}
            type="radio"
            value={notificationMethod.id}
            checked={notificationMethod.code === selectedValue}
            onChange={handleInternalChange}
            className="outline border border-solid box-shadow h-4 w-4 border-gray-300 text-sky-800 focus:ring-sky-800"
          />
          <label
            htmlFor={notificationMethod.id}
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            {notificationMethod.value}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RatioSimpleInlineList;
