import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const NoticeView = ({
  dropdownOpen,
  setDropdownOpen,
  noticeContents,
  navigate,
  files,
  onClickImgLink,
}) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <section className='mx-auto my-0 text-center p-0 align-baseline'>
      <div className='flex flex-col items-center justify-center p-0 align-baseline'>
        <div className='box-border w-full block py-20 px-0 font-sans align-baseline'>
          <h2 className='box-border block text-4xl tracking-tighter font-black mb-2 ms-0 me-0 m-0 p-0 underline-yellow'>
            커뮤니티
          </h2>
        </div>
        <div className='box-border block pb-10 font-sans w-3/4'>
          <ul className='xl:flex xl:list-none hidden'>
            <Link
              to='/community/notice'
              className='text-white font-medium text-xl flex-grow w-1/2 bg-sky-800 h-20 flex justify-center items-center hover:underline hover:decoration-solid'
            >
              <li className=''>공지사항</li>
            </Link>
            <Link
              to='/community/ask'
              className='font-medium text-xl flex-grow w-1/2 border border-solid border-r-0 bg-gray-100 h-20 flex justify-center items-center hover:underline hover:decoration-solid hover:text-sky-800'
            >
              <li className=''>1:1문의</li>
            </Link>
            <Link
              to='/community/faq'
              className='font-medium text-xl flex-grow w-1/2 border border-solid bg-gray-100 h-20 flex justify-center items-center hover:underline hover:decoration-solid hover:text-sky-800'
            >
              <li className=''>FAQ</li>
            </Link>
          </ul>
          <div className='xl:hidden flex cursor-pointer relative'>
            <div
              className='flex align-center items-center justify-between font-medium text-xl w-full border text-sky-800 border-solid border-sky-800 p-4 '
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              공지
              <svg
                class='-mr-1 h-5 w-5 text-gray-400'
                viewBox='0 0 20 20'
                fill='currentColor'
                aria-hidden='true'
              >
                <path
                  fill-rule='evenodd'
                  d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                  clip-rule='evenodd'
                />
              </svg>
            </div>
            {dropdownOpen && (
              <div>
                <Link
                  to='/community/ask'
                  className='absolute left-0 align-center items-center p-4 font-medium text-xl text-sky-800 flex w-full translate-y-14 bg-white border border-solid border-sky-800 mt-1'
                >
                  1:1문의
                </Link>
                <Link
                  to='/community/faq'
                  className='absolute left-0 align-center items-center p-4 font-medium text-xl text-sky-800 flex w-full translate-y-28 bg-white border border-solid border-sky-800 mt-1'
                >
                  FAQ
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className='py-10 font-sans w-3/4'>
          <div className='mb-10 border-t border-b border-t-black border-b-black border-solid'>
            <div className='xl:flex-row flex-col flex w-full text-center items-center py-6 border-b border-b-gray-300 border-solid h-28'>
              <h2 className='flex flex-grow float-left text-2xl font-bold tracking-tighter'>
                {noticeContents.title}
              </h2>
              <div className='flex float-right text-lg text-gray-500 font-medium tracking-tighter'>
                {(noticeContents.regDt || '').split(' ')[0]}
              </div>
            </div>
            <div
              className='justify-start text-start text-lg py-20 tracking-tighter font-medium'
              dangerouslySetInnerHTML={createMarkup(noticeContents.contents)}
            ></div>
          </div>

          {files.length == 0 ? (
            ''
          ) : (
            <div className='flex flex-col w-full'>
              <h2 className='flex flex-grow float-left text-xl font-bold tracking-tighter'>
                첨부파일
              </h2>
              <div className='flex flex-grow flex-col mt-4'>
                {files.map((file, index) => (
                  <div
                    key={index}
                    className='flex items-center text-sm text-gray-700'
                  >
                    <a
                      href={file.link}
                      download={file.oriName || file.name}
                      className='hover:underline'
                    >
                      {file.oriName || file.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}

          <button
            type='button'
            className='inline-flex float-right justify-center items-center bg-white border border-solid border-gray-200 text-gray-600 mt-4 text-xs w-16 h-8 lg:w-32 lg:h-16 lg:text-2xl transition-all'
            id='cancelBtn'
            onClick={() => navigate(-1)}
          >
            목록
          </button>
        </div>
      </div>
    </section>
  );
};

export default NoticeView;
