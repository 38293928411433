const Input = ({ type, name, placeholder, className }) => {
  return (
    <input
      className={`box-border mb-5 text-2xl tracking-tight font-medium pl-[1.8rem] block w-full h-16
        border-solid border-1 border-[#cfcecf] ${className}`}
      type={type}
      name={name}
      placeholder={placeholder}
    />
  );
};

export default Input;
