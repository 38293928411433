import React from 'react';
import Button from '../../../atoms/Button';
import { useNavigate } from 'react-router-dom';

const AdminTitle = ({ hasAddButton, title, form }) => {
  const navigate = useNavigate();
  const path =
    form === 'noticeForm'
      ? '/admin/noticeList/noticeForm'
      : '/admin/FAQList/FAQForm';

  return (
    <div className='flex justify-between items-center pb-4'>
      <h2 className='text-xl font-semibold p-2'>{title}</h2>
      {hasAddButton && (
        <Button
          title={'추가'}
          className={'px-6 py-2'}
          onClick={() => {
            navigate(path);
          }}
        />
      )}
    </div>
  );
};

export default AdminTitle;
