import { PRIVACY_ITEMS } from "../../../../constants/privacyItems";
import ApplyTitle from "../../../atoms/ApplyTitle";
import PolicyContent from "../../../atoms/PolicyContent";
import TermsTitle from "../../../atoms/TermsTitle";

const Privacy = ({}) => {
  return (
    <>
      <ApplyTitle title={"개인정보처리방침"} />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        {PRIVACY_ITEMS.map((item) => {
          return (
            <>
              {item.bt != null ? <TermsTitle text={item.bt} /> : ""}
              <PolicyContent item={item} />
            </>
          );
        })}
      </div>
    </>
  );
};

export default Privacy;
