import { useEffect, useRef, useState } from 'react';
import AdditionalInfo from './AdditionalInfo';

const Index = ({
  handleFocus,
  handleApplyForm,
  menuList,
  onMenuChange,
  onAddMenu,
  onDeleteMenu,
  onStoreChange,
  storeFileList,
  applyForm,
  setUpdatedStoreFile,
  onOnlyNumber,
}) => {
  const [radio, setRadio] = useState('00');
  const [isChecked, setChecked] = useState(false);
  const [targetEtc1, setTargetEtc1] = useState('');
  const [targetEtc2, setTargetEtc2] = useState('');

  useEffect(() => {}, [menuList]);

  const handleRadio = (e) => {
    handleApplyForm(e);
    setRadio(e.target.value);
  };

  const handleCheck = (e) => {
    setChecked(!isChecked);
  };

  const handleTarget = (e, step) => {
    if (step == 1) {
      setTargetEtc1(e.target.value);
    } else {
      setTargetEtc2(e.target.value);
    }
    handleApplyForm(e);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      // 파일 크기 확인
      if (file.size > maxSize) {
        alert('5MB 이하의 이미지만 업로드 가능합니다.');
        return;
      }

      // 파일 타입 확인
      if (!allowedTypes.includes(file.type)) {
        alert('jpg, jpeg, gif, png 형식의 이미지만 업로드 가능합니다.');
        return;
      }

      // 파일 선택 이벤트 전달
      if (e.target.name === 'store_Img') {
        onStoreChange(e);
      } else {
        onMenuChange(e);
      }
    }
  };

  return (
    <AdditionalInfo
      {...{
        handleFocus,
        handleApplyForm,
        radio,
        isChecked,
        targetEtc1,
        targetEtc2,
        menuList,
        onMenuChange,
        onAddMenu,
        onDeleteMenu,
        onStoreChange,
        storeFileList,
        applyForm,
        setUpdatedStoreFile,
      }}
      onRadio={handleRadio}
      onCheck={handleCheck}
      onTarget={handleTarget}
      onFileSelect={handleFileSelect}
      onOnlyNumber={onOnlyNumber}
    />
  );
};

export default Index;
