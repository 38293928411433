import React from 'react';
import { PlusIcon } from '@heroicons/react/16/solid';
import ApplyInput from '../ApplyInput/ApplyInput';
import ApplyInputTitle from '../../atoms/ApplyInputTitle';
import ApplyInputContent from '../../atoms/ApplyInputContent';
import SNSDropDown from '../../atoms/SNSDropDown';
import StoreImages from '../../atoms/StoreImages';
import Menu from '../../atoms/menu';
import SelectSimpleCustom from '../../atoms/SelectSimpleCustom';
import { SECTORS } from '../../../constants/sectors';

const AdditionalInfo = ({
  handleApplyForm,
  menuList,
  onMenuChange,
  onAddMenu,
  onDeleteMenu,
  onRadio,
  onCheck,
  onTarget,
  onStoreChange,
  storeFileList,
  onFileSelect,
  applyForm,
  setUpdatedStoreFile,
  onOnlyNumber,
}) => {
  return (
    <>
      <div className='flex flex-col md:w-5/6 w-full mt-10'>
        <div className='flex flex-row w-full mb-8 justify-between border-solid border-b-2 border-gray-500'>
          <h3 className='flex md:text-2xl text-sm font-bold mb-4 '>추가정보</h3>
        </div>
      </div>

      <dl className='flex flex-col md:w-5/6 w-full md:mt-10 mt-0 items-center '>
        <div className='flex flex-col md:w-2/3 w-full'>
          <ApplyInput id={'영업시간'}>
            <ApplyInputTitle title={`영업시간`} redStar={false} />
            <ApplyInputContent>
              <div className='flex flex-col md:flex-row h-fit w-full items-center gap-4'>
                <input
                  name='open_Hour'
                  type='time'
                  className='flex w-full md:w-auto md:text-2xl text-lg'
                  onChange={handleApplyForm}
                  value={applyForm.open_Hour}
                />
                <p className='md:text-2xl text-lg'>~</p>
                <input
                  name='close_Hour'
                  type='time'
                  className='flex w-full md:w-auto md:text-2xl text-lg'
                  onChange={handleApplyForm}
                  value={applyForm.close_Hour}
                />
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'브레이크타임'}>
            <ApplyInputTitle title={`브레이크타임`} redStar={false} />
            <ApplyInputContent>
              <div className='flex flex-col md:flex-row h-fit w-full items-center gap-4'>
                <input
                  name='break_Start_Hour'
                  type='time'
                  className='flex w-full md:w-auto md:text-2xl text-lg'
                  onChange={handleApplyForm}
                  value={applyForm.break_Start_Hour}
                />
                <p className='md:text-2xl text-lg'>~</p>
                <input
                  name='break_End_Hour'
                  type='time'
                  className='flex w-full md:w-auto md:text-2xl text-lg'
                  onChange={handleApplyForm}
                  value={applyForm.break_End_Hour}
                />
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'휴무일'}>
            <ApplyInputTitle title={`휴무일`} redStar={false} />
            <ApplyInputContent>
              <div
                className='flex flex-col w-full md:gap-8 gap-2'
                onChange={handleApplyForm}
              >
                <div className='flex flex-row flex-wrap md:gap-4 gap-1 md:text-xl text-xs'>
                  {['월', '화', '수', '목', '금', '토', '일'].map((day) => (
                    <div
                      key={day}
                      className='flex flex-row items-center md:gap-4 gap-0.5'
                    >
                      <input
                        checked={applyForm.close_Info.includes(day)}
                        name='close_Info'
                        value={day}
                        data-type='checkbox'
                        type='checkbox'
                        className='w-5 h-5 border-solid border-gray-500 text-sky-950'
                      />
                      <p>{day}</p>
                    </div>
                  ))}
                </div>
                <div className='flex flex-row items-center md:gap-4 gap-2'>
                  <input
                    checked={applyForm.close_Info.includes('휴무일 상시변경')}
                    name='close_Info'
                    value='휴무일 상시변경'
                    type='checkbox'
                    data-type='checkbox'
                    className='w-5 h-5 border-solid border-gray-500 text-sky-950'
                  />
                  <p className='md:text-lg text-xs'>휴무일 상시변경</p>
                </div>
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'제공품목'}>
            <ApplyInputTitle title={`제공품목`} redStar={false} />
            <ApplyInputContent>
              <div className='flex flex-col md:w-full md:mb-12 gap-2 '>
                <a
                  onClick={onAddMenu}
                  className='flex border-solid border-2 border-sky-950  md:w-24 w-20 md:p-2 p-1 bg-white justify-center items-center self-start mr-40 hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer'
                >
                  <PlusIcon className='size-4'></PlusIcon>
                  <p className='md:text-sm text-xs font-medium'>추가하기</p>
                </a>
                {menuList.map((menu) => {
                  return (
                    <Menu
                      key={menu.id}
                      {...{
                        menu,
                        onMenuChange,
                        onDeleteMenu,
                        onFileSelect,
                        onOnlyNumber,
                      }}
                    />
                  );
                })}
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'제공대상1'}>
            <ApplyInputTitle title={`제공대상1`} redStar={false} />
            <ApplyInputContent>
              <div className='flex flex-col h-full md:w-full justify-center md:text-base text-2xs'>
                <div
                  className='flex flex-row gap-4 mt-3 ml-3 mb-2'
                  onChange={onRadio}
                >
                  <div className='flex items-center '>
                    <input
                      checked={applyForm.target1_Code === '01'}
                      id='recipient-radio-1'
                      type='radio'
                      value='01'
                      name='target1_Code'
                      className='w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-800'
                    />
                    <label
                      htmlFor='recipient-radio-1'
                      className='ms-2 font-medium'
                    >
                      아이 본인만
                    </label>
                  </div>
                  <div className='flex items-center'>
                    <input
                      checked={applyForm.target1_Code === '02'}
                      id='recipient-radio-2'
                      type='radio'
                      value='02'
                      name='target1_Code'
                      className='w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      htmlFor='recipient-radio-2'
                      className='ms-2 font-medium '
                    >
                      동반 1인
                    </label>
                  </div>
                  <div className='flex items-center'>
                    <input
                      checked={applyForm.target1_Code === '03'}
                      id='recipient-radio-3'
                      type='radio'
                      value='03'
                      name='target1_Code'
                      className='w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      htmlFor='recipient-radio-3'
                      className='ms-2 font-medium '
                    >
                      동반 2인
                    </label>
                  </div>
                </div>
                <div className='flex flex-row md:w-full ml-3 gap-5 md:text-base text-2xs'>
                  <div className='flex items-center'>
                    <input
                      checked={applyForm.target1_Code === '99'}
                      id='recipient-radio-4'
                      type='radio'
                      value='99'
                      name='target1_Code'
                      onChange={onRadio}
                      className='w-4 h-4 text-sky-800 bg-gray-100 border-gray-300 focus:ring-sky-700 dark:focus:ring-sky-800 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      htmlFor='recipient-radio-4'
                      className='ms-2 font-medium '
                    >
                      기타
                    </label>
                  </div>
                  <div className='flex flex-col w-2/3'>
                    <input
                      name='target1_Etc'
                      type='text'
                      onChange={(e) => onTarget(e, 1)}
                      disabled={applyForm.target1_Code === '99' ? false : true}
                      value={
                        applyForm.target1_Code === '99'
                          ? applyForm.target1_Etc
                          : ''
                      }
                      className='flex flex-col h-fit md:text-base text-sm'
                    />
                    <div className='flex w-full  border-solid border-b border-gray-300'></div>
                  </div>
                </div>
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'제공대상2'}>
            <ApplyInputTitle title={`제공대상2`} redStar={false} />
            <ApplyInputContent>
              <div
                className='flex flex-col h-full w-full justify-center '
                onChange={handleApplyForm}
              >
                <div className='flex flex-row gap-4 mt-3 ml-3 mb-2 md:text-base text-2xs'>
                  <div className='flex items-center '>
                    <input
                      checked={applyForm.target2_Code.includes('01')}
                      name='target2_Code'
                      value='01'
                      id='target2-checkbox-1'
                      type='checkbox'
                      data-type='checkbox'
                      className='w-5 h-5 border-solid border-gray-500 text-sky-950'
                    />
                    <label
                      htmlFor='target2-checkbox-1'
                      className='ms-2 font-medium '
                    >
                      결식아동
                    </label>
                  </div>
                  <div className='flex items-center'>
                    <input
                      checked={applyForm.target2_Code.includes('02')}
                      name='target2_Code'
                      id='target2-checkbox-2'
                      type='checkbox'
                      data-type='checkbox'
                      value='02'
                      className='w-5 h-5 border-solid border-gray-500 text-sky-950'
                    />
                    <label
                      htmlFor='target2-checkbox-2'
                      className='ms-2 font-medium '
                    >
                      소방관
                    </label>
                  </div>
                </div>
                <div className='flex flex-row md:w-full ml-3 gap-4 md:text-base text-2xs'>
                  <div className='flex items-center'>
                    <input
                      checked={applyForm.target2_Code.includes('99')}
                      name='target2_Code'
                      value='99'
                      id='target2-checkbox-3'
                      type='checkbox'
                      data-type='checkbox'
                      className='w-5 h-5 border-solid border-gray-500 text-sky-950'
                      onChange={onCheck}
                    />
                    <label
                      htmlFor='target2-checkbox-3'
                      className='ms-2 font-medium '
                    >
                      기타
                    </label>
                  </div>
                  <div className='flex flex-col w-2/3'>
                    <input
                      name='target2_Etc'
                      onChange={(e) => onTarget(e, 2)}
                      value={
                        applyForm.target2_Code.includes('99')
                          ? applyForm.target2_Etc
                          : ''
                      }
                      disabled={
                        applyForm.target2_Code.includes('99') ? false : true
                      }
                      type='text'
                      className='flex flex-col h-fit md:text-base text-sm'
                    />
                    <div className='flex w-full  border-solid border-b border-gray-300'></div>
                  </div>
                </div>
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'가게 SNS'}>
            <ApplyInputTitle title={`가게 SNS`} redStar={false} />
            <ApplyInputContent>
              <div className='flex flex-col w-full gap-4'>
                <SNSDropDown
                  name='sns_Type1'
                  sns='sns_Url1'
                  onChange={handleApplyForm}
                  selectedValue={applyForm.sns_Type1}
                  inValue={applyForm.sns_Url1}
                />
                <SNSDropDown
                  name='sns_Type2'
                  sns='sns_Url2'
                  onChange={handleApplyForm}
                  selectedValue={applyForm.sns_Type2}
                  inValue={applyForm.sns_Url2}
                />
              </div>
            </ApplyInputContent>
          </ApplyInput>

          <ApplyInput id={'사진첨부'}>
            <ApplyInputTitle title={`사진첨부`} redStar={false} />
            <ApplyInputContent>
              <div className='flex flex-col gap-6'>
                {storeFileList.map((storeFile, idx) => (
                  <StoreImages
                    titleId={idx}
                    storeFile={storeFile}
                    onStoreChange={onStoreChange}
                    onFileSelect={onFileSelect}
                    isUpdate={applyForm.isUpdate}
                    setUpdatedStoreFile={setUpdatedStoreFile}
                  />
                ))}
                <p className='text-gray-600 md:text-base text-sm'>
                  * 5MB 이하의 jpg, jpeg, gif, png 이미지만 업로드 가능
                </p>
              </div>
            </ApplyInputContent>
          </ApplyInput>
        </div>
      </dl>
    </>
  );
};

export default AdditionalInfo;
