import React from 'react';

const PopupForm = ({
  popupData,
  setPopupData,
  handleChange,
  handleFileChange,
  handleSubmit,
}) => {
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col px-8 py-4 mb-4 w-full max-w-2xl mx-auto border border-solid border-gray-50 bg-white rounded-lg shadow-md'
      >
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2 w-full'>
            제목
          </label>
          <input
            type='text'
            name='title'
            value={popupData.title}
            onChange={handleChange}
            className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>
            본문 내용
          </label>
          <textarea
            name='contents'
            value={popupData.contents}
            onChange={handleChange}
            className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
            rows='4'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>
            공지사항 인덱스
          </label>
          <input
            type='number'
            name='noticeIdx'
            value={popupData.noticeIdx}
            onChange={handleChange}
            className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>
            이미지
          </label>
          <input
            type='file'
            name='file'
            onChange={handleFileChange}
            className='w-full px-3 py-2 border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
          />
          {popupData.oriName && (
            <div className='mt-2'>
              <a
                href={'file/notice/popup/' + popupData.realName}
                download={popupData.oriName}
                className='hover:underline'
              >
                {popupData.oriName}
              </a>
            </div>
          )}
        </div>

        <div className='mb-4 flex items-center'>
          <label className='block text-gray-700 text-sm font-bold mr-2'>
            팝업 표시 여부
          </label>
          <input
            type='checkbox'
            name='viewYn'
            checked={popupData.viewYn === 'Y'}
            onChange={() =>
              setPopupData({
                ...popupData,
                viewYn: popupData.viewYn === 'Y' ? 'N' : 'Y',
              })
            }
            className='form-checkbox h-5 w-5 text-main border border-solid'
          />
        </div>
        <div className='flex justify-center'>
          <button
            type='submit'
            className='w-full bg-main text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400'
          >
            생성하기
          </button>
        </div>
      </form>
    </div>
  );
};

export default PopupForm;
