import { useEffect, useState } from 'react';
import { AuthAPI } from '../../../api';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  CalendarIcon,
  BookOpenIcon,
  BuildingOffice2Icon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  FaceSmileIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const navigation = [
  { name: '소개', href: '/intro', icon: BookOpenIcon, current: false },
  {
    name: '사업소개',
    href: '/bizinfo',
    icon: BuildingOffice2Icon,
    current: false,
  },
  { name: '동행신청', href: '/apply', icon: UsersIcon, current: false },
  {
    name: '후원하기',
    href: '/devote',
    icon: FaceSmileIcon,
    current: false,
  },
  {
    name: '커뮤니티',
    href: '/community/notice',
    icon: PencilSquareIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({ user, handleLogout }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const userNavigation = [
    {
      name: '나의 정보',
      onClick: function () {
        navigator('/store/myinfo');
      },
    },
    {
      name: '로그아웃',
      onClick: async function async() {
        try {
          const result = await AuthAPI.logout();
        } catch (error) {
          console.log(error);
          alert('로그인 중 오류가 발생했습니다.');
        }
      },
    },
  ];

  return (
    <>
      <div className='h-16'>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className='relative z-50 lg:hidden'
        >
          <DialogBackdrop
            transition
            className='fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0'
          />

          <div className='fixed inset-0 flex'>
            <DialogPanel
              transition
              className='relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full'
            >
              <TransitionChild>
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0'>
                  <button
                    type='button'
                    onClick={() => setSidebarOpen(false)}
                    className='-m-2.5 p-2.5'
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon
                      aria-hidden='true'
                      className='h-6 w-6 text-white'
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4'>
                <div className='flex h-16 shrink-0 items-center'>
                  <Link
                    to='/'
                    className='pt-4'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <div className="w-12 h-10 bg-[url('./assets/images/title_before.png')] bg-cover cursor-pointer" />
                  </Link>
                </div>
                <nav className='flex flex-1 flex-col'>
                  <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                    <li>
                      <ul role='list' className='-mx-2 space-y-1'>
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              onClick={() => setSidebarOpen(false)}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-50 text-main'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-main',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                              )}
                            >
                              <item.icon
                                aria-hidden='true'
                                className={classNames(
                                  item.current
                                    ? 'text-main'
                                    : 'text-gray-400 group-hover:text-main',
                                  'h-6 w-6 shrink-0'
                                )}
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        <div className=''>
          <div className='sticky top-0 z-40 flex h-16 shrink-0 items-center justify-between border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
            <div className='flex items-center'>
              <button
                type='button'
                onClick={() => setSidebarOpen(true)}
                className='-m-2.5 p-2.5 text-gray-700 md:hidden'
              >
                <span className='sr-only'>Open sidebar</span>
                <Bars3Icon aria-hidden='true' className='h-6 w-6' />
              </button>

              <Link
                to='/'
                onClick={() => setSidebarOpen(false)}
                className='hidden md:flex md:flex-1 md:items-center md:justify-center'
              >
                <div className="w-24 h-12 bg-[url('./assets/images/header_logo.png')] bg-cover cursor-pointer" />
              </Link>
            </div>

            {/* Centered navigation */}
            <div className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-center'>
              <nav className='flex text-lg font-semibold space-x-16'>
                {navigation.map((item) => (
                  <div className='relative text-lg font-bold cursor-pointer group hidden hover:text-[#285184] lg:inline-block'>
                    <Link to={item.href}>{item.name}</Link>
                    <span className='absolute top-7 left-0 bottom-0 w-0 h-1 bg-[#285184] transition-all duration-200 ease-in-out group-hover:w-full' />
                  </div>
                ))}
              </nav>
            </div>

            {/* Profile dropdown */}
            <div className='flex items-center'>
              <Menu as='div' className='relative'>
                <MenuButton className='-m-1.5 flex items-center p-1.5'>
                  <span className='sr-only'>Open user menu</span>
                  {/* <Header2 /> */}
                  <span className='flex items-center'>
                    <Link
                      to={
                        user.owner_Name == null
                          ? `/store/auth`
                          : '/store/myinfo'
                      }
                    >
                      <span
                        aria-hidden='true'
                        className='ml-4 text-sm font-semibold leading-6 text-gray-900'
                      >
                        {user.owner_Name == null
                          ? '로그인'
                          : user.owner_Name + '님'}
                      </span>
                    </Link>
                    {user.owner_Name != null && (
                      <ChevronDownIcon
                        aria-hidden='true'
                        className='ml-2 h-5 w-5 text-gray-400'
                      />
                    )}
                  </span>
                </MenuButton>
                {user.owner_Name != null && (
                  <MenuItems
                    transition
                    className='absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
                  >
                    <MenuItem>
                      <Link
                        className='block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                        to='/store/myinfo'
                      >
                        나의 정보
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <span
                        className='block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50 cursor-pointer'
                        onClick={handleLogout}
                      >
                        로그아웃
                      </span>
                    </MenuItem>
                  </MenuItems>
                )}
              </Menu>
            </div>
          </div>

          <main className=''></main>
        </div>
      </div>
    </>
  );
}
