import { useEffect, useState } from 'react';
import ArrowDropRight from '../../assets/svg/arrow-drop-right';

const ApplyProgress = ({ title, semiTitle, step }) => {
  const [progress, setProgress] = useState([false, false, false]);

  useEffect(() => {
    const newProgress = [...progress];
    newProgress[step - 1] = 'text-sky-800';
    setProgress(newProgress);
  }, [step]);

  return (
    <div className="w-full md:w-5/6 md:mb-8 border-solid border-b-2 border-gray-500">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <ul className="flex gap-2 items-center md:order-last order-first">
          <li
            className={
              'flex flex-row md:text-lg text-xs md:gap-1 font-bold items-center ' +
              progress[0]
            }
          >
            <p>01 약관동의</p>
            <ArrowDropRight isActive={progress[0]} />
          </li>
          <li
            className={
              'flex flex-row md:text-lg text-xs md:gap-1 font-bold items-center ' +
              progress[1]
            }
          >
            <p>02 정보입력</p>
            <ArrowDropRight isActive={progress[1]} />
          </li>
          <li
            className={
              'flex flex-row md:text-lg text-xs md:gap-1 font-bold items-center ' +
              progress[2]
            }
          >
            <p>03 신청완료</p>
          </li>
        </ul>
        <div className="flex flex-col md:flex-row md:items-center mt-4 md:mt-0">
          <div className="flex md:items-center md:space-x-4">
            <h3 className="md:text-2xl text-sm font-bold">{title}</h3>
            <p className="md:text-base text-xs font-normal md:ml-4 ml-2">
              {semiTitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyProgress;
