import Login from './Login';
import { AuthAPI, MyPageAPI } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tokenState, userState } from '../../../store/stateAtoms';

const Index = () => {
  const navigate = useNavigate();
  const setToken = useSetRecoilState(tokenState);
  const setUser = useSetRecoilState(userState);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('email');
    const password = formData.get('password');

    if (!email || !password) {
      alert('이메일 또는 비밀번호를 확인해 주세요');
      return;
    }

    try {
      const result = await AuthAPI.login({
        owner_Email: email,
        owner_Pwd: password,
      });

      if (result.status === 200) {
        const accessToken = result.headers['access_token'];
        const refreshToken = result.headers['refresh_token']; // Assuming refresh token is also returned
        const info = await MyPageAPI.getInfo(accessToken);
        setUser({
          name: '',
          owner_Name: info.data.owner_Name,
        });
        setToken({
          accessToken: accessToken || 'emptyToken',
          refreshToken: refreshToken || null,
        });

        navigate('/');
      } else {
        alert('이메일 또는 비밀번호를 확인해 주세요');
      }
    } catch (error) {
      console.log(error);
      alert('로그인 중 오류가 발생했습니다.');
    }
  };

  return <Login onLoginSubmit={handleLoginSubmit} />;
};

export default Index;
