import React, { useEffect, useState } from 'react';
import { DonationAPI } from '../../api';

import icon1 from '../../assets/images/devote_icon_1.png';
import icon2 from '../../assets/images/devote_icon_2.png';
import icon3 from '../../assets/images/devote_icon_3.png';

export default function Stats() {
  const [stats, setStats] = useState([
    {
      id: 1,
      name: '누적금액',
      value: 0,
      finalValue: 0,
      icon: icon1,
    },
    {
      id: 2,
      name: '기부자',
      value: 0,
      finalValue: 0,
      icon: icon2,
    },
    {
      id: 3,
      name: '사용금액',
      value: 0,
      finalValue: 0,
      icon: icon3,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await DonationAPI.getDonation();
        for (let key in data) {
          data[key] = parseInt(data[key].replace(/,/g, ''), 10);
        }

        setStats([
          {
            id: 1,
            name: '누적금액',
            value: 0,
            finalValue: `${data.totalDonations}`,
            icon: icon1,
            scale: '원',
          },
          {
            id: 2,
            name: '기부자',
            value: 0,
            finalValue: `${data.totalDonors}`,
            icon: icon2,
            scale: '명',
          },
          {
            id: 3,
            name: '사용금액',
            value: 0,
            finalValue: `${data.totalExpenses}`,
            icon: icon3,
            scale: '원',
          },
        ]);
      } catch (error) {
        console.error('Error fetching donation data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setStats((prevStats) =>
        prevStats.map((stat) => {
          if (stat.value < stat.finalValue) {
            const increment = Math.ceil((stat.finalValue - stat.value) / 10);
            return { ...stat, value: stat.value + increment };
          }
          return stat;
        })
      );
    }, 10);

    return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 interval 정리
  }, [stats]);

  return (
    <dl className='grid grid-cols-3 gap-1 rounded-xl text-center bg-gray-100 p-2'>
      {stats.map((stat) => (
        <div
          key={stat.id}
          className={`flex flex-col sm:flex-row p-1 md:p-4 w-full bg-gray-100 justify-evenly items-center ${
            stat.id === 1 ? '' : 'border-solid border-gray-200 sm:border-l-2'
          }`}
        >
          <a className='flex justify-center items-center mb-4 sm:mb-0 sm:mr-4 md:text-base text-sm'>
            <img src={stat.icon} className='w-12 h-12' alt={stat.name} />
          </a>
          <div className='flex flex-col gap-2 text-center sm:text-left '>
            <dd className='md:text-lg text-xs font-medium tracking-tight'>
              {stat.name}
            </dd>
            <dt className='text-xs md:text-3xl font-semibold text-sky-900'>
              {stat.value.toLocaleString()}
              {stat.scale}
            </dt>
          </div>
        </div>
      ))}
    </dl>
  );
}
