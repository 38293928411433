import { useEffect, useState } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectSector({
  options,
  onChangeSector,
  onApplyForm,
  isSub,
  value,
  onPass,
}) {
  const [selected, setSelected] = useState(
    options[value] ? options[value] : options[0]
  );
  const [isFirst, setIsFirst] = useState(isSub);

  useEffect(() => {}, selected);
  useEffect(() => {
    if (isSub && options.length > 0 && isFirst == false) {
      setSelected(options[0]);
      onPass('sector', false);
    } else {
      setIsFirst(false);
    }
  }, [options]); // opt
  const handleSelect = (e) => {
    const { code, value } = e;
    setSelected(e);
    if (!isSub) {
      onChangeSector(e);
    } else {
      const data = {
        target: {
          name: 'type',
          value: [code, value],
        },
      };
      onApplyForm(data);
      onPass('sector', true);
    }
  };
  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }) => (
        <>
          <div className="relative border-solid border border-gray-200">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-800 sm:text-sm sm:leading-6">
              <span className="block truncate text-sm md:text-base lg:text-lg">
                {selected.value}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {options.map(({ id, code, value }) => (
                <ListboxOption
                  key={id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-sky-800 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9 text-sm md:text-base lg:text-lg'
                    )
                  }
                  value={{ id, code, value }}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        {value}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-sky-800',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}
