import React, { useState } from "react";
import banner from "../../../assets/images/devote_banner.png";
import Stats from "../../atoms/Stats";
import CardNews from "../../atoms/CardNews";
import Modal from "../../atoms/Modal";
import ApplyPageHeader from "../../atoms/ApplyPageHeader";

const Devote = ({ content }) => {
  const [showModal, setShowModal] = useState(false);
  const modalContent = {
    title: "단기 • 정기후원",
    describe: [],
    items: [
      {
        subTitle: "방법 1. 단체로 직접 후원하기",
        contents: [
          "우리은행 1005404677800",
          '기부금 영수증 발급을 위해 입금자명에 "이름과 전화번호"를 꼭 입력해주세요',
        ],
      },
      // {
      //   subTitle: "방법 2. 바스켓 펀딩으로 후원하기",
      //   contents: [
      //     "사단법인 승인 전부터 후원받았던 캠페인입니다.",
      //     "다양한 결제방법을 원하시면 바스켓을 이용해주시기 바랍니다.",
      //     "기부금 영수증은 IBA 재단 이름으로 발급됩니다.",
      //     "25년부터는 단체 홈페이지에서만 후원 할 수 있습니다.",
      //   ],
      // },
    ],
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleLink = () => {
    window.open("https://basket.fund/goodimpact/7", "_blank");
  };

  return (
    <>
      <div className="flex flex-col mb-16 p-4 md:mb-64 md:p-8">
        <ApplyPageHeader IMG={banner} />
        <div className="flex flex-col w-full h-auto md:h-full md:pt-6 pt-16 mb-16 md:mb-24">
          <div className="flex flex-col w-auto h-auto md:mx-10 mx-4 md:mb-16">
            <h2 className="md:text-5xl text-xl font-semibold md:leading-tight leading-tight">
              후원
            </h2>
            <div id="info" className="flex flex-col md:my-8 my-4">
              <p className="md:text-lg text-sm font-normal md:leading-7 leading-5">
                사단법인 선한영향력가게는 회비와 특별회비로 운영되며{" "}
                <br className="hidden md:block" />
                후원금은 아이들을 위한 사업에 사용됩니다.
              </p>
            </div>
          </div>
          <Stats />
        </div>
        <div className="flex flex-col md:flex-row justify-around p-4 md:p-8 gap-4 md:gap-8">
          <CardNews content={content[0]} onModal={handleModal} />
          <CardNews content={content[1]} />
          <CardNews content={content[2]} />
        </div>
        <Modal
          show={showModal}
          content={modalContent}
          onLink={handleLink}
          onModal={handleModal}
        />
      </div>
    </>
  );
};

export default Devote;
