import ApplyTitle from "../../../atoms/ApplyTitle";
import ApplyProgress from "../../../atoms/ApplyProgress";
import { useEffect } from "react";

const Step3 = () => {
  useEffect(() => {});

  return (
    <>
      {/* section1 */}
      <section id="section1" className="">
        <ApplyTitle title={"선한영향력가게 회원 신청"} />
      </section>

      {/* section2 */}
      <section
        id="section2"
        className="flex flex-col items-center md:mx-0 mx-4"
      >
        <ApplyProgress title={"신청완료"} step={3} />

        <div className="flex flex-col md:w-5/6 w-full mt-10 md:mb-56 mb-28 items-center">
          <div className="flex md:flex-row flex-col w-full md:h-96 h-full">
            <div className="flex flex-col md:w-1/2 md:mb-0 mb-8 md:items-start items-center">
              <h2 className="flex-1 h-full md:mb-16 mb-8 md:text-4xl text-xl font-semibold leading-snug md:text-left text-center">
                <span className="">선한영향력가게</span>
                <br />
                <span className="md:underline-yellow underline-yellow-sm">
                  신청이 완료 되었습니다.
                </span>
              </h2>
              <p className="flex h-full md:text-xl text-xs font-normal md:leading-relaxed leading-snug md:text-left text-center">
                선한영향력가게 신청에 감사드리며 <br />
                신청해 주신 모든 점주님들께 <br />
                선한영향력가게 스티커를 보내드립니다.
              </p>
            </div>
            <div className="flex w-full md:h-full h-48 bg-cover bg-violet-200 bg-[url('./assets/images/step3_img.png')] bg-no-repeat"></div>
          </div>
          <div className="flex md:flex-row flex-col w-full md:mt-16 mt-8 md:items-start items-center">
            <div className="flex md:flex-row flex-col md:w-1/2 w-full md:text-2xl text-base md:text-left text-center md:mb-0 mb-10 font-medium">
              <p>
                정회원연회비는 아동과 선한 영향력을 통한
                <br />
                진정한 상생을 위해 소중히 사용하며
                <br />
                사용 내역은 투명하게 공개합니다.
              </p>
            </div>
            <div className="flex flex-col md:w-1/2 w-full md:text-lg text-xs md:text-left text-center">
              <p>
                연회비는 정관 제8조에 의거하여 정보를 투명하게 운영/관리합니다.
                <br />
                연12만원을 납부하신 점주분들에게는 협회 의결권을 갖으며
                <br />
                정회원증 발급해드립니다.
                <br />
                비영리법인 지침상 연회비는 기부금영수증처리 되지 않으며 협회
                연회비 납부영수증과 이체내역으로 경비처리됩니다.
              </p>
              <br />
              <p>
                우리은행 (사단법인 선한영향력가게) <br />
                1006201548538
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step3;
