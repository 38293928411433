import { useEffect, useState } from "react";
import { SNS } from "../../constants/sns";

const SNSDropDown = ({ name, sns, onChange, selectedValue, inValue }) => {
  const [code, setCode] = useState(selectedValue);
  const [url, setUrl] = useState(inValue);

  useEffect(() => {
    setCode(selectedValue);
    setUrl(inValue);
  }, [selectedValue, inValue]);

  const handleSNS = (e) => {
    onChange(e);
    setCode(e.target.value);
  };

  const handleURL = (e) => {
    setUrl(e.target.value);
    onChange(e);
  };

  return (
    <>
      <div className="flex flex-row flex-wrap items-end gap-1">
        <div className="flex float-left">
          <label className="flex flex-grow">
            <select
              value={code}
              name={name}
              onChange={handleSNS}
              className="md:h-10 h-fit md:w-36 w-24 border-solid border-gray-300 inline-block md:text-base text-xs text-gray-500"
            >
              {SNS.map((item) => (
                <option value={item.code}>{item.value} </option>
              ))}
            </select>
          </label>
        </div>
        <label className="flex flex-col md:w-2/3 w-full">
          <input
            name={sns}
            value={code == "00" ? "가게 SNS을 선택하세요." : url}
            type="text"
            className="peer md:h-full h-fit items-center md:text-base text-xs"
            placeholder={
              code == "00" ? "가게 SNS을 선택하세요." : "가게 SNS을 입력하세요."
            }
            disabled={code == "00" ? true : false}
            onChange={handleURL}
          />
          <div className="flex w-full border-solid border-b border-gray-300"></div>
        </label>
      </div>
    </>
  );
};

export default SNSDropDown;
