const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refreshToken';

const tokenService = {
  getAccessToken: () => {
    let storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (storedToken === null || undefined) {
      return 'no token';
    }
    return storedToken;
  },
  getRefreshToken: () => localStorage.getItem(REFRESH_TOKEN_KEY),

  // saveAccessToken: (token) => localStorage.setItem(ACCESS_TOKEN_KEY, token),
  saveRefreshToken: (token) => localStorage.setItem(REFRESH_TOKEN_KEY, token),

  removeAccessToken: () => localStorage.removeItem(ACCESS_TOKEN_KEY),
  removeRefreshToken: () => localStorage.removeItem(REFRESH_TOKEN_KEY),

  updateAccessToken: (token) => localStorage.setItem(ACCESS_TOKEN_KEY, token),
};

export default tokenService;
