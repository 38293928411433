import React, { useRef, useState, useEffect } from "react";
//import video from "../../assets/JTBC_On_Campaign_240709_0.mp4";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";

const VIDEO = () => {
  const autoplayUrl = "/file/videos/JTBC_On_Campaign_240709_0.mp4";
  //const autoplayUrl = video;
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(1);

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
      setVolume(newVolume);
      if (newVolume === 0) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }
    }
  };

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      videoElement.addEventListener("touchstart", (e) => e.preventDefault(), {
        passive: false,
      });
      videoElement.addEventListener("click", (e) => e.preventDefault(), {
        passive: false,
      });
    }
  }, []);

  return (
    <div className="relative flex flex-col justify-center items-center">
      <div className="relative w-full pointer-events-none py-5 px-8 bg-[#FAE5A6] md:py-7 lg:h-120">
        <video
          id="background-video"
          ref={videoRef}
          className="w-full h-full pointer-events-none rounded-lg object-cover"
          autoPlay
          muted={isMuted}
          loop
          playsInline
          disablePictureInPicture
          controls={false}
          controlsList="nodownload nofullscreen noremoteplayback"
          style={{
            clipPath: "inset(1% round 15px)",
          }}
        >
          <source src={autoplayUrl} type="video/mp4" />
        </video>
        <div className="absolute text-[5px] md:text-[8px] left-0 bottom-0 pl-12 pb-8 md:pl-14 md:pb-10 lg:pl-16 lg:pb-12">
          <p>출처: 채널: JTBC Brand Design</p>
          <p>
            영상제목: “함께 있지 않아도 함께 | 선한 영향력 (내레이션 배우
            소유진)”
          </p>
          <p>업로드일: 2021. 11. 17, 영상길이: 0:52</p>
        </div>
      </div>
      <div className="absolute bottom-2 right-4 md:bottom-4 md:right-12 flex items-center space-x-2 md:space-x-4">
        <button
          onClick={handleMute}
          className={`p-1.5 md:p-2 rounded-lg text-white transition-colors duration-300 ease-in-out ${
            isMuted
              ? "bg-gray-700 hover:bg-gray-600"
              : "bg-sky-600 hover:bg-sky-500"
          }`}
        >
          {isMuted ? (
            <SpeakerXMarkIcon
              className="h-4 w-4 md:h-6 md:w-6"
              aria-hidden="true"
            />
          ) : (
            <SpeakerWaveIcon
              className="h-4 w-4 md:h-6 md:w-6"
              aria-hidden="true"
            />
          )}
        </button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          className="w-20 h-1.5 md:w-24 md:h-2 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-pink-500 transition-all"
          style={{
            background: `linear-gradient(to right, #ec4899 ${
              volume * 100
            }%, #e5e7eb ${volume * 100}%)`,
          }}
        />
      </div>
    </div>
  );
};

export default VIDEO;
