import React from 'react';

import Button from '../../atoms/Button';

const Admin = ({ handleLogin, handleChange }) => {
  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="flex flex-col justify-center items-center p-8 md:p-12 rounded-md bg-slate-100 w-full max-w-md">
        <div className="bg-[url('./assets/images/header_logo.png')] bg-cover w-48 h-24 md:w-64 md:h-32 mb-4" />
        <h1 className="text-xl md:text-2xl font-semibold pb-4">
          New 관리자 페이지
        </h1>
        <form className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col m-4 space-y-2 w-full">
            <label className="flex flex-col">
              <input
                name="userId"
                type="text"
                className="peer h-full items-center text-sm md:text-base p-2 border border-gray-300 rounded"
                placeholder="아이디"
                onChange={handleChange}
              />
            </label>
            <label className="flex flex-col mt-4 md:mt-7">
              <input
                name="passwd"
                type="password"
                className="peer h-full w-full items-center text-sm md:text-base p-2 border border-gray-300 rounded"
                placeholder="패스워드"
                onChange={handleChange}
              />
            </label>
          </div>
          <Button
            title={'로그인'}
            className={'py-3 md:py-5 w-full rounded-none bg-main text-white'}
            onClick={handleLogin}
          />
        </form>
      </div>
    </div>
  );
};

export default Admin;
