import React from "react";

const ApplyInput = ({ title, children }) => {
  return (
    <>
      <div
        id={title}
        className="flex flex-row md:h-full h-fit md:mb-8 mb-0 items-center"
      >
        {children}
      </div>
    </>
  );
};

export default ApplyInput;
