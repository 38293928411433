import { BusinessesAPI } from '../../api';

var KT = {
  b_no: ['1028142945'],
  start_dt: '19811210',
  p_nm: '김영섭',
};

const HoverButton = ({ title, w, h, textSize, data, onPass }) => {
  const handleValidation = async () => {
    try {
      const response = await BusinessesAPI.createPost(data);
      const isVaild = response.data.data[0].valid;
      if (isVaild === '01') {
        onPass('businesses', true);
        alert('인증되었습니다.');
      } else {
        onPass('businesses', false);
        alert('사업자등록정보를 확인해 주세요.');
      }
    } catch (error) {
      console.log(error);
      alert('오류가 발생했습니다.');
    }
  };

  return (
    <a
      className={`flex border-solid border border-sky-950 w-${w} h-${h} gap-3 bg-white justify-center items-center hover:bg-sky-950 hover:text-white transition duration-300 hover:cursor-pointer`}
      onClick={handleValidation}
    >
      <p className={`text-${textSize} font-medium`}>{title}</p>
    </a>
  );
};

export default HoverButton;

// // LG전자
// {
//   b_no: "1078614075",
//   start_dt: "20020401",
//   p_nm: "조주완",
// },
// 삼성SDS
// - **1108128774**
// - 19850501
// - 황성우
