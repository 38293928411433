import { useState } from "react";
import BasicInfo from "./BasicInfo";

const Index = ({
  handleFocus,
  handleApplyForm,
  data,
  applyForm,
  onPass,
  refs,
}) => {
  const [isRegulr, setIsRegulr] = useState(true);

  const handleIsRegulr = (e) => {
    handleApplyForm(e);
    setIsRegulr(!isRegulr);
  };

  return (
    <>
      <BasicInfo
        {...{
          handleFocus,
          handleApplyForm,
          data,
          isRegulr,
          handleIsRegulr,
          applyForm,
          onPass,
          refs,
        }}
      />
    </>
  );
};

export default Index;
