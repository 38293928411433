import { useState } from 'react';
import FindPassword from './FindPassword';
import { AuthAPI } from '../../../api';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const [secretModal, setSecretModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [checkNewPassword, setCheckNewPassword] = useState('');
  const [accountIdx, setAccountIdx] = useState('-1');
  const navigate = useNavigate();

  // 전화번호에 하이픈을 추가하는 함수
  const formatPhoneNumber = (phone) => {
    const cleaned = phone.replace(/\D/g, ''); // 숫자가 아닌 모든 문자를 제거
    let formattedPhone = cleaned;

    if (cleaned.length > 3 && cleaned.length <= 7) {
      formattedPhone = cleaned.replace(/(\d{3})(\d+)/, '$1-$2');
    } else if (cleaned.length > 7) {
      formattedPhone = cleaned.replace(/(\d{3})(\d{4})(\d+)/, '$1-$2-$3');
    }

    return formattedPhone;
  };

  const handleFindPasswordSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('email');
    const name = formData.get('name');
    let phone = formData.get('phone');

    // 전화번호를 하이픈이 포함된 형식으로 변환
    phone = formatPhoneNumber(phone);

    async function verifyProcess(email, name, phone) {
      if (
        name === '' ||
        undefined ||
        phone === '' ||
        undefined ||
        email === '' ||
        undefined
      ) {
        alert('이름, 전화번호, 이메일을 확인해 주세요');
      } else {
        const result = await AuthAPI.verifyAccount({
          ownerEmail: email,
          ownerHp: phone,
          ownerName: name,
        });
        if (result.status === 200) {
          setSecretModal(true);
          setAccountIdx(result.data.message);
        } else {
          alert('이름, 전화번호, 이메일을 확인해 주세요');
        }
      }
    }
    verifyProcess(email, name, phone);
  };

  const handleResetPasswordClick = (e) => {
    e.preventDefault();

    // 비밀번호 유효성 검사 정규식
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{10,16}$/;

    // 비밀번호 유효성 검사
    if (!passwordRegex.test(newPassword)) {
      alert(
        '비밀번호는 영문 대소문자, 숫자, 특수문자를 조합하여 10자에서 16자 사이여야 합니다.'
      );
      return;
    }

    if (newPassword !== checkNewPassword) {
      alert('새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.');
    } else {
      async function resetPasswordProcess() {
        if (
          accountIdx === '-1' ||
          undefined ||
          newPassword === '' ||
          undefined ||
          checkNewPassword === '' ||
          undefined
        ) {
          alert('새 비밀번호와 새 비밀번호 확인을 확인해 주세요');
        } else {
          const result = await AuthAPI.resetPassword({
            idx: accountIdx,
            newPwd: newPassword,
          });
          if (result.status === 200) {
            setSecretModal(false);
            alert('계정이 새 비밀번호로 초기화되었습니다.');
            navigate('/store/auth');
          } else {
            alert('새 비밀번호와 새 비밀번호 확인을 확인해 주세요');
          }
        }
      }
      resetPasswordProcess();
    }
  };

  return (
    <FindPassword
      secretModal={secretModal}
      setSecretModal={setSecretModal}
      onFindPasswordSubmit={handleFindPasswordSubmit}
      onResetPasswordClick={handleResetPasswordClick}
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      checkNewPassword={checkNewPassword}
      setCheckNewPassword={setCheckNewPassword}
    />
  );
};

export default Index;
