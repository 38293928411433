import NoticeView from './NoticeView';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CommunityAPI } from '../../../api';

const Index = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [noticeContents, setNoticeContents] = useState({});
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const result = await CommunityAPI.getNotice(id);
      const domain = 'https://www.선한영향력가게.com';
      try {
        const serverFiles = result.noticeFile.map((file) => ({
          ...file,
          link: '/file/notice/' + file.realName,
          state: 'stable',
          type: 'server',
        }));
        setFiles(serverFiles);
      } catch (error) {
        console.error('Failed to fetch notice:', error);
      }

      const updatedContents = result.contents.replace(
        /<img src="\/file\/notice\//g,
        `<img src="${domain}/file/notice/`
      );

      result.contents = updatedContents;
      setNoticeContents(result);
    }
    fetchData();
  }, []);

  return (
    <NoticeView
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      noticeContents={noticeContents}
      navigate={navigate}
      files={files}
    />
  );
};

export default Index;
