import * as XLSX from 'xlsx';

export const exportToExcel = (data) => {
  // 현재 날짜 가져오기
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  // 파일 이름 생성
  const fileName = `${year}.${month}.${day} 선한영향력가게.xlsx`;

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, fileName);
};
