import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import './index.css';
import './reset.css';

import MainLayout from './MainLayout';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Apply from './components/pages/Apply';
import Step1 from './components/pages/Apply/Step1';
import Step2 from './components/pages/Apply/Step2';
import Step3 from './components/pages/Apply/Step3';
import FindEmail from './components/pages/FindEmail';
import FindPassword from './components/pages/FindPassword';
import Notice from './components/pages/Notice';
import NoticeView from './components/blocks/NoticeView';
import Ask from './components/pages/Ask';
import AskForm from './components/blocks/AskForm';
import AskView from './components/blocks/AskView';
import Faq from './components/pages/Faq';
import FaqView from './components/blocks/FaqView';

import StoreForm from './components/blocks/Admin/StoreForm';
import NoticeForm from './components/blocks/Admin/NoticeForm';
import FAQForm from './components/blocks/Admin/FAQForm';
import MyInfo from './components/pages/MyInfo';
import Terms from './components/pages/Policy/Terms';
import Email from './components/pages/Policy/Email';
import Privacy from './components/pages/Policy/Privacy';
import Devote from './components/pages/Devote';
import BizInfo from './components/pages/BizInfo';
import Introduction from './components/pages/Introduction';

import Admin from './components/pages/Admin';
import StoreList from './components/pages/Admin/Store';
import NoticeList from './components/pages/Admin/Notice';
import AskList from './components/pages/Admin/Ask';
import FAQList from './components/pages/Admin/FAQ';
import Post from './components/pages/Admin/Post';

import { MyPageAPI } from './api';
import { userState } from './store/stateAtoms';
import { useSetRecoilState } from 'recoil';

const App = () => {
  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await MyPageAPI.getInfo();
        setUser(data);
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    init();
  }, []);

  return (
    <MainLayout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />

        <Route path='/store'>
          <Route path='auth' element={<Login />} />
          <Route path='findEmail' element={<FindEmail />} />
          <Route path='findPwd' element={<FindPassword />} />
          <Route path='myinfo' element={<MyInfo />} />
        </Route>

        <Route path='/intro'>
          <Route path='' element={<Introduction />} />
        </Route>

        <Route path='/bizinfo'>
          <Route path='' element={<BizInfo />} />
        </Route>

        <Route path='/apply'>
          <Route index element={<Apply />} />
          <Route path='step'>
            <Route path='1' element={<Step1 />} />
            <Route path='2' element={<Step2 />} />
            <Route path='3' element={<Step3 />} />
          </Route>
        </Route>

        <Route path='/community'>
          <Route path='notice'>
            <Route path='' element={<Notice />} />
            <Route path='view/:id' element={<NoticeView />} />
          </Route>
          <Route path='ask'>
            <Route path='' element={<Ask />} />
            <Route path='form' element={<AskForm />} />
            <Route path='view/:id' element={<AskView />} />
          </Route>
          <Route path='faq'>
            <Route path='' element={<Faq />} />
            <Route path='view/:id' element={<FaqView />} />
          </Route>
        </Route>

        <Route path='/admin'>
          <Route path='' element={<Admin />} />
          <Route path='storeList/' element={<StoreList />}>
            <Route path='storeForm' element={<StoreForm />} />
          </Route>
          <Route path='noticeList' element={<NoticeList />}>
            <Route path='noticeForm' element={<NoticeForm />} />
          </Route>
          <Route path='askList' element={<AskList />}>
            <Route path='askForm' element={<AskForm />} />
          </Route>
          <Route path='FAQList' element={<FAQList />}>
            <Route path='FAQForm' element={<FAQForm />} />
          </Route>
          <Route path='post' element={<Post />} />
        </Route>

        <Route path='/devote'>
          <Route path='' element={<Devote />} />
          <Route path='donation' element={<Home />} />
        </Route>

        <Route path='/new'>
          {/* <Route path="home" element={<NewHome />} /> */}
          {/* <Route path="apply" element={<NewApply />} /> */}
          {/* <Route path="bizinfo" element={<NewBizinfo />} /> */}
        </Route>

        <Route path='/new2'>
          {/* <Route path="home" element={<NewHome2 />} /> */}
          {/* <Route path="apply" element={<NewApply2 />} /> */}
          {/* <Route path="bizinfo" element={<NewBizinfo2 />} /> */}
        </Route>

        <Route path='/policy'>
          <Route path='terms' element={<Terms />} />
          <Route path='email' element={<Email />} />
          <Route path='privacy' element={<Privacy />} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default App;
